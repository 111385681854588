import React, { Component, useEffect, useState } from 'react'

const customStyles = {
    container: (provided, state) => ({
        ...provided,
        marginTop: 50
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflow: "visible"
    }),
    placeholder: (provided, state) => ({
        ...provided,
        position: "absolute",
        top: state.hasValue || state.selectProps.inputValue ? -25 : "",
        left: state.hasValue || state.selectProps.inputValue ? -6 : "",
        color: state.hasValue || state.selectProps.inputValue ? "#007b91" : "#7F8F97",
        transition: "top 0.1s, font-size 0.1s",
        fontSize: (state.hasValue || state.selectProps.inputValue) && 14
    })
};

export const institutionsHelper = {
    customStyles
}