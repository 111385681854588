import React, { Component, useEffect, useState } from "react";
import { Menu } from "@mui/material";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { RiAddFill, RiBankFill, RiMailFill, RiPhoneFill, RiCameraFill, RiSearchLine, RiUserSettingsFill, RiPencilFill } from "react-icons/ri";
import Select, { components } from "react-select";
import { Card, FloatingLabel } from "react-bootstrap";
import { ErrorMessage, Formik } from "formik";
import { Schema } from "./schema";
import { cypherService } from "../../service/cypherService";
import { EndpointService } from "../../service/endpointService";
import HTTPService from "../../service/HTTPService";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Country, State, City } from "country-state-city";
import { institutionsHelper } from "./institutionsHelper";
import { useDispatch, useSelector, Provider, connect } from "react-redux";
import { changeInstitution } from "../../components/redux/institution/institutionActions";

export default function CreateInstitution(props) {
   const [loader, setLoader] = useState(false);
   const [errorMsg, setErrorMsg] = useState("");
   const [stateList, setStateList] = useState([]);
   const [cityList, setCityList] = useState([]);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { ValueContainer, Placeholder } = components;
   const [file, setFile] = useState(null);
   const [image, setImage] = useState(null);
   const [imageSrc, setImageSrc] = useState("");
   const [imageError, setImageError] = useState("");
   const [submitFlag, setSubmitFlag] = useState(false);
   const [userRole, setUserRole] = useState("");
   const getUserRole = () => {
      setUserRole(cypherService.encryptSessionStorage.getItem('officerRole'))
    }
   const CustomValueContainer = ({ children, ...props }) => {
      return (
         <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
               {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
         </ValueContainer>
      );
   };

   //getAllCityList
   const getAllCityList = (stateCode) => {
      let tempArray = [];
      let city = City.getCitiesOfState("IN", stateCode);
      console.log(city);
      city &&
         city.map((item) => {
            tempArray.push({
               value: item.name,
               label: item.name,
            });
         });
      setCityList(tempArray);
   };

   // getAllStateList
   const getAllStateList = () => {
      let tempArray = [];
      let state = State.getStatesOfCountry("IN");
      state &&
         state.map((item) => {
            tempArray.push({
               value: item.isoCode,
               label: item.name,
            });
         });
      setStateList(tempArray);
   };

   useEffect(() => {
      getUserRole()
      getAllStateList();
   }, []);

   const onSubmit = (values) => {
      setSubmitFlag(true);
      if (imageError == "" && (image !== null || file !== null)) {
         setLoader(true);
         let payLoad = { ...values };
         const formData = new FormData();
         formData.append("porductImage", file);
         formData.append("name", payLoad.name);
         formData.append("address1", payLoad.address1);
         formData.append("address2", payLoad.address1);
         formData.append("city", payLoad.city);
         formData.append("state", payLoad.state);
         formData.append("country", payLoad.country);
         formData.append("pincode", payLoad.pincode);
         formData.append("path", imageSrc);

         const apiUrl = EndpointService.createInstitutions;
         HTTPService.imageWithContentRequest(apiUrl, formData)
            .then((response) => {
               const data = response.data;
               if (response.success === true) {
                  cypherService.encryptSessionStorage.setItem("institutionCreated", true);
                  let num = Math.floor(100000 + Math.random() * 900000);
                  dispatch(changeInstitution(num));
                  setLoader(false);
                  toast.success(response.message);
                  navigate(-1);
               }
               if (response.success === false) {
                  setLoader(false);
                  debugger;
                  toast.error(response.error);
               }
            })
            .catch((error) => {
               setLoader(false);
               if (error.response.status == 401) {
                  toast.error(error.response.data.error);
               } else {
                  toast.error(error.response.data.error);
               }
               throw error;
            });
      } else if (image === null && file === null) {
         setImageError("Image is required");
      }
   };
   //File upload
   const handleOnChange = (e) => {
      let imageFile = e.target.files[0];
      if (imageFile) {
         let mb = imageFile.size / 1000000;
         let fixedMb = 2;
         if (parseFloat(mb) > parseFloat(fixedMb)) {
            setImageError("Please upload a image less then 2MB");
         } else {
            setFile(imageFile);
            let imageUrl = URL.createObjectURL(imageFile);
            setImage(imageUrl);
            setImageError("");
         }
      }
   };
   return (
      <>
         {/* <InstitutionMenu/> */}
         <Card className="add-institution">
            <Card.Body>
               <Card.Title>Create Institution</Card.Title>
               <Formik initialValues={Schema.initialValues} validationSchema={Schema.validationSchema} onSubmit={onSubmit} enableReinitialize={true}>
                  {({ handleSubmit, getFieldProps, setFieldValue, values }) => {
                     console.log(values);
                     return (
                        <form className="verical-space-between login-form" onSubmit={handleSubmit} autoComplete="off">
                           <div className="row">
                              <div className="col-md-6">
                                 <div className="">
                                    <FloatingLabel controlId="floatingName" label="Institution Name" className="with-icon mb-3">
                                       <Form.Control size="lg" type="text" placeholder="Name" {...getFieldProps("name")} />
                                       <RiBankFill />
                                    </FloatingLabel>
                                    <ErrorMessage name="name" component="div" className="error-msg" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="institution-logo">
                                    <h4>Institution Logo</h4>
                                    <div className="user-photo">
                                       <img className="profile-image" src={image} alt="" />
                                       <input type="file" id="actual-btn" onChange={handleOnChange} />
                                       <label htmlFor="actual-btn">
                                          <RiCameraFill />
                                       </label>
                                    </div>
                                    <div className="error-msg">{imageError}</div>
                                 </div>
                              </div>
                              <div className="mt-4 pt-2 col-md-12">
                                 <h4>Address</h4>
                              </div>

                              <div className="col-md-6">
                                 <div className="">
                                    <FloatingLabel controlId="floatingAddress1" label="Address Line 1" className="mb-1 mb-md-3">
                                       <Form.Control size="lg" type="text" placeholder="Address Line 1" {...getFieldProps("address1")} />
                                    </FloatingLabel>
                                    <ErrorMessage name="address1" component="div" className="error-msg" />
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="">
                                    <FloatingLabel controlId="floatingAddress2" label="Address Line 2" className="mb-1 mb-md-3">
                                       <Form.Control size="lg" type="text" placeholder="Address Line 2" {...getFieldProps("address2")} />
                                    </FloatingLabel>
                                    <ErrorMessage name="address2" component="div" className="error-msg" />
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="select-input">
                                    <Select
                                       className="basic-single"
                                       classNamePrefix="select"
                                       name="state"
                                       options={stateList}
                                       components={{
                                          ValueContainer: CustomValueContainer,
                                       }}
                                       placeholder="State"
                                       styles={institutionsHelper.customStyles}
                                       defaultValue={null}
                                       value={stateList.filter((option) => option.value == values.state)}
                                       onChange={(e) => {
                                          setFieldValue("state", e.value);
                                          getAllCityList(e.value);
                                       }}
                                       // inputProps={{autoComplete: 'off', autofill:'off', autoCorrect: 'off', spellCheck: 'off' }}
                                    />
                                    <ErrorMessage name="state" component="div" className="error-msg" />
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="select-input">
                                    <Select
                                       className="basic-single"
                                       classNamePrefix="select"
                                       name="city"
                                       options={cityList}
                                       components={{
                                          ValueContainer: CustomValueContainer,
                                       }}
                                       placeholder="City"
                                       styles={institutionsHelper.customStyles}
                                       defaultValue={null}
                                       value={cityList.filter((option) => option.value == values.city)}
                                       onChange={(e) => {
                                          console.log(e.value);
                                          setFieldValue("city", e.value);
                                       }}
                                       // inputProps={{autoComplete: 'off', autofill:'off', autoCorrect: 'off', spellCheck: 'off' }}
                                    />
                                    <ErrorMessage name="city" component="div" className="error-msg" />
                                 </div>
                              </div>

                              {/* <div className='col-md-6'>
                                        <div className='select-input mt-5'>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="country"
                                                options={countries}
                                                components={{
                                                    ValueContainer: CustomValueContainer
                                                }}
                                                placeholder="Country"
                                                styles={customStyles}
                                                defaultValue={null}
                                                value={countries.filter(option => option.value == values.country)}
                                                onChange={(e) => {
                                                    setFieldValue("country", e.value);
                                                }}

                                            />
                                            <ErrorMessage
                                                name="country"
                                                component="div"
                                                className="error-msg"
                                            />
                                        </div>
                                    </div> */}

                              <div className="col-md-6">
                                 <div className="mt-1 mt-md-5">
                                    <FloatingLabel controlId="floatingAddress2" label="Pincode" className="mb-3">
                                       <Form.Control size="lg" type="text" placeholder="Pincode" maxLength={6} {...getFieldProps("pincode")} />
                                    </FloatingLabel>
                                    <ErrorMessage name="pincode" component="div" className="error-msg" />
                                 </div>
                              </div>
                           </div>
                           <div className="py-3">
                              <hr className="" />
                              <div className="d-flex justify-content-end">
                                 <Button variant="secondary" className="secondary-btn mt-3 me-3" onClick={() => navigate(-1)}>
                                    Back
                                 </Button>
                                 <Button type="submit" variant="primary" className="primary-btn mt-3">
                                    Create
                                 </Button>
                              </div>
                           </div>
                        </form>
                     );
                  }}
               </Formik>
            </Card.Body>
         </Card>
      </>
   );
}
