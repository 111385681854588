import brandLogo from './svg/grameen-logo.svg'
import leafLeft from './svg/leafLeft.svg'
import leafRight from './svg/leafRight.svg'
import leafRight2 from './svg/leafRight2.svg'
import loginBg from './svg/login-bg.svg'
import user from './svg/user.png'
import keywordsMaping from './svg/keywordsMaping.svg'
import geocodeMapping from './svg/geocodeMapping.svg'
import csv from './csv.png'
import qrcode from './qrcode.png'

export const images = {
    brandLogo, leafLeft, leafRight, loginBg, leafRight2,
    user, keywordsMaping, geocodeMapping, csv, qrcode
}