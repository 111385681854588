import { Menu } from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { RiAddFill, RiBankFill, RiMailFill, RiPhoneFill, RiSearchLine, RiUserSettingsFill } from "react-icons/ri";
import Select, { components } from "react-select";
import { Card, FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import { Schema } from "../schema";
import { cypherService } from "../../../service/cypherService";
import HTTPService from "../../../service/HTTPService";
import { EndpointService } from "../../../service/endpointService";
import { toast } from "react-toastify";

const gender = [
  { label: "Male", value: "m" },
  { label: "Female", value: "f" },
];

export default function CreateOfficer() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [loginFailMsg, setLoginFailMsg] = useState("");
  const [roleList, setRoleList] = useState([]);

  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
      </ValueContainer>
    );
  };

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      marginTop: 50,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "visible",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? -25 : "",
      left: state.hasValue || state.selectProps.inputValue ? -6 : "",
      color: state.hasValue || state.selectProps.inputValue ? "#007b91" : "#7F8F97",
      transition: "top 0.1s, font-size 0.1s",
      fontSize: (state.hasValue || state.selectProps.inputValue) && 14,
    }),
  };

  // getAllRoleList
  const getAllRoleList = () => {
    const apiUrl = EndpointService.getAllRoles;
    HTTPService.get(apiUrl, null)
      .then((response) => {
        console.log("roles", response);
        const role = response.data;
        let tempArray = [];
        role &&
          role.map((item) => {
            if (item.name != "Admin") {
              tempArray.push({
                value: item._id,
                label: item.name,
              });
            }
          });
        setRoleList(tempArray);
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    getAllRoleList();
  }, []);

  // Submit
  const onSubmit = (values) => {
    setLoader(true);
    values.institutionId = cypherService.encryptSessionStorage.getItem("institutionId");
    let payLoad = { ...values };
    const apiUrl = EndpointService.createOfficer;
    HTTPService.post(apiUrl, payLoad)
      .then((response) => {
        console.log(response);
        const data = response.data;
        if (response.success === true) {
          toast.success(response.message);
          navigate("/institution/officers");
        }
        if (response.success == false) {
          setLoader(false);
          toast.error(response.error);
          debugger;
        }
      })
      .catch((error) => {
        debugger;
        setLoader(false);
        toast.error(error.response.data.error);
        throw error;
      });
  };

  return (
    <Card className="officers-list">
      <Card.Body>
        <Card.Title>Create New Officer</Card.Title>
        <Formik initialValues={Schema.officerInitialValues} validationSchema={Schema.officerValidationSchema} onSubmit={onSubmit} enableReinitialize={true}>
          {({ handleSubmit, getFieldProps, setFieldValue, values }) => {
            console.log(values);
            return (
              <form className="verical-space-between login-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="">
                          <FloatingLabel controlId="floatingName" label="First Name" className="with-icon mb-3">
                            <Form.Control size="lg" type="text" placeholder="First Name" {...getFieldProps("firstName")} />
                            <RiUserSettingsFill />
                          </FloatingLabel>
                          <ErrorMessage name="firstName" component="div" className="error-msg" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="">
                          <FloatingLabel controlId="floatingLastName" label="Last Name" className="with-icon mb-3">
                            <Form.Control size="lg" type="text" placeholder="Last Name" {...getFieldProps("lastName")} />
                            <RiUserSettingsFill />
                          </FloatingLabel>
                          <ErrorMessage name="lastName" component="div" className="error-msg" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <FloatingLabel controlId="floatingMobile" label="Mobile Number" className="with-icon mb-3">
                        <Form.Control size="lg" type="text" maxLength={10} placeholder="Mobile Number" {...getFieldProps("mobileNumber")} />
                        <RiPhoneFill />
                      </FloatingLabel>
                      <ErrorMessage name="mobileNumber" component="div" className="error-msg" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <FloatingLabel controlId="floatingEmailId" label="Email Id" className="with-icon mb-3">
                        <Form.Control size="lg" type="text" placeholder="Email Id" {...getFieldProps("email")} />
                        <RiMailFill />
                      </FloatingLabel>
                      <ErrorMessage name="email" component="div" className="error-msg" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="select-input">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="gender"
                        options={gender}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        placeholder="Gender"
                        styles={customStyles}
                        defaultValue={null}
                        value={gender.filter((option) => option.value == values.gender)}
                        onChange={(e) => {
                          setFieldValue("gender", e.value);
                        }}
                      />
                      <ErrorMessage name="gender" component="div" className="error-msg" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="select-input">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="roleId"
                        options={roleList}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        placeholder="Officer Type"
                        styles={customStyles}
                        defaultValue={null}
                        value={roleList.filter((option) => option.value == values.roleId)}
                        onChange={(e) => {
                          setFieldValue("roleId", e.value);
                        }}
                      />
                      <ErrorMessage name="roleId" component="div" className="error-msg" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <FloatingLabel controlId="floatingDesignation" label="Designation" className="mb-3">
                        <Form.Control size="lg" type="text" placeholder="Designation" {...getFieldProps("designation")} />
                      </FloatingLabel>
                      <ErrorMessage name="designation" component="div" className="error-msg" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <FloatingLabel controlId="floatingDepartment" label="Department" className="mb-3">
                        <Form.Control size="lg" type="text" placeholder="Department" {...getFieldProps("department")} />
                      </FloatingLabel>
                      <ErrorMessage name="department" component="div" className="error-msg" />
                    </div>
                  </div>
                </div>
                <div className="py-3">
                  <hr className="" />
                  <div className="d-flex justify-content-end">
                    <Button variant="secondary" className="secondary-btn mt-3 me-3" onClick={() => navigate("/institution/officers")}>
                      Back
                    </Button>
                    <Button type="submit" variant="primary" className="primary-btn mt-3">
                      Create
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Card.Body>
    </Card>
  );
}
