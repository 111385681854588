import "bootstrap/dist/css/bootstrap.min.css";
// import Alert from 'react-bootstrap/Alert';
import "./App.scss";
import { IconName } from "react-icons/ri";
import { useState,useEffect } from "react";
import { useDispatch, useSelector, Provider, connect } from 'react-redux';
import store from './components/redux/store';
import BaseRoutes from "./routes/BaseRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";

function App() {
  const [show, setShow] = useState(true);
  return (
  <Provider store={store}>
    <div className="grameen-app-ui">
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          pauseOnFocusLoss
          pauseOnHover
        />
        <BaseRoutes />
      </BrowserRouter>
    </div>
  </Provider>
  );
}

export default App;
