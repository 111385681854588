import React, { useEffect, useState } from "react";
import { Menu } from "@mui/material";
import { Card, FloatingLabel } from "react-bootstrap";
import dashboardImg from "../../../assets/images/dashboard.png";
import { ErrorMessage, Formik } from "formik";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Select from "react-select";
import ChartOne from "./chartOne";
import ChartTwo from "./chartTwo";
import ChartThree from "./chartThree";
import DataTable from "react-data-table-component";
import LineChart from "./lineChart";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BarChart } from "./barChart";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { ColorRing } from "react-loader-spinner";

const options = [
   { value: "chocolate", label: "Chocolate" },
   { value: "strawberry", label: "Strawberry" },
   { value: "vanilla", label: "Vanilla" },
];

export default function Dashboard(props) {
   const [startDate, setStartDate] = useState(new Date());
   const [endDate, setEndDate] = useState(new Date());
   const [institutionList, setInstitutionList] = useState([]);
   const [courseList, setCourseList] = useState([]);
   const [institutionId, setInstitutionId] = useState('');
   const [institutionName, setInstitutionName] = useState('');
   const [totalCustomerCount, setTotalCustomerCount] = useState('');
   const [totalOfficerCount, setTotalOfficerCount] = useState('');
   const [customerAnalytics, setCustomerAnalytics] = useState({});
   const [parent, setParent] = useState(true);
   const [customerRole, setCustomerRole] = useState("");
   const [gender, setGender] = useState("");
   const [from, setFrom] = useState('');
   const [to, setTo] = useState('')
   const [languageList, setLanguageList] = useState([]);
   const [loader, setLoader] = useState(true);

   const columns = [
      {
         name: "RANK",
         selector: (row) => <span className="table-rank">{row.rank}</span>,
         sortable: true,
      },
      {
         name: "COURSE NAME",
         selector: (row) => row.name,
         sortable: true,
      },
      {
         name: "COURSE ID",
         selector: (row) => row.courseId,
         sortable: true,
      },
      {
         name: "AVERAGE TIME(m)",
         selector: (row) => row.avgTime,
         sortable: true,
      },
      {
         name: "TOTAL TIME(m)",
         selector: (row) => row.time,
         sortable: true,
      },
      {
         name: "NO. OF. USER ACCESSED",
         selector: (row) => row.count,
         sortable: true,
      },
   ];

   const handleCutomerType = (e, type) => {
      // fetchCustomers(e.target.value);
   };

   const [data, setData] = useState([]);

   useEffect(() => {
      setLoader(true);
      getAllInstitutions();
   }, []);

   useEffect(() => {
      getDashboardDetails();
   }, [institutionId, startDate, endDate, parent]);

   // getDashboardDetails
   const getDashboardDetails = () => {
      setLoader(true);
      const apiUrl = EndpointService.dashboardDetails;
      const payload = {
         "id": institutionId,
         "gender": gender,
         "from": from,
         "to": to
      }
      HTTPService.post(apiUrl, payload)
         .then((response) => {
            console.log(response);
            var percentage = response.data.allCustomerCount ? (response.data.allCustomerCount / 100) : 0
            setTotalCustomerCount([((response.data.totalCustomerCount <= 0) && (response.data.totalBcCount <= 0)) ? 100 : 0,(percentage > 0 ? (((response.data.totalCustomerCount) / percentage)).toFixed() : 0), (percentage > 0 ? (((response.data.totalBcCount) / percentage)).toFixed() : 0)]);
            var percentageOfficer = response.data.allOfficerCount ? (response.data.allOfficerCount / 100) : 0
  
            setTotalOfficerCount([(((response.data.adminOfficerCount <= 0) && (response.data.generalOfficerCount <= 0) )|| (institutionName == "Grameen Foundation")) ? 100 : 0, ((percentageOfficer > 0 && (institutionName != "Grameen Foundation")) ? (((response.data.adminOfficerCount) / percentageOfficer)).toFixed() : 0), ((percentageOfficer > 0 && (institutionName != "Grameen Foundation")) ? (((response.data.generalOfficerCount) / percentageOfficer)).toFixed() : 0)]);
            setCustomerAnalytics(response.data)
            setLanguageList(response.data.languageList)
            setData(response.data.transaction);
            setLoader(false);
         })
         .catch((error) => {
            // debugger;
            setLoader(false);
         });
   };
   const selectInstitution = (event) => {
      setInstitutionId(event.value)
      setInstitutionName(event.name)
   }
   // getAllInstitutions
   const getAllInstitutions = () => {
      const apiUrl = EndpointService.getAllInstitutions;
      HTTPService.get(apiUrl, null).then((response) => {
         const institution = response.data;
         let _institution = institution.map((obj, index) => {
            return {
               address1: obj.address1,
               address2: obj.address2,
               city: obj.city,
               createdAt: obj.createdAt,
               createdBy: obj.createdBy,
               name: obj.name,
               pincode: obj.pincode,
               state: obj.state,
               updatedAt: obj.updatedAt,
               _id: obj._id,
               value: obj._id,
               label: obj.name,
            };
         });
         setInstitutionList(_institution);
         getAllCourseList();
      })
         .catch((error) => {
            throw error;
         });
   };
   const genderType = (e, type) => {
      setGender(type);
      if (type == "m") {
         setParent(true);
      } else {
         setParent(false);
      }

   };
   // getAllCourse
   const getAllCourseList = () => {
      const apiUrl = EndpointService.getAllCourse;
      HTTPService.post(apiUrl, null).then((response) => {
         if (response.data) {
            const courseList = response.data;
            let _courseList = courseList.map((obj, index) => {
               return {
                  _id: obj._id,
                  courseName: obj.courseName,
                  courseCode: obj.courseCode,
                  languageId: obj.languageId,
                  institutionId: obj.institutionId,
                  status: obj.status,
                  active: obj.active,
                  createdAt: obj.createdAt,
                  updatedAt: obj.updatedAt,
                  value: obj._id,
                  label: obj.courseName,
               };
            });
            setCourseList(_courseList);
         }
      }).catch((error) => {
         setLoader(false);
         throw error;
      });;
   };

let dateConvert = (currentDate) =>{
   var date = new Date(currentDate); // M-D-YYYY

   var d = date.getDate();
   var m = date.getMonth() + 1;
   var y = date.getFullYear();
   
   var dateString = y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
   return dateString;
}

   return (
      <section className="main-container dashboard">
         {loader ? (
            <div className="loader">
               <ColorRing
                  visible={true}
                  height="60"
                  width="60"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#007B91", "#007B91", "#007B91", "#007B91", "#007B91"]}
               />
            </div>
         ) : null}
         <div className="dashboard-container row">
            <div className="col-12">
               <div className="select-input institution-select">
                  <Select className="basic-single !mt-0" classNamePrefix="select" name="institution" onChange={selectInstitution} options={institutionList} placeholder="All Institution" />
               </div>

               <div className="row  mx-0">
                  <div className="col-12 col-lg-12 col-xl-8 p-3 pe-0 pb-0 ps-0">
                     <div className="card">
                        <div className="p-3 d-block d-md-flex justify-content-between">
                           <p>Institution Result</p>
                           <div className="chart-filter">
                              <div className="switches-container dashboard-switch">
                                 <input
                                    type="radio"
                                    id="switchParent"
                                    name="switchType"
                                    value={gender}
                                    checked={parent}
                                    onChange={(e) => {
                                       genderType(e, "m");
                                    }}
                                 />

                                 <input
                                    type="radio"
                                    id="switchCustomer"
                                    name="switchType"
                                    value={gender}
                                    checked={!parent}
                                    onChange={(e) => {
                                       genderType(e, "f");
                                    }}
                                 />

                                 <label htmlFor="switchCustomer">Male</label>
                                 <label htmlFor="switchParent">Female</label>
                                 <div className="switch-wrapper">
                                    <div className="switch">
                                       <div>Male</div>
                                       <div>Female</div>
                                    </div>
                                 </div>
                              </div>
                              <div className="date-from-to-main">
                                 <DatePicker
                                    selected={startDate}
                                    onChange={(date) => {
                                       setStartDate(date)
                                       setFrom(dateConvert(date))
                                       
                                    }}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                 />
                                 <span>-</span>
                                 <DatePicker
                                    selected={endDate}
                                    onChange={(date) => {
                                       setEndDate(date)
                                       setTo(dateConvert(date))
                                    }}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                 />
                              </div>

                           </div>
                        </div>
                        <div className="chart-main">
                           <div className="chart-card">
                              { //Check if message failed
                                 (totalCustomerCount != '')
                                 && <ChartOne customerAnalytics={customerAnalytics} totalCustomerCount={totalCustomerCount} institutionName={institutionName} />
                              }
                           </div>

                           <div className="chart-card">
                              { //Check if message failed
                                 (totalOfficerCount != '')
                                 && <ChartThree customerAnalytics={customerAnalytics} totalOfficerCount={totalOfficerCount} institutionName={institutionName} />
                              }
                           </div>

                        </div>
                     </div>
                  </div>

                  <div className="col-12 col-md-12 col-lg-6 col-xl-4 card mt-3 ml-4 p-3">

                     <p>Language Users</p>
                     <div>
                        <BarChart languageList={languageList} />

                     </div>
                  </div>
                  {/* <div className="col-6 mx-0 ml-4 mb-3">
                     <div className="row card mt-3 py-4 px-2 mx-0 ml-4">
                        <div className="col-12">
                           <ChartTwo customerAnalytics={customerAnalytics} />
                        </div>
                        <div className="col-12">
                           <ChartThree customerAnalytics={customerAnalytics} />
                        </div>
                     </div>
                  </div> */}
                  <div className="col-12 col-sm-6 col-lg-3 ml-4 mt-3">
                     <div className="card count-list-cord">
                        <div className="cord-header">
                           <p>Gaming</p>
                        </div>
                        <div className="cord-body">
                           <p>{customerAnalytics.transactionCompletedCount} <span>completed</span></p>
                           <p>{customerAnalytics.transactionInprogressCount} <span>completed</span></p>
                        </div>
                     </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 ml-4 mt-3">
                     <div className="card count-list-cord">
                        <div className="cord-header">
                           <p>Total App Downloads</p>
                        </div>
                        <div className="cord-body">
                           <p>234 <span>Downloaded</span></p>
                        </div>
                     </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 ml-4 mt-3">
                     <div className="card count-list-cord">
                        <div className="cord-header">
                           <p>Total Institutions</p>
                        </div>
                        <div className="cord-body">
                           <p>{customerAnalytics.totalInstitutionCount} <span>Institutions</span></p>
                        </div>
                     </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 ml-4 mt-3">
                     <div className="card count-list-cord">
                        <div className="cord-header">
                           <p>Total Visits</p>
                        </div>
                        <div className="cord-body">
                           <p>{customerAnalytics.arCount} <span>AR Visits</span></p>
                           <p>{customerAnalytics.mapCount} <span>Map Visits</span></p>
                        </div>
                     </div>
                  </div>
               </div>

               {/* table */}
               <p className=" pt-4 mb-1">MCQ Leaderboard</p>
               <div className="row me-2 mx-0">
                  <div className="col-12 card ml-4 p-3">
                     <DataTable columns={columns} data={data} className="react-table" />
                  </div>
               </div>
            </div>

            <div className="col-6 mt-5 d-none">
               <div className="row">
                  <div class="card p-3 mt-4">
                     <div>
                        <div class="cart-filter mb-3">
                           <h2 class="chart-header">User Analysis</h2>
                           <div className="d-flex">
                              <div className="select-input me-4">
                                 <Select className="basic-single !mt-0" classNamePrefix="select" name="courseList" options={courseList} placeholder="All Courses" />
                                 {/* <ErrorMessage name="institution" component="div" className="error-msg" /> */}
                              </div>
                              <div class="select-input with-icon">
                                 {/* <DatePicker placeholder="(DD-MM-YYYY)" inputFormat="DD-MM-YYYY" selected={startDate} onChange={(date: Date) => setStartDate(date)} /> */}
                              </div>
                           </div>
                        </div>

                        <LineChart />
                     </div>

                     <div>
                        <div class="cart-filter">
                           <div className="select-input w-100 ">
                              <Select className="basic-single !mt-0" classNamePrefix="select" name="institution" options={institutionList} placeholder="All Institution" />
                           </div>
                        </div>
                        {/* <BarChart  /> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section >
   );
}
