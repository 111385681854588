import { Menu } from '@mui/material'
import React, { Component, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { RiAddFill, RiBankFill, RiMailFill, RiPhoneFill, RiSearchLine, RiUserSettingsFill, RiPencilFill } from 'react-icons/ri';
import Select, { components } from 'react-select';
import { Card, FloatingLabel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Formik } from 'formik';
import { Schema } from './schema';
import { cypherService } from '../../service/cypherService';
import HTTPService from '../../service/HTTPService';
import { EndpointService } from '../../service/endpointService';
import { toast } from 'react-toastify';
import { images } from '../../assets/images';
import environment from '../../environments/environment'

const fileReader = new FileReader();

export default function Profile() {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [loginFailMsg, setLoginFailMsg] = useState("");
    const [roleList, setRoleList] = useState([]);
    const [officerInitialValues, setOfficerInitialValues] = useState(Schema.officerInitialValues)
    const [name, setName] = useState('')
    const [role, setRole] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [email, setEmail] = useState('')
    const [officerValidationSchema, setOfficerValidationSchema] = useState(Schema.officerValidationSchema)
    const [file, setFile] = useState('')
    const [image, setImage] = useState('')
    const [imageSrc, setImageSrc] = useState('')
    const [imageError, setImageError] = useState("");

    const { ValueContainer, Placeholder } = components;

    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            marginTop: 50
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -25 : "",
            left: state.hasValue || state.selectProps.inputValue ? -6 : "",
            color: state.hasValue || state.selectProps.inputValue ? "#007b91" : "#7F8F97",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 14
        })
    };

    // getAllRoleList
    const getAllRoleList = () => {
        const apiUrl = EndpointService.getAllRoles;
        HTTPService.get(apiUrl, null).then((response) => {
            console.log(response);
            const role = response.data;
            let tempArray = [];
            role && role.map((item) => {
                tempArray.push({
                    value: item._id,
                    label: item.name
                })
            })
            setRoleList(tempArray);
        }).catch((error) => {
            throw error;
        });

    }

    // getOfficersById
    const getOfficersById = () => {
        let officerId = cypherService.encryptSessionStorage.getItem('userId');
        const apiUrl = EndpointService.getOfficerById + officerId;
        debugger
        HTTPService.get(apiUrl, null).then((response) => {
            debugger;
            const data = response.data;
            console.log(data);
            setName(data.firstName + " " + data.lastName)
            setEmail(data.email)
            setMobileNumber(data.mobileNumber)
            setImage(data.path)
            setImageSrc(data.path)
            setOfficerInitialValues({
                firstName: data.firstName,
                lastName: data.lastName,
                mobileNumber: data.mobileNumber,
                email: data.email,
                designation: data.designation,
                department: data.department,
                gender: data.gender
            })
        }).catch((error) => {
            throw error;
        });
    }

    useEffect(() => {
        getAllRoleList();
        getOfficersById();
    }, []);

    //File upload
    const handleOnChange = (e) => {
        let imageFile = e.target.files[0];
        if (imageFile) {
            let mb = imageFile.size / 1000000;
            let fixedMb = 2;
            if (parseFloat(mb) > parseFloat(fixedMb)) {
                setImageError("Please upload a image less then 2MB");
                setFile(e.target.files[0])
                const preview = document.querySelector('.profile-image');
                const file = document.querySelector('input[type=file]').files[0];
                const reader = new FileReader();

                reader.addEventListener("load", () => {
                    // convert image file to base64 string
                    preview.src = reader.result;
                    setImage(reader.result);
                }, false);

                if (file) {
                    reader.readAsDataURL(file);
                }
            } else {
                setImageError("");
                setFile(e.target.files[0])
                const preview = document.querySelector('.profile-image');
                const file = document.querySelector('input[type=file]').files[0];
                const reader = new FileReader();

                reader.addEventListener("load", () => {
                    // convert image file to base64 string
                    preview.src = reader.result;
                    setImage(reader.result);
                }, false);

                if (file) {
                    reader.readAsDataURL(file);
                }
            }
        }
    };

    // Submit
    const onSubmit = (values) => {
        if (imageError == "") {
            setLoader(true);
            let payLoad = { ...values };
            console.log("payLoad", payLoad)
            let officerId = cypherService.encryptSessionStorage.getItem('userId');
            const formData = new FormData()
            console.log("file", file)
            formData.append("porductImage", file)
            formData.append("firstName", payLoad.firstName)
            formData.append("lastName", payLoad.lastName)
            formData.append("email", payLoad.email)
            formData.append("mobileNumber", payLoad.mobileNumber)
            formData.append("officerId", officerId)
            formData.append("path", imageSrc)
            const apiUrl = EndpointService.uploadCustomer;
            HTTPService.imageWithContentRequest(apiUrl, formData)
                .then((response) => {
                    console.log(response)
                    const data = response.data;
                    if (response.success === true) {
                        toast.success(response.message);
                        const profileImage = document.querySelector('.thumbnail-image');
                        cypherService.encryptSessionStorage.setItem('userName', payLoad.firstName + " " + payLoad.lastName);
                        cypherService.encryptSessionStorage.setItem('userImage', response.data.path);
                        profileImage.src = image
                        navigate('/dashboard')
                    }
                    if (response.success === false) {
                        setLoader(false);
                        toast.error(response.error);
                    }
                })
                .catch((error) => {
                    debugger
                    setLoader(false);
                    toast.error(error.response.data.error);
                    throw error;
                });
        }
    };

    return (
        <section className="main-container">
            <div className="dashboard-container">
                <Card className="officers-list">
                    <Card.Body>
                        <Card.Title>Edit Profile</Card.Title>
                        <div className="user-info">
                            <div>
                                <div className="user-photo">
                                    <img className="profile-image" src={image} alt="" />
                                    <input type="file" id="actual-btn" onChange={handleOnChange} />
                                    <label htmlFor="actual-btn"><RiPencilFill /></label>
                                </div>
                                <div className="error-msg">{imageError}</div>
                            </div>
                            <div className="user-details">
                                <h4>{name}</h4>
                                <p>{role}</p>
                                <p>{mobileNumber}</p>
                                <p>{email}</p>
                            </div>
                        </div>
                        <Formik
                            initialValues={officerInitialValues}
                            validationSchema={officerValidationSchema}
                            onSubmit={onSubmit}
                            enableReinitialize={true}
                        >
                            {({ handleSubmit, getFieldProps, setFieldValue, values }) => {
                                return (
                                    <form className="verical-space-between login-form" onSubmit={handleSubmit}>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className=''>
                                                            <FloatingLabel controlId="floatingName" label="First Name" className="with-icon mb-3">
                                                                <Form.Control size="lg" type="text" placeholder="First Name" {...getFieldProps("firstName")} />
                                                                <RiUserSettingsFill />
                                                            </FloatingLabel>
                                                            <ErrorMessage
                                                                name="firstName"
                                                                component="div"
                                                                className="error-msg"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className=''>

                                                            <FloatingLabel controlId="floatingLastName" label="Last Name" className="with-icon mb-3">
                                                                <Form.Control size="lg" type="text" placeholder="Last Name" {...getFieldProps("lastName")} />
                                                                <RiUserSettingsFill />
                                                            </FloatingLabel>
                                                            <ErrorMessage
                                                                name="lastName"
                                                                component="div"
                                                                className="error-msg"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className=''>
                                                    <FloatingLabel controlId="floatingMobile" label="Mobile Number" className="with-icon mb-3">
                                                        <Form.Control size="lg" type="text" placeholder="Mobile Number" {...getFieldProps("mobileNumber")} />
                                                        <RiPhoneFill />
                                                    </FloatingLabel>
                                                    <ErrorMessage
                                                        name="mobileNumber"
                                                        component="div"
                                                        className="error-msg"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className=''>
                                                    <FloatingLabel controlId="floatingEmailId" label="Email Id" className="with-icon mb-3">
                                                        <Form.Control size="lg" type="text" disabled placeholder="Email Id" {...getFieldProps("email")} />
                                                        <RiMailFill />
                                                    </FloatingLabel>
                                                    <ErrorMessage
                                                        name="email"
                                                        component="div"
                                                        className="error-msg"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className='py-3'>
                                            <hr className='' />
                                            <div className='d-flex justify-content-end'>
                                                <Button variant="secondary" className='secondary-btn mt-3 me-3' onClick={() => navigate(-1)}>Cancel</Button>
                                                <Button type='submit' variant="primary" className='primary-btn mt-3'>Update</Button>
                                            </div>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </Card.Body>
                </Card>
            </div>
        </section>

    )
}
