import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export const options = {
   responsive: true,
   plugins: {
      title: {
         display: true,
         text: "",
      },
   },
};

const labels = ["W1", "W2", "W3", "W4", "W5", "W6", "W7"];

export const data = {
   labels,
   datasets: [
      {
         fill: true,
         label: "Total number of hours spent",
         data: [10, 19, 20, 35, 40, 50, 17],
         borderColor: "#F2A16A80",
         backgroundColor: "#F2A16A80",
      },
   ],
};

export default function LineChart() {
   return (
      <div className="line-chart">
         <Line
            data={data}
            options={{
               responsive: true,
               maintainAspectRatio: true,
            }}
         />
      </div>
   );
}
