import { Menu } from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { RiAddFill, RiBankFill, RiMailFill, RiPhoneFill, RiSearchLine, RiUserSettingsFill, RiLayoutGridFill, RiBookFill, RiDeleteBin7Line } from "react-icons/ri";
import Select, { components } from "react-select";
import { Card, FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import { Schema } from "../schema";
import { cypherService } from "../../../service/cypherService";
import HTTPService from "../../../service/HTTPService";
import { EndpointService } from "../../../service/endpointService";
import { toast } from "react-toastify";
import { MuiChipsInput } from "mui-chips-input";
import UploadWithDrag from "./uploadWithDrag";
import { ColorRing } from "react-loader-spinner";
import DeleteQuestionModel from "./deleteQuestionModel";

const courseCategory = [
   { label: "Category 1", value: 1 },
   { label: "Category 2", value: 2 },
];

export default function Mcqs() {
   const navigate = useNavigate();
   const [loader, setLoader] = useState(true);
   const [loginFailMsg, setLoginFailMsg] = useState("");
   const [mcqInitialValues, setMCQInitialValues] = useState({
      questionName: "",
      option1: "Yes",
      option2: "No",
      correctAnswer: "",
      rightAnswerMessage: "",
      wrongAnswerMessage: "",
   });
   const [questionList, setQuestionList] = useState([]);
   const [seletedQuestion, setSeletedQuestion] = useState("");
   const [correctAnsError, setCorrectAnsError] = useState("");
   const [show, setShow] = useState(false);
   const [deleteQuestion, setDeleteQuestion] = useState("");
   const [coursePermission, setCoursePermission] = useState({
      create: true,
      delete: true,
      read: true,
      update: true
   });
   const [tempCourseData, setTempCourseData] = useState("");

   let loginUserPermission = cypherService.encryptSessionStorage.getItem("loginUserPermission");
   let componentName = cypherService.encryptSessionStorage.getItem("appComponent");

   useEffect(() => {
      getQuestionList();
      coursePermissionEvent();
   }, []);

   const coursePermissionEvent = () => {
      if (loginUserPermission) {
         for (let i = 0; i < loginUserPermission.length; i++) {
            let componentId = loginUserPermission[i].componentId;
            if (componentName[componentId] == "Contents") {
               setCoursePermission({
                  create: loginUserPermission[i].create,
                  delete: loginUserPermission[i].delete,
                  read: loginUserPermission[i].read,
                  update: loginUserPermission[i].update
               });
               break;
            }
         }
      }
   }
   const getQuestionList = () => {
      let courseData = cypherService.encryptSessionStorage.getItem("courseData");
      console.log(courseData);
      setTempCourseData(courseData);
      console.log('***************');

      if (courseData._id) {
         handleClose();
         const apiUrl = EndpointService.getCourseQuestions + "" + courseData.courseCode;
         HTTPService.get(apiUrl, null).then((response) => {
            console.log(response);
            debugger;
            const data = response.data;
            if (response.success === true) {
               setMCQInitialValues({
                  questionName: "",
                  option1: "Yes",
                  option2: "No",
                  correctAnswer: "",
                  rightAnswerMessage: "",
                  wrongAnswerMessage: "",
               });
               setQuestionList(data);
               setLoader(false);
            }
            if (response.success == false) {
               setLoader(false);
               toast.error(response.error);
               debugger;
            }
         })
            .catch((error) => {
               debugger;
               setLoader(false);
               if (error.response.data.error == 'Course not found') {
               } else {
                  toast.error(error.response.data.error);
               }
               throw error;
            });
      }
   };
   // Submit
   const onSubmit = (values, form) => {
      setLoader(true);
      setCorrectAnsError("");
      if (values.correctAnswer) {
         let ans = values.correctAnswer.toLowerCase();
         if (values[ans]) {
            questionMap(values, form);
         } else {
            setCorrectAnsError("Please select correct answer");
            setLoader(false);
         }
      } else {
         setCorrectAnsError("Please select correct answer");
         setLoader(false);
      }
   };
   const questionMap = (values, form) => {
      let payLoad = { ...values };
      debugger;
      form.resetForm();
      setLoader(true);
      let courseData = cypherService.encryptSessionStorage.getItem("courseData");
      payLoad.courseId = courseData._id;
      let apiUrl = "";
      if (seletedQuestion == "") {
         apiUrl = EndpointService.createQuestion;
         createQuestion(apiUrl, payLoad, form);
      } else {
         apiUrl = EndpointService.updateQuestion + seletedQuestion._id;
         editQuestion(apiUrl, payLoad, form);
      }
   };
   const createQuestion = (apiUrl, payLoad, form) => {
      HTTPService.post(apiUrl, payLoad)
         .then((response) => {
            const data = response.data;
            if (response.success === true) {
               resetFormValue();
               toast.success(response.message);
               getQuestionList();
            }
            if (response.success == false) {
               setLoader(false);
               toast.error(response.error);
               debugger;
            }
         })
         .catch((error) => {
            debugger;
            setLoader(false);
            toast.error(error.response.data.error);
            throw error;
         });
   };
   const editQuestion = (apiUrl, payLoad, form) => {
      HTTPService.put(apiUrl, payLoad)
         .then((response) => {
            const data = response.data;
            if (response.success === true) {
               form.resetForm();
               resetFormValue();
               toast.success(response.message);
               getQuestionList();
            }
            if (response.success == false) {
               setLoader(false);
               toast.error(response.error);
               debugger;
            }
         })
         .catch((error) => {
            debugger;
            setLoader(false);
            toast.error(error.response.data.error);
            throw error;
         });
   };
   const correctAnswerEvent = (event, setFieldValue) => {
      if (event.target.id) {
         setFieldValue("correctAnswer", event.target.id);
         setCorrectAnsError("");
      }
   };
   const setQuestion = (item, index) => {
      setSeletedQuestion(item);
      setMCQInitialValues({
         questionName: item.questionName,
         option1: item.option1,
         option2: item.option2,
         correctAnswer: item.correctAnswer,
         rightAnswerMessage: item.rightAnswerMessage,
         wrongAnswerMessage: item.wrongAnswerMessage,
      });
      let optionValue = item.correctAnswer;
      if (optionValue) {
         let _temp = optionValue.charAt(0).toUpperCase() + optionValue.slice(1);
         document.getElementById(_temp).checked = true;
      }
   };
   const resetFormValue = () => {
      setMCQInitialValues({
         questionName: "",
         option1: "Yes",
         option2: "No",
         correctAnswer: "",
         rightAnswerMessage: "",
         wrongAnswerMessage: "",
      });
      setSeletedQuestion("");
      document.getElementById("questionfrm").reset();
   };
   const removeQuestion = (item, index) => {
      if (coursePermission.delete) {
         setDeleteQuestion(item);
         setShow(true);
      } else {
         toast.error('Permission is not available');
      }
   };
   const confirmDeleteQuestion = () => {
      setLoader(true);
      let _qus = deleteQuestion;
      const apiUrl = EndpointService.deleteQuestion + _qus._id;
      HTTPService.delete(apiUrl)
         .then((response) => {
            const data = response.data;
            if (response.success === true) {
               toast.success(response.message);
            }
            if (response.success === false) {
               toast.error(response.error);
            }
            getQuestionList();
            setLoader(false);
         })
         .catch((error) => {
            setLoader(false);
            toast.error(error.error);
            throw error;
         });
   };
   const handleClose = () => {
      setShow(false);
      setDeleteQuestion("");
   };
   const handleShow = () => {
      setShow(true);
   };
   const publishCourse = () => {
      let _list = questionList;
      if (_list && _list.length != 0) {
         if (_list.length >= 5) {
            setLoader(true);
            let courseData = cypherService.encryptSessionStorage.getItem("courseData");
            const apiUrl = EndpointService.updateCourseStatus + courseData._id;
            HTTPService.put(apiUrl, {}).then((response) => {
               const data = response.data;
               if (response.success === true) {
                  toast.success('Course published successfully');
                  getQuestionList();
               }
               if (response.success == false) {
                  setLoader(false);
                  toast.error('Course not published');
                  debugger;
               }
            })
               .catch((error) => {
                  debugger;
                  setLoader(false);
                  toast.error(error.response.data.error);
                  throw error;
               });
         } else {
            toast.error("Please add 5 question for course before publish");
         }
      } else {
         toast.error("Please create quiz for course");
      }
   };
   return (
      <section className="main-container">
         {loader ? (
            <div className="loader">
               <ColorRing
                  visible={true}
                  height="60"
                  width="60"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#007B91", "#007B91", "#007B91", "#007B91", "#007B91"]}
               />
            </div>
         ) : null}

         <div className="dashboard-container">
            <Card className="officers-list mcqs">
               <Card.Body>
                  <Card.Title>Create Questions</Card.Title>
                  <Formik initialValues={mcqInitialValues} validationSchema={Schema.mcqValidationSchema} onSubmit={onSubmit} enableReinitialize={true}>
                     {({ handleSubmit, getFieldProps, setFieldValue, values, errors }) => {
                        console.log(values);
                        return (
                           <form id="questionfrm" className="verical-space-between login-form" onSubmit={handleSubmit}>
                              <div className="row">
                                 <div className="col-xl-6">
                                    {/* Mcq question */}
                                    <div className="mcq-question">
                                       <FloatingLabel controlId="floatingName" label="Question Name" className="mb-3">
                                          <Form.Control size="lg" type="text" placeholder="Question" {...getFieldProps("questionName")} />
                                       </FloatingLabel>
                                       <ErrorMessage name="questionName" component="div" className="error-msg" />
                                    </div>
                                    {/* Mcq answers */}
                                    <div className="mcq-answers">
                                       <div className="answer">
                                             <p className="right-answer-p">Right Answer :</p>
                                          <Form.Check type="radio" name="correctAnswer" onChange={(e) => correctAnswerEvent(e, setFieldValue)} id="Option1" label="Yes"
                                             checked={values.correctAnswer == 'Option1' ? true : undefined} />
                                             <Form.Check type="radio" name="correctAnswer" onChange={(e) => correctAnswerEvent(e, setFieldValue)} id="Option2" label="No"
                                             checked={values.correctAnswer == 'Option2' ? true : undefined} />
                                          <div className="w-100">

                                          </div>
                                       </div>
                                       <div className="answer">
                                          <ErrorMessage name="correctAnswer" component="div" className="error-msg" />
                                          {correctAnsError && <div className="error-msg">{correctAnsError}</div>}
                                       </div>
                                       <div className="answer">
                                       <FloatingLabel controlId="floatingName" label="Right answer Message" className="col-12">
                                             <Form.Control type="text" placeholder="Answer 1" {...getFieldProps("rightAnswerMessage")} />
                                             
                                             <ErrorMessage name="rightAnswerMessage" component="div" className="error-msg" />
                                             </FloatingLabel>
                                             </div>
                                             <div className="answer">
                                             <FloatingLabel controlId="floatingName" label="Wrong answer Message" className="col-12">
                                                <Form.Control type="text" placeholder="Answer 2" {...getFieldProps("wrongAnswerMessage")} />
                                               
                                                <ErrorMessage name="wrongAnswerMessage" component="div" className="error-msg" />
                                                </FloatingLabel>
                                                </div>
                                      
                                       {/* <div className="answer">
                                          <Form.Check type="radio" name="correctAnswer" onChange={(e) => correctAnswerEvent(e, setFieldValue)} id="Option3" label="Option 3"
                                          checked={values.correctAnswer == 'Option3' ? true:undefined}/>
                                          <div className="w-100">
                                             <Form.Control type="text" placeholder="Answer 3" {...getFieldProps("option3")} />
                                             <ErrorMessage name="option3" component="div" className="error-msg" />
                                          </div>
                                       </div>
                                       <div className="answer">
                                          <Form.Check type="radio" name="correctAnswer" onChange={(e) => correctAnswerEvent(e, setFieldValue)} id="Option4" label="Option 4" 
                                          checked={values.correctAnswer == 'Option4' ? true:undefined}/>
                                          <div className="w-100">
                                             <Form.Control type="text" placeholder="Answer 4" {...getFieldProps("option4")} />
                                             <ErrorMessage name="option4" component="div" className="error-msg" />
                                          </div>
                                       </div> */}

                                    </div>
                                    {questionList && questionList.length >= 5 ? (
                                       seletedQuestion ? (
                                          <div className="d-flex justify-content-end">
                                             <Button type="button" variant="secondary" className="secondary-btn me-3" onClick={() => resetFormValue()}>
                                                Cancel
                                             </Button>
                                             <Button type="submit" variant="primary" className="primary-btn">
                                                {seletedQuestion ? "Edit" : "Add"}
                                             </Button>
                                          </div>
                                       ) : (
                                          <div className="d-flex flex-column align-items-end">
                                             <span className="mt-5 h6">5 Questions Only Allowed</span>
                                             <Button type="button" variant="primary" style={{ cursor: "not-allowed" }} className="primary-btn d-inline">
                                                Add
                                             </Button>
                                          </div>
                                       )
                                    ) : (
                                       <div className="d-flex justify-content-end">
                                          <Button type="button" variant="secondary" className="secondary-btn me-3" onClick={() => resetFormValue()}>
                                             Cancel
                                          </Button>
                                          <Button type="submit" variant="primary" className="primary-btn">
                                             {seletedQuestion ? "Edit" : "Add"}
                                          </Button>
                                       </div>
                                    )}
                                 </div>
                                 <div className="col-xl-6 mcq-list-main">
                                    <div className="mcq-list">
                                       {questionList &&
                                          questionList.map((item, index) => {
                                             return (
                                                <div className="list-item">
                                                   <div onClick={() => setQuestion(item, index)}>{item.questionName}</div>
                                                   {
                                                      tempCourseData && tempCourseData.status == 'publish' ? (
                                                         <></>
                                                      ) : (
                                                         <RiDeleteBin7Line className="icon icon-delete" onClick={() => removeQuestion(item, index)} />
                                                      )
                                                   }
                                                </div>
                                             );
                                          })}
                                    </div>
                                 </div>
                              </div>
                              <div className="py-3">
                                 <hr className="" />
                                 <div className="d-flex justify-content-end">
                                    <Button type="button" variant="secondary" className="secondary-btn mt-3 me-3" onClick={() => navigate("/contents/create-course")}>
                                       Back
                                    </Button>
                                    {/* <Button
                        type="button"
                          variant="secondary"
                          className="secondary-btn mt-3 me-3"
                        >
                          Save
                        </Button> */}
                                    <Button type="button" variant="primary" className="primary-btn mt-3" onClick={() => publishCourse()}>
                                       Publish
                                    </Button>
                                 </div>
                              </div>
                           </form>
                        );
                     }}
                  </Formik>
               </Card.Body>
            </Card>
         </div>
         {show && <DeleteQuestionModel show={show} handleClose={handleClose} confirmDeleteQuestion={confirmDeleteQuestion}></DeleteQuestionModel>}
      </section>
   );
}
