import { Menu } from '@mui/material'
import React, { Component, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { RiAddFill, RiBankFill, RiMailFill, RiPhoneFill, RiSearchLine, RiUserSettingsFill } from 'react-icons/ri';
import Select, { components } from 'react-select';
import { Card, FloatingLabel } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Formik } from 'formik';
import { Schema } from '../schema';
import { cypherService } from '../../../service/cypherService';
import HTTPService from '../../../service/HTTPService';
import { EndpointService } from '../../../service/endpointService';
import { toast } from 'react-toastify';
import moment from 'moment';

export default function UpdateCustomer() {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [loginFailMsg, setLoginFailMsg] = useState("");
    const [roleList, setRoleList] = useState([]);
    const [customerInitialValues, setCustomerInitialValues] = useState(Schema.customerInitialValues)
    const [customerValidationSchema, setCustomerValidationSchema] = useState(Schema.customerValidationSchema)
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [customerTypeList, setCustomerTypeList] = useState([]);
    const [storeInstitutionName, setStoreInstitutionName] = useState(cypherService.encryptSessionStorage.getItem('institutionName'));
    const [selectedCustomerType, setSelectCustomerType] = useState('');
    const [languageList, setLanguageList] = useState([]);
    const gender = [
        { label: "Male", value: 'm' },
        { label: "Female", value: 'f' }
    ];
    const maritalStatus = [
        { label: "Married", value: true },
        { label: "Unmarried", value: false }
    ];

    const { ValueContainer, Placeholder } = components;

    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            marginTop: 50
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -25 : "",
            left: state.hasValue || state.selectProps.inputValue ? -6 : "",
            color: state.hasValue || state.selectProps.inputValue ? "#007b91" : "#7F8F97",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 14
        })
    };

    useEffect(() => {
        getCustomerType();
        getLanguageList();
    }, []);

  
     const getLanguageList = () => {
        let apiUrl = EndpointService.getLanguageList;
        HTTPService.get(apiUrl, null).then((response) => {
           if (response && response.data) {
              if (response.data.length != 0) {
                 let _list = response.data.map((obj) => {
                    return {
                       label: obj.name,
                       value: obj._id,
                    };
                 });
                 console.log(_list);
  
                 if (_list) {
                    // const obj = Object.fromEntries(_list.map((item) => [item._id, item.name]));
                    // console.log(obj);
                    setLanguageList(_list);
                    // getAllCourseList();
                 }
              }
           }
           debugger;
        });
     };
  
    // getCustomerType
    const getCustomerType = () => {
        const apiUrl = EndpointService.typesOfCustomer;
        HTTPService.post(apiUrl, {}).then((response) => {
            let cusTypeList = response.data;
            let _typeList = [];
            let customerID = null;

            cusTypeList.filter((item) => {
                if (cypherService.encryptSessionStorage.getItem('institutionName') == 'Grameen Foundation') {
                    debugger;
                    if (item.name == 'Customer') {
                        _typeList.push({
                            value: item._id,
                            label: item.name
                        });
                        customerID = item._id;
                    }
                    return true;
                } else {
                    _typeList.push({
                        value: item._id,
                        label: item.name
                    });
                    return true;
                }
            });
            setCustomerTypeList(_typeList);
            getCustomersById(_typeList);
        }).catch((error) => {
            throw error;
        });
    }
    // getCustomersById
    const getCustomersById = (_typeList) => {
        let customerId = cypherService.encryptSessionStorage.getItem('customerId');
        let institutionId = cypherService.encryptSessionStorage.getItem('institutionId');
        const apiUrl = EndpointService.getCustomerById + customerId;
        HTTPService.get(apiUrl, null).then((response) => {
            const data = response.data;
            _typeList.map((item) => {
                if (item.value.toString() === data.roleId.toString()) {
                    setSelectCustomerType(item.label);
                }
            })
            setCustomerInitialValues({
                institutionId: institutionId,
                firstName: data.firstName,
                lastName: data.lastName,
                mobileNumber: data.mobileNumber,
                email: data.email,
                roleId: data.roleId,
                gender: data.gender,
                dateOfBirth: new Date(data.dateOfBirth),
                maritalStatus: data.maritalStatus,
                language:data.language,
                latitude: data.latitude,
                longitude: data.longitude
            })
            setDateOfBirth(new Date(data.dateOfBirth))
        }).catch((error) => {
            throw error;
        });
    }
    // Submit
    const onSubmit = (values) => {
        setLoader(true);
        values.institutionId = cypherService.encryptSessionStorage.getItem('institutionId');
        let customerId = cypherService.encryptSessionStorage.getItem('customerId');
        let payLoad = { ...values, customerId };
        const apiUrl = EndpointService.updateCustomer + customerId;
        HTTPService.put(apiUrl, payLoad)
            .then((response) => {
                console.log(response)
                const data = response.data;
                if (response.success === true) {
                    navigate('/institution/customers');
                    toast.success(response.message);
                }
                if (response.success === false) {
                    setLoader(false);
                    if(response.message){
                       toast.error(response.message);
                    }else{
                       if(response.error.errors.email){
                          toast.error('Email id should be unique');
                       }
                       if(response.error.errors.mobileNumber){
                          toast.error('Mobile number should be unique');
                       }
                    }                
                 }
            })
            .catch((error) => {
                debugger
                setLoader(false);
                toast.error(error.response.data.error);
                throw error;
            });
    };
    const selectCustomerType = (e, setFieldValue) => {
        setFieldValue("roleId", e.value);
        setSelectCustomerType(e.label);
    }

    return (
        <Card className="officers-list">
            <Card.Body>
                <Card.Title>Update User</Card.Title>
                <Formik
                    initialValues={customerInitialValues}
                    validationSchema={customerValidationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                >
                    {({ handleSubmit, getFieldProps, setFieldValue, values }) => {
                        console.log(values)
                        return (
                            <form className="verical-space-between login-form" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className=''>
                                                    <FloatingLabel controlId="floatingName" label="First Name" className="with-icon mb-3">
                                                        <Form.Control size="lg" type="text" placeholder="First Name" {...getFieldProps("firstName")} />
                                                        <RiUserSettingsFill />
                                                    </FloatingLabel>
                                                    <ErrorMessage
                                                        name="firstName"
                                                        component="div"
                                                        className="error-msg"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className=''>

                                                    <FloatingLabel controlId="floatingLastName" label="Last Name" className="with-icon mb-3">
                                                        <Form.Control size="lg" type="text" placeholder="Last Name" {...getFieldProps("lastName")} />
                                                        <RiUserSettingsFill />
                                                    </FloatingLabel>
                                                    <ErrorMessage
                                                        name="lastName"
                                                        component="div"
                                                        className="error-msg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className=''>
                                            <FloatingLabel controlId="floatingMobile" label="Mobile Number" className="with-icon mb-3">
                                                <Form.Control size="lg" type="text" placeholder="Mobile Number" {...getFieldProps("mobileNumber")} />
                                                <RiPhoneFill />
                                            </FloatingLabel>
                                            <ErrorMessage
                                                name="mobileNumber"
                                                component="div"
                                                className="error-msg"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className=''>
                                            <FloatingLabel controlId="floatingEmailId" label="Email Id" className="with-icon mb-3">
                                                <Form.Control size="lg" type="text" placeholder="Email Id" {...getFieldProps("email")} />
                                                <RiMailFill />
                                            </FloatingLabel>
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="error-msg"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='select-input'>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="gender"
                                                options={gender}
                                                components={{
                                                    ValueContainer: CustomValueContainer
                                                }}
                                                placeholder="Gender"
                                                styles={customStyles}
                                                defaultValue={null}
                                                value={gender.filter(option => option.value.toString() == values.gender.toString())}
                                                onChange={(e) => {
                                                    setFieldValue("gender", e.value);
                                                }}

                                            />
                                            <ErrorMessage
                                                name="gender"
                                                component="div"
                                                className="error-msg"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='select-input'>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="roleId"
                                                options={customerTypeList}
                                                components={{
                                                    ValueContainer: CustomValueContainer
                                                }}
                                                placeholder="Customer Type"
                                                styles={customStyles}
                                                defaultValue={null}
                                                value={customerTypeList.filter(option => option.value == values.roleId)}
                                                onChange={(e) => { selectCustomerType(e, setFieldValue) }}
                                            />
                                            <ErrorMessage
                                                name="roleId"
                                                component="div"
                                                className="error-msg"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='select-input'>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name="maritalStatus"
                                                options={maritalStatus}
                                                components={{
                                                    ValueContainer: CustomValueContainer
                                                }}
                                                placeholder="Marital Status"
                                                styles={customStyles}
                                                defaultValue={null}
                                                value={maritalStatus.filter(option => option.value.toString() == values.maritalStatus.toString())}
                                                onChange={(e) => {
                                                    setFieldValue("maritalStatus", e.value);
                                                }}

                                            />
                                            <ErrorMessage
                                                name="maritalStatus"
                                                component="div"
                                                className="error-msg"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                              <div className="select-input">
                                 <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="language"
                                    options={languageList}
                                    components={{
                                       ValueContainer: CustomValueContainer,
                                    }}
                                    placeholder="Language List"
                                    styles={customStyles}
                                    defaultValue={null}
                                    value={languageList.filter((option) => option.value.toString() == values.language.toString())}
                                    onChange={(e) => {
                                       setFieldValue("language", e.value);
                                    }}
                                 />
                                 <ErrorMessage name="language" component="div" className="error-msg" />
                              </div>
                           </div>
                                    <div className='col-md-6'>
                                        <div className="customer-date-picker">
                                            <FloatingLabel controlId="floatingDesignation" className="mb-3">
                                                {/* <Form.Control size="lg" type="text" placeholder="Date of Birth" {...getFieldProps("designation")} /> */}
                                                <DatePicker
                                                    placeholder="(DD-MM-YYYY)"
                                                    inputFormat="DD-MM-YYYY"
                                                    selected={dateOfBirth}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    placeholderText="Date of Birth"
                                                    name="dateOfBirth"
                                                    onChange={(date) => {
                                                        setDateOfBirth(date);
                                                        setFieldValue("dateOfBirth", date);
                                                    }}
                                                />
                                            </FloatingLabel>
                                            {/* <DatePicker placeholder="(DD-MM-YYYY)" setTodayOnBlur={false} name="fromDate" locale='en-in'
                                                                autoComplete='off' value={values.fromDate}
                                                                inputFormat="DD-MM-YYYY" timePicker={false} onChange={(e) => handleDateChange(e, setFieldValue, 'fromDate')}
                                                            /> */}
                                            <ErrorMessage
                                                name="dateOfBirth"
                                                component="div"
                                                className="error-msg"
                                            />
                                        </div>
                                    </div>

                                    {
                                        selectedCustomerType == 'Business Correspondence' ? (
                                            <>
                                                <div className='col-md-6'>
                                                    <div className=''>
                                                        <FloatingLabel controlId="floatingName" label="latitude" className="mb-3">
                                                            <Form.Control size="lg" type="text" placeholder="latitude" {...getFieldProps("latitude")} />
                                                        </FloatingLabel>
                                                        <ErrorMessage
                                                            name="latitude"
                                                            component="div"
                                                            className="error-msg"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className=''>
                                                        <FloatingLabel controlId="floatingName" label="longitude" className="mb-3">
                                                            <Form.Control size="lg" type="text" placeholder="longitude" {...getFieldProps("longitude")} />
                                                        </FloatingLabel>
                                                        <ErrorMessage
                                                            name="longitude"
                                                            component="div"
                                                            className="error-msg"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>
                                <div className='py-3'>
                                    <hr className='' />
                                    <div className='d-flex justify-content-end'>

                                        <Button variant="secondary" className='secondary-btn mt-3 me-3' onClick={() => navigate(-1)}>Back</Button>
                                        <Button type='submit' variant="primary" className='primary-btn mt-3'>Update</Button>
                                    </div>
                                </div>
                            </form>
                        )
                    }}
                </Formik>
            </Card.Body>
        </Card>

    )
}
