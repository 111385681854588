import { Menu } from "@mui/material";
import React, { Component, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import {
   RiAddCircleFill,
   RiAddFill,
   RiBankFill,
   RiCloseFill,
   RiDeleteBin7Line,
   RiDownload2Fill,
   RiMapPinFill,
   RiMore2Fill,
   RiPencilFill,
   RiSearchLine,
   RiUpload2Fill,
   RiUserLocationLine,
   RiUserStarFill,
} from "react-icons/ri";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { cypherService } from "../../../service/cypherService";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import { SwitchChangeEventHandler } from "rc-switch";
import Switch from "rc-switch";
import { ErrorMessage } from "formik";

export default function CustomersList(props) {

   const navigate = useNavigate();
   const [data, setData] = useState([]);
   const [customerList, setCustomerList] = useState([]);
   const [loading, setLoading] = useState(false);
   const [loader, setLoader] = useState(false);
   const [totalRows, setTotalRows] = useState(0);
   const [perPage, setPerPage] = useState(10);
   const [institutionName, setInstitutionName] = useState();
   const [filterText, setFilterText] = React.useState("");
   const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
   const searchQuery = useRef("");
   const [filteredList, setFilteredList] = useState([]);
   const [deleteBtn, setDeleteBtn] = useState(false);
   const [institutionList, setInstitutionList] = useState([]);
   const [deleteCustomerId, setDeleteCustomerId] = useState("");
   const [customerIds, setCustomerIds] = useState([]);
   const [roleId, setRoleId] = useState("");
   const [institutionsId, setInstitutionsId] = useState("");
   const [customerError, setCustomerError] = useState("");
   const [institutioError, setInstitutionError] = useState("");
   const [disabled, setDisabled] = useState(false);
   const [parent, setParent] = useState(false);
   const [roleList, setRoleList] = useState([]);
   const [roleBC, setRoleBC] = useState("");
   const [roleCustomer, setRoleCustomer] = useState("");
   const [customerRole, setCustomerRole] = useState("");
   const [page, setPage] = useState(0);
   const [file, setFile] = useState('');
   const fileReader = new FileReader();
   const [customerMapping, setCustomerMapping] = useState(false);
   const [flag,setFlag] = useState(false);
   const [show, setShow] = useState(false);
   const [institutionPermission, setInstitutionPermission] = useState({
      create: true,
      delete: true,
      read: true,
      update: true
   });

   const handleShow = () => setShow(true);
   const handleClose = () => setShow(false);
   const [keyValue,setKeyValue] = useState(Math.random() * 100);

   let _customerRoleID = "";
   let tempInstitutionName = cypherService.encryptSessionStorage.getItem("institutionName");
   let loginUserPermission = cypherService.encryptSessionStorage.getItem("loginUserPermission");
   let componentName = cypherService.encryptSessionStorage.getItem("appComponent");

   const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
         if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
         }
      };
   }, [filterText, resetPaginationToggle]);

   const hiddenFileInput = React.useRef(null);

   useEffect(() => {
      getCustomerType();
      getAllInstitutions();
      institutionPermissionEvent();
   }, []);
   useEffect(()=>{
      fetchCustomers()
   },[customerRole,page])

   const institutionPermissionEvent = () => {
      if (loginUserPermission) {
         for (let i = 0; i < loginUserPermission.length; i++) {
            let componentId = loginUserPermission[i].componentId;
            if (componentName[componentId] == "Institutions") {
               setInstitutionPermission({
                  create: loginUserPermission[i].create,
                  delete: loginUserPermission[i].delete,
                  read: loginUserPermission[i].read,
                  update: loginUserPermission[i].update,
               });
               break;
            }
         }
      }
   }

   useEffect(() => {
      if (props.currentEvent) {
         getCustomerType();
      }
   }, [props.currentEvent]);

   //File upload
   const handleOnChange = (e) => {
      setFile(e.target.files[0]);
      e.preventDefault()
      const reader = new FileReader()
      reader.onload = async (e) => {
         const text = (e.target.result)
         const csvOutput = e.target.result;
         console.log(csvOutput);
         var arr = csvOutput.split("\n");
         var jsonObj = [];
         var headers = arr[0].split(",");
         console.log(headers);
         for (var i = 1; i < arr.length; i++) {
            var data = arr[i].split(",");
            console.log(data);
            var obj = {};
            for (var j = 0; j < data.length; j++) {

               if (headers[j] && headers[j].trim() && headers[j].trim() == 'gender') {
                  if (data[j] && data[j].trim() && (data[j].trim()).toLowerCase() == "male") {
                     obj[headers[j].trim()] = "true"
                  }
                  else if (data[j] && data[j].trim() && (data[j].trim()).toLowerCase() == "female") {
                     obj[headers[j].trim()] = "false"
                  }
                  else {

                  }

               }
               else if (headers[j] && headers[j].trim() && headers[j].trim() == 'maritalStatus') {
                  if (data[j] && data[j].trim() && (data[j].trim()).toLowerCase() == "married") {
                     obj[headers[j].trim()] = "true"
                  }
                  else if (data[j] && data[j].trim() && (data[j].trim()).toLowerCase() == "unmarried") {
                     // data[j]="false"
                     obj[headers[j].trim()] = "false"
                  }
                  else {

                  }

               }
               else if (data[j] && data[j].trim() && headers[j] && headers[j].trim() && headers[j].trim() == 'roleId') {
                  if ((data[j].trim()).toLowerCase() == "customer") {
                     // data[j] = roleCustomer
                     obj[headers[j].trim()] = roleCustomer
                  }
                  else if (data[j] && data[j].trim() && (data[j].trim()).toLowerCase() == "businesscorrespondence") {
                     // data[j] = roleBC
                     obj[headers[j].trim()] = roleBC
                  }
                  else {

                  }
               }
               else {
                  if (headers[j] && data[j] && headers[j].trim() && data[j].trim()) {
                     obj[headers[j].trim()] = data[j].trim();
                  }

               }

            }
            jsonObj.push(obj);
         }

         //   setLoader(true);
         let payload = {
            customer: [],
         };
         let _list = jsonObj.map((obj) => {
            return {
               institutionId: cypherService.encryptSessionStorage.getItem("institutionId"),
               roleId: customerRole,
               dateOfBirth: obj.dateOfBirth,
               email: obj.email,
               firstName: obj.firstName,
               gender: obj.gender,
               lastName: obj.lastName,
               latitude: obj.lastName,
               longitude: obj.lastName,
               maritalStatus: obj.maritalStatus,
               mobileNumber: obj.mobileNumber,
            };
         });
         payload.customer = _list;
         const apiUrl = EndpointService.multipleCreate;
         HTTPService.post(apiUrl, payload)
            .then((response) => {
               console.log(response);
               const data = response.data;
               if (response.success === true || response.status == "success") {
                  toast.success(response.message);
                  fetchCustomers();
               } else {
                  setLoader(false);
                  toast.error(response.message);
               }
               setCustomerMapping(false)
               // debugger;
               hiddenFileInput.current.value = ''
               //   setFile('')
            })
            .catch((error) => {
               // debugger;
               if (error.response && error.response.data && error.response.data.error) {
                  var error = Object.values(error.response.data.error)
                  setLoader(false);
                  if (error && error[0]) {
                     toast.error(error[0]);
                  }
               }
               setCustomerMapping(false)
               // setFile('')
               hiddenFileInput.current.value = ''
            });
         console.log(text)
         // debugger;
      };

      reader.readAsText(e.target.files[0])

   };
   //File supmit
   const handleOnSubmit = (e) => {
      // debugger;
      hiddenFileInput.current.click();

   };
   const handleCutomerType = (e, type) => {
      setCustomerRole(e.target.value);
      if (type == "BC") {
         setParent(true);
      } else {
         setParent(false);
      }

   };

   const createCSV = (array) => {
      var keys = Object.keys(array[0]); //Collects Table Headers
      var result = ""; //CSV Contents
      result += keys.join(","); //Comma Seperates Headers
      result += "\n"; //New Row
      array.forEach(function (item) {
         //Goes Through Each Array Object
         keys.forEach(function (key) {
            //Goes Through Each Object value
            result += item[key] + ","; //Comma Seperates Each Key Value in a Row
         });
         result += "\n"; //Creates New Row
      });
      return result;
   };

   const downloadCSV = () => {
      let institutionId = cypherService.encryptSessionStorage.getItem("institutionId");
      let apiUrl = EndpointService.getAllCustomer;
      let payLoad = {
         perPage: totalRows,
         page: page,
         institutionId: institutionId,
         searchParm: "",
         customerRole: customerRole,
      };
      HTTPService.post(apiUrl, payLoad).then((response) => {
         console.log(response);
         const data = response.data;
         var csv = "data:text/csv;charset=utf-8," + createCSV(data); //Creates CSV File Format
         var excel = encodeURI(csv); //Links to CSV
         var link = document.createElement("a");
         link.setAttribute("href", excel); //Links to CSV File
         link.setAttribute("download", "customer.csv"); //Filename that CSV is saved as
         link.click();
      });
   };

   // getAllRoleList
   const getCustomerType = () => {
      const apiUrl = EndpointService.typesOfCustomer;
      HTTPService.post(apiUrl, {})
         .then((response) => {
            const typeList = response.data;

            let _typeList = typeList.map((item) => {
               if (item.name == "Customer") {
                  setRoleCustomer(item._id);
                  setCustomerRole(item._id)
                  _customerRoleID = item._id;
               }
               if (item.name == "Business Correspondence") {
                  setRoleBC(item._id);
               }
               return {
                  value: item._id,
                  label: item.name,
               };
            });
            if (_typeList) {
               setRoleList(_typeList);
               // setCustomerRole(_customerRoleID);
               // fetchCustomers(_customerRoleID);
               document.getElementById("switchParent").click();
            }
         })
         .catch((error) => {
            throw error;
         });
   };

   const getAllInstitutions = () => {
      const apiUrl = EndpointService.getAllInstitutions;
      const _institutionList = [];
      HTTPService.get(apiUrl, null).then((response) => {
         // debugger;
         (response.data).map((item) => {
            _institutionList.push({
               value: item._id,
               label: item.name,
            });
            // debugger;
         })
         if (_institutionList) {
            // debugger
            setInstitutionList(_institutionList);
         }
      }).catch((error) => {
         throw error;
      });
   }

   // Fetch customers
   const fetchCustomers = () => {
      setLoading(true);
      setInstitutionName(cypherService.encryptSessionStorage.getItem("institutionName"));
      var _institutionName = cypherService.encryptSessionStorage.getItem("institutionName")
      debugger;
      let institutionId = cypherService.encryptSessionStorage.getItem("institutionId");
      let _customerList = [];
      let apiUrl = EndpointService.getAllCustomer;
      var search = searchQuery.current;
      let payLoad = {
         perPage: perPage,
         page: page,
         institutionId: institutionId,
         searchParm: search,
         customerRole: customerRole
      };
      HTTPService.post(apiUrl, payLoad).then((response) => {
         (response.data).map((item) => {
            _customerList.push({
               value: item._id,
               label: item.firstName + " " + item.lastName,
            });
            debugger;
         })
         if (_customerList) {
            debugger
            setCustomerList(_customerList);
         }
         console.log(response);
         const data = response.data;
         setData(data);
         setFilteredList(data);
         setTotalRows(response.count);
         setLoading(false);
      });
   };

   // HandlePageChange
   const handlePageChange = (page) => {
      setPage(page-1);
   };
   // HandlePerRowsChange
   const handlePerRowsChange = async (newPerPage, page) => {
      setLoading(true);
      let institutionId = cypherService.encryptSessionStorage.getItem("institutionId");
      let apiUrl = EndpointService.getAllCustomer;
      var search = searchQuery.current;
      let payLoad = {
         perPage: newPerPage,
         page: (page-1),
         institutionId: institutionId,
         searchParm: search,
         customerRole: customerRole,
      };
      HTTPService.post(apiUrl, payLoad).then((response) => {
         console.log(response);
         const data = response.data;
         setData(data);
         setFilteredList(data);
         setTotalRows(response.count);
         setPerPage(newPerPage);
         setLoading(false);
      });
   };

   //Search list of objects
   const handleSearch = ({ target }) => {
      const { value } = target;
      const searchText = value;
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
      searchQuery.current = searchText;
      setPage(0);
      fetchCustomers();
   };

   // updateCustomer
   const updateCustomer = (row, event) => {
      console.log(row);
      console.log(event);
      cypherService.encryptSessionStorage.setItem("customerId", row._id);
      navigate("/institution/customers/update-customer");
   };
   // handleRowSelected
   const handleRowSelected = (row, event) => {
      const selectedRows = (row.selectedRows).map((row) => row._id);
      setCustomerIds(selectedRows)
      setDeleteBtn(true);
      setCustomerMapping(true);
      setDeleteCustomerId("");
      if (!row.selectedCount > 0) {
         setDeleteBtn(false);
         setCustomerMapping(false);
      }
      setDeleteCustomerId(row.selectedRows.length > 0 ? row.selectedRows[0]._id : "");
      // console.log(row.selectedRows[0]._id);
   };
   const updateUser = () => {
      if (roleId == '') {
         setCustomerError('Please select customer type')
      }
      else if (institutionsId == '') {
         setInstitutionError('Please select institution')
      }
      else {
         handleClose()
         const payLoad = {
            ids:customerIds,
            institutionId: institutionsId,
            roleId: roleId
         }
         const apiUrl = EndpointService.multipleUpdate;
         HTTPService.post(apiUrl, payLoad)
            .then((response) => {
               console.log(response)
               if (response.success === true) {
                  fetchCustomers()
                  toast.success(response.message);
                  setCustomerMapping(false);
               }
               if (response.success === false) {
                  // setLoader(false);
                  toast.error(response.message);
               }
            })
            .catch((error) => {
            });
      }
   }
   // deleteCustomer
   const deleteCustomer = () => {
      // setLoader(true);
      // institutionId = cypherService.encryptSessionStorage.getItem('institutionId');
      if (institutionPermission.delete) {
         let officerId = cypherService.encryptSessionStorage.getItem("officerId");
         const apiUrl = EndpointService.deleteCustomer + deleteCustomerId;
         HTTPService.delete(apiUrl)
            .then((response) => {
               console.log(response);
               const data = response.data;
               if (response.success === true) {
                  setDeleteBtn(false);
                  toast.success(response.message);
                  fetchCustomers(customerRole);
                  setFlag(true)
               }
               if (response.success === false) {
                  setLoader(false);
                  toast.error(response.error);
               }
            })
            .catch((error) => {
               setLoader(false);
               toast.error(error.error);
               throw error;
            });
      } else {
         toast.error('Permission is not available');
      }
   }

   const columns = [
      {
         name: "First Name",
         selector: (row) => row.firstName,
         sortable: true,
      },
      {
         name: "Last Name",
         selector: (row) => row.lastName,
         sortable: true,
      },
      {
         name: "Mobile No",
         selector: (row) => row.mobileNumber,
         sortable: true,
      },
      {
         name: "Email ID",
         selector: (row) => row.email,
         sortable: true,
      },
   ];

   const gotoCreateCustomerPage = () => {
      if (institutionPermission.create) {
         navigate("/institution/customers/add-customer");
      } else {
         toast.error('Permission is not available');
      }
   }
   const gotoEditInstitutionPage = () => {
      if (institutionPermission.update) {
         navigate("/institution/update-institution");
      } else {
         toast.error('Permission is not available');
      }
   }

   return (
      <>
         {/* <InstitutionMenu/> */}
         {false ? (
            <div className="loader">
               <ColorRing
                  visible={true}
                  height="60"
                  width="60"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#007B91", "#007B91", "#007B91", "#007B91", "#007B91"]}
               />
            </div>
         ) : null}

         <Card className="officers-list">
            <Card.Body>
               <Card.Title>
                  <div>{cypherService.encryptSessionStorage.getItem("institutionName")}</div>
                  <DropdownButton
                     id="dropdown-item-button"
                     variant="light"
                     title={
                        <>
                           <RiMore2Fill className="dots" />
                           <RiCloseFill className="cross" />
                        </>
                     }
                  >
                     <Dropdown.Item
                        onClick={() => {
                           // navigate("/institution/update-institution");
                           gotoEditInstitutionPage();
                        }}
                     >
                        <span className="icon">
                           <RiPencilFill />
                        </span>
                        <span className="content">
                           <p>Edit Institution</p>
                        </span>
                     </Dropdown.Item>
                  </DropdownButton>
               </Card.Title>
               <div className="filter">
                  <div className="d-flex flex-wrap">
                     <div className="institution-list--search mt-3">
                        <Form.Control
                           type="text"
                           placeholder="Search customers"
                           value={searchQuery.current}
                           onChange={(e) => {
                              handleSearch(e);
                           }}
                           className=""
                        />
                        <RiSearchLine className="search-icon" />
                     </div>
                     <div className="switches-container mt-3">
                        <input
                           type="radio"
                           id="switchParent"
                           name="switchType"
                           value={roleCustomer}
                           checked={!parent}
                           onChange={(e) => {
                              handleCutomerType(e, "C");
                           }}
                        />
                        <input
                           type="radio"
                           id="switchCustomer"
                           name="switchType"
                           value={roleBC}
                           checked={parent}
                           disabled={(cypherService.encryptSessionStorage.getItem("institutionName")) == "Grameen Foundation" ? true : false}
                           onChange={(e) => {
                              handleCutomerType(e, "BC");
                           }}
                        />
                        <label htmlFor="switchCustomer">Cust</label>
                        <label htmlFor="switchParent">BC</label>
                        <div className="switch-wrapper">
                           <div className="switch">
                              <div>Cust</div>
                              <div>BC</div>
                           </div>
                        </div>
                     </div>
                     {/* <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={handleOnChange} className="file-input" ref={hiddenFileInput} style={{ display: "none" }} /> */}
                     <input type={"file"} id={"csvFileInput"} accept={".csv"} className="file-input" ref={hiddenFileInput} style={{ display: "none" }} onChange={(e) => handleOnChange(e)} />
                     
                     {(cypherService.encryptSessionStorage.getItem("institutionName")) == "Grameen Foundation" && customerMapping ? <Button
                        htmlFor="csvFileInput"
                        className="add-btn me-3" onClick={handleShow}>
                        <RiUserLocationLine />
                     </Button> : <Button
                        htmlFor="csvFileInput"
                        className="add-btn me-3 mt-3"
                        onClick={(e) => {
                           handleOnSubmit(e);
                        }}
                     >
                        <RiUpload2Fill />
                     </Button>}

                     {/* <Button
                        htmlFor="csvFileInput"
                        className="add-btn me-3"
                        onClick={(e) => {
                           handleOnSubmit(e);
                        }}
                     >
                        <RiUpload2Fill />
                     </Button> */}
                     <Button variant="primary" className="add-btn me-3  mt-3" onClick={() => downloadCSV()}>
                        <RiDownload2Fill />
                     </Button>
                     {deleteBtn && (
                        <Button variant="primary" className="add-btn me-3  mt-3" onClick={() => deleteCustomer()}>
                           <RiDeleteBin7Line />
                        </Button>
                     )}
                  </div>
                  <div className="">
                     <Button variant="primary" className="primary-btn mt-3"
                        onClick={() =>
                           gotoCreateCustomerPage()}
                     // navigate("/institution/customers/add-customer")}
                     >
                        <RiAddCircleFill />
                        Add User
                     </Button>
                  </div>
               </div>
               <div>
                  <DataTable
                     columns={columns}
                     data={filteredList}
                     progressPending={loading}
                     pagination
                     paginationServer
                     responsive
                     selectableRows
                     selectableRowsHighlight
                     clearSelectedRows={flag}
                     paginationTotalRows={totalRows}
                     onChangeRowsPerPage={handlePerRowsChange}
                     onChangePage={handlePageChange}
                     subHeaderComponent={subHeaderComponentMemo}
                     paginationResetDefaultPage={resetPaginationToggle}
                     persistTableHead
                     onRowClicked={updateCustomer}
                     onSelectedRowsChange={(row) => handleRowSelected(row)}
                     className="react-table"
                     key={keyValue}
                  />
               </div>

               <Modal show={show} onHide={handleClose}>
                  <Modal.Body>
                     <Modal.Title>Customer Mapping</Modal.Title>
                     <form className="verical-space-between login-form">
                        <div className="">
                           <div className="select-input">
                              <Select
                                 className="basic-single"
                                 classNamePrefix="select"
                                 name="languageId"
                                 placeholder="Type of Customer"
                                 options={roleList}
                                 onChange={(e) => {
                                    console.log(e.value)
                                    setRoleId(e.value)
                                    setCustomerError('')
                                 }}

                              />
                              <div name="course" component="div" className="error-msg">{customerError}</div>
                              {/* <ErrorMessage name="languageId" component="div" className="error-msg" /> */}
                           </div>
                           <div className="select-input">
                              <Select
                                 className="basic-single"
                                 classNamePrefix="select"
                                 name="languageId2"
                                 placeholder="Assigned Institution"
                                 options={institutionList}
                                 onChange={(e) => {
                                    setInstitutionsId(e.value)
                                    setInstitutionError('')
                                 }}

                              />
                              <div name="course" component="div" className="error-msg">{institutioError}</div>
                              {/* <ErrorMessage name="languageId" component="div" className="error-msg" /> */}
                           </div>
                        </div>
                        <div className="d-flex justify-content-center mb-4 ">
                           <Button variant="primary" className="login-btn" onClick={updateUser}>
                              Update
                           </Button>
                        </div>

                     </form>
                  </Modal.Body>

               </Modal>
            </Card.Body>
         </Card>
      </>
   );
}
