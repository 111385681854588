import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const DeleteQuestionModel=(props)=> {
  

return (
    <>        
      <Modal show={props.show} hide={()=>props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>Confirm Delete Your Seleted Question ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>props.handleClose()}>
            Cancel
          </Button>
          <Button variant="primary" onClick={()=>props.confirmDeleteQuestion()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteQuestionModel;
