import { Menu } from "@mui/material";
import React, { Component, useEffect, useRef, useState } from "react";
import Header from "../../../layouts/header/Header";
import Sidemenu from "../../../layouts/menu/Sidebar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";
import {
   RiAddCircleFill,
   RiAddFill,
   RiBankFill,
   RiCloseFill,
   RiDeleteBin7Line,
   RiDownload2Fill,
   RiMapPinFill,
   RiMore2Fill,
   RiPencilFill,
   RiSearchLine,
   RiUpload2Fill,
   RiUserStarFill,
} from "react-icons/ri";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import courseImg from "../../../assets/images/courses.png";
import { EndpointService } from "../../../service/endpointService";
import HTTPService from "../../../service/HTTPService";
import { cypherService } from "../../../service/cypherService";
import { ColorRing } from "react-loader-spinner";
import { toast } from 'react-toastify';

export default function CourseList(props) {
   const location = useLocation();
   const navigate = useNavigate();
   const [courseList, setCourseList] = useState([]);
   const [languageList, setLanguageList] = useState([]);
   const [loader, setLoader] = useState(true);
   // const [searchQuery,setSearchQuery] = useState('');
   const searchQuery = useRef("")
   const [coursePermission, setCoursePermission] = useState({
      create: true,
      delete: true,
      read: true,
      update: true
   });

   let loginUserPermission = cypherService.encryptSessionStorage.getItem("loginUserPermission");
   let componentName = cypherService.encryptSessionStorage.getItem("appComponent");    

   cypherService.encryptSessionStorage.setItem("courseEvent", "");
   cypherService.encryptSessionStorage.setItem("editCourseId", "");

   useEffect(() => {
      getLanguageList();
      coursePermissionEvent();
   }, []);

   const coursePermissionEvent=()=>{
      if(loginUserPermission){
         for (let i = 0; i < loginUserPermission.length; i++) {
            let componentId = loginUserPermission[i].componentId;
            if (componentName[componentId] == "Contents") {
               setCoursePermission({
                  create: loginUserPermission[i].create,
                  delete: loginUserPermission[i].delete,
                  read: loginUserPermission[i].read,
                  update: loginUserPermission[i].update
               });
               break;
            }
         }  
      }           
  }

   const getLanguageList = () => {
      let apiUrl = EndpointService.getLanguageList;
      HTTPService.get(apiUrl, null).then((response) => {
         if (response && response.data) {
            if (response.data.length != 0) {
               let _list = response.data.map((obj) => {
                  return {
                     _id: obj._id,
                     name: obj.name,
                  };
               });
               console.log(_list);

               if (_list) {
                  const obj = Object.fromEntries(_list.map((item) => [item._id, item.name]));
                  console.log(obj);
                  setLanguageList(obj);
                  getAllCourseList();
               }
            }
         }
         debugger;
      });
   };
   const handleSearch = (e) => {
      searchQuery.current = e.target.value
      getAllCourseList()
   }
   const getAllCourseList = () => {
      // getAllCourse
      let payLoad = {
         searchParm: searchQuery.current,
      };
      const apiUrl = EndpointService.getAllCourse;
      HTTPService.post(apiUrl, payLoad).then((response) => {
         debugger;
         if (response.data) {
            debugger;
            setCourseList(response.data);
            setLoader(false);
         }
      });
   };
   const gotoEditCourse = (item) => {
      if(coursePermission.update){
         cypherService.encryptSessionStorage.setItem("editCourseId", item._id);
         cypherService.encryptSessionStorage.setItem("courseEvent", "Edit");
         navigate("/contents/create-course");
      }else{
         toast.error('Permission is not available');
      }     
   }
   const gotoCreateCourse = () => {
      if(coursePermission.create){
         cypherService.encryptSessionStorage.setItem("editCourseId", "");
         cypherService.encryptSessionStorage.setItem("courseEvent", "Create");
         navigate("/contents/create-course");
      }else{
         toast.error('Permission is not available');
      }
   }
   return (
      <Card className="officers-list">
         {loader ? (
            <div className="loader">
               <ColorRing
                  visible={true}
                  height="60"
                  width="60"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#007B91", "#007B91", "#007B91", "#007B91", "#007B91"]}
               />
            </div>
         ) : null}
         <Card.Body>
            <Card.Title>Courses</Card.Title>
            <div className="filter">
               <div className="d-flex">
                  <div className="institution-list--search">
                     <Form.Control
                        type="text"
                        placeholder="Search courses"
                        value={searchQuery.current}
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                        className=""
                     />
                     <RiSearchLine className="search-icon" />
                  </div>
                  {/* {deleteBtn && (
                  <Button
                    variant="primary"
                    className="add-btn me-3"
                    onClick={() => deleteOfficer()}
                  >
                    <RiDeleteBin7Line />
                  </Button>
                )} */}
               </div>
               <div className="">
                  <Button variant="primary" className="primary-btn" onClick={() => gotoCreateCourse()}>
                     <RiAddCircleFill />
                     Create Course
                  </Button>
               </div>
            </div>
            {/* Course list */}
            <div className="course-list">
               {courseList &&
                  courseList.map((item, index) => {
                     return (
                        <div key={index} className="list-item" onClick={() => gotoEditCourse(item)}>
                           <h4 className="course-name">{item.courseName}</h4>
                           <div className="course-details">
                              <span className="course-id">Course ID - {item.courseCode}</span>
                              <span className="course-mcq">Lang - {languageList[item.languageId]}</span>
                              <span className="course-mcq">No. of MCQ's - {item.questionCount ? item.questionCount : '0'}</span>
                              <span className="course-mcq">Status - {item.status}</span>                               
                           </div>
                        </div>
                     );
                  })}
            </div>
             
         </Card.Body>
      </Card>
   );
}
