import { Menu } from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import Header from "../../layouts/header/Header";
import Sidemenu from "../../layouts/menu/Sidebar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";
import { RiAddFill, RiBankFill, RiSearchLine } from "react-icons/ri";
import Select, { components } from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { ErrorMessage, Formik } from "formik";
import HTTPService from "../../service/HTTPService";
import { EndpointService } from "../../service/endpointService";
import { cypherService } from "../../service/cypherService";
import { toast } from "react-toastify";
import { Schema } from "./schema";
import { ColorRing } from "react-loader-spinner";

export default function Permissions(props) {
   
   const navigate = useNavigate();
   const [loader, setLoader] = useState(true);
   const [loginFailMsg, setLoginFailMsg] = useState("");
   const [roleList, setRoleList] = useState([]);
   const [componentList, setComponentList] = useState([]);
   const { ValueContainer, Placeholder } = components;
   const [permissionNameMap, setPermissionNameMap] = useState(null);

   let arrayHelpers = [];

   const customStyles = {
      container: (provided, state) => ({
         ...provided,
         marginTop: 50,
      }),
      valueContainer: (provided, state) => ({
         ...provided,
         overflow: "visible",
      }),
      placeholder: (provided, state) => ({
         ...provided,
         position: "absolute",
         top: state.hasValue || state.selectProps.inputValue ? -25 : "",
         left: state.hasValue || state.selectProps.inputValue ? -6 : "",
         color: state.hasValue || state.selectProps.inputValue ? "#007b91" : "#7F8F97",
         transition: "top 0.1s, font-size 0.1s",
         fontSize: (state.hasValue || state.selectProps.inputValue) && 14,
      }),
   };

   useEffect(() => {
      setLoader(true);
      getAllRoleList();
   }, []);

   const CustomValueContainer = ({ children, ...props }) => {
      return (
         <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
               {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
         </ValueContainer>
      );
   };
   // getAllRoleList
   const getAllRoleList = () => {
      const apiUrl = EndpointService.getAllRoles;
      HTTPService.get(apiUrl, null)
         .then((response) => {
            console.log(response);
            debugger;
            const role = response.data;
            let tempArray = [];
            role &&
               role.map((item) => {
                  if (item.name != "Admin") {
                     tempArray.push({
                        value: item._id,
                        label: item.name,
                     });
                  }
               });
            setRoleList(tempArray);
            getAllComponentList();
         })
         .catch((error) => {
            throw error;
         });
   };
   // getAllComponetList
   const getAllComponentList = () => {
      debugger;
      const apiUrl = EndpointService.getAllComponent;
      HTTPService.get(apiUrl, null)
         .then((response) => {
            if (response.success) {
               if (response.data.length > 0) {
                  debugger;
                  let _componentList = response.data.map((obj) => {
                     return {
                        _id: obj._id,
                        name: obj.name ? obj.name : "-",
                        create: false,
                        read: false,
                        update: false,
                        delete: false,
                     };
                  });
                  const obj = Object.fromEntries(_componentList.map((item) => [item._id, item.name]));
                  setPermissionNameMap(obj);
                  arrayHelpers = [..._componentList];
                  console.log(_componentList);
                  debugger;
                  setComponentList(_componentList);
                  setLoader(false);
               }else{
                  setLoader(false);
               }
            }else{
               setLoader(false);
            }
         })
         .catch((error) => {
            setLoader(false);
            throw error;
         });
   };

   const onSubmit = (values) => {
      setLoader(true);
      let roleId = values.roleId;
      let payload = {
         permission: [],
      };
      let _list = componentList.map((obj) => {
         return {
            roleId: roleId,
            componentId: obj._id,
            create: obj.create,
            update: obj.update,
            delete: obj.delete,
            read: obj.read,
         };
      });
      debugger;
      payload.permission = _list;
      const apiUrl = EndpointService.roleMapComponentPermission;
      HTTPService.post(apiUrl, payload)
         .then((response) => {
            console.log(response);
            const data = response.data;
            debugger;
            if (response.success === true || response.status == "success") {
               toast.success(response.message);
               setLoader(false);
            } else {
               setLoader(false);
               toast.error(response.message);
            }
         })
         .catch((error) => {
            setLoader(false);
            throw error;
         });
   };
   //arrayHelpers
   const mapPermission = (operationName, componentName, checked, index, setFieldValue) => {
      let _list = [...componentList];
      _list[index][operationName] = checked;
      setComponentList(_list);
      let checkAnyActive = false;
      _list.map((obj) => {
         if (obj.create || obj.read || obj.update || obj.delete) {
            checkAnyActive = true;
         }
      });
      setFieldValue("permission", checkAnyActive ? [1] : []);
      debugger;
   };
   const handleRole = (value, setFieldValue) => {
      setFieldValue("roleId", value);
      getRoleIdWisePermissionDetails(value);
   };
   const getRoleIdWisePermissionDetails = (value) => {
      const apiUrl = EndpointService.roleIdWisePermissionDetail + "/" + value;
      HTTPService.get(apiUrl, null)
         .then((response) => {
            if (response.success === true) {
               if (response.data.length > 0) {
                  let _componentList = response.data.map((obj) => {
                     return {
                        _id: obj.componentId,
                        name: permissionNameMap[obj.componentId],
                        create: obj.create,
                        read: obj.read,
                        update: obj.update,
                        delete: obj.delete,
                     };
                  });
                  setComponentList(_componentList);
               } else {
                  getAllComponentList();
               }
            } else {
               getAllComponentList();
            }
         })
         .catch((error) => {
            throw error;
         });
   };
   return (
      <section className="main-container">
          {loader ? (
            <div className="loader">
               <ColorRing
                  visible={true}
                  height="60"
                  width="60"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#007B91", "#007B91", "#007B91", "#007B91", "#007B91"]}
               />
            </div>
         ) : null}
         <div className="dashboard-container">
            <Card>
               <Card.Body>
                  <Card.Title>Manage Permissions</Card.Title>
                  <Formik initialValues={Schema.initialValues} validationSchema={Schema.validationSchema} onSubmit={onSubmit} enableReinitialize={true}>
                     {({ handleSubmit, getFieldProps, setFieldValue, values }) => {
                        console.log(values);
                        return (
                           <form className="verical-space-between login-form permission-form" onSubmit={handleSubmit}>
                              <div className="row">
                                 <div className="col-md-4 mb-5">
                                    <div className="select-input">
                                       <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          name="roleId"
                                          options={roleList}
                                          components={{
                                             ValueContainer: CustomValueContainer,
                                          }}
                                          placeholder="User Type"
                                          styles={customStyles}
                                          defaultValue={null}
                                          value={roleList.filter((option) => option.value == values.roleId)}
                                          onChange={(e) => {
                                             // handleRole(setFieldValue("roleId", e.value))
                                             handleRole(e.value, setFieldValue);
                                          }}
                                       />
                                       <ErrorMessage name="roleId" component="div" className="error-msg" />
                                    </div>
                                 </div>
                                 <div className="row mb-4">
                                    <div className="col-4 label">
                                       <span>Menu</span>
                                       <ErrorMessage name="permission" component="div" className="error-msg" />
                                    </div>
                                    <div className="col-8 crud">
                                       <div className="row">
                                          <div className="col-3 crud-menu-head">Create</div>
                                          <div className="col-3 crud-menu-head">Read</div>
                                          <div className="col-3 crud-menu-head">Update</div>
                                          <div className="col-3 crud-menu-head">Delete</div>
                                       </div>
                                    </div>
                                 </div>
                                 {/* <pre>{JSON.stringify(componentList,2,2)}</pre> */}
                                 {componentList &&
                                    componentList.map((item, index) => {
                                       return (
                                          <div className="row mb-3">
                                             <div className="col-md-4">
                                                <span className="crud-head">{item.name}</span>
                                             </div>
                                             <div className="col-md-8">
                                                <div className="row">
                                                   <div className="col-3 crud-check-main">
                                                      <span className="crud-check-head">Create</span>
                                                      <Form.Group className="mb-3" controlId={`create${index}`}>
                                                         <Form.Check
                                                            type="checkbox"
                                                            label=""
                                                            checked={item.create}
                                                            onChange={(e) => {
                                                               mapPermission("create", item, e.target.checked, index, setFieldValue);
                                                            }}
                                                         />
                                                      </Form.Group>
                                                   </div>
                                                   <div className="col-3 crud-check-main">
                                                      <span className="crud-check-head">Read</span>
                                                      <Form.Group className="mb-3" controlId={`read${index}`}>
                                                         <Form.Check
                                                            type="checkbox"
                                                            label=""
                                                            checked={item.read}
                                                            onChange={(e) => {
                                                               mapPermission("read", item, e.target.checked, index, setFieldValue);
                                                            }}
                                                         />
                                                      </Form.Group>
                                                   </div>
                                                   <div className="col-3 crud-check-main">
                                                      <span className="crud-check-head">Update</span>
                                                      <Form.Group className="mb-3" controlId={`update${index}`}>
                                                         <Form.Check
                                                            type="checkbox"
                                                            label=""
                                                            checked={item.update}
                                                            onChange={(e) => {
                                                               mapPermission("update", item, e.target.checked, index, setFieldValue);
                                                            }}
                                                         />
                                                      </Form.Group>
                                                   </div>
                                                   <div className="col-3 crud-check-main">
                                                      <span className="crud-check-head">Delete</span>
                                                      <Form.Group className="mb-3" controlId={`delete${index}`}>
                                                         <Form.Check
                                                            type="checkbox"
                                                            label=""
                                                            checked={item.delete}
                                                            onChange={(e) => {
                                                               mapPermission("delete", item, e.target.checked, index, setFieldValue);
                                                            }}
                                                         />
                                                      </Form.Group>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       );
                                    })}
                                 .
                              </div>
                              <div className="py-3">
                                 <hr className="" />
                                 <div className="d-flex justify-content-end">
                                    <Button type="submit" variant="primary" className="primary-btn mt-3">
                                       Submit
                                    </Button>
                                 </div>
                              </div>
                           </form>
                        );
                     }}
                  </Formik>
               </Card.Body>
            </Card>
         </div>
      </section>
   );
}
