export class EndpointService {
   static loginUrl = "auth/login";
   static forgotPwdUrl = "auth/forgot-password";
   static resetPwdUrl = "auth/reset-password/";
   // MasterData
   static getAllState = "state/list";
   static getLanguageList = "master/language";
   // Institution
   static createInstitutions = "institution/profile";
   static getAllInstitutions = "institution/range";
   static getInstitutionsById = "institution/";
   static updateInstitutions = "institution/editProfile";
   static deleteInstitutions = "institution/";
   // Officers
   static createOfficer = "officer";
   static getAllOfficer = "officer/range";
   static getOfficerById = "officer/";
   static updateOfficer = "officer/";
   static deleteOfficer = "officer/delete";
   static changeOfficersPwdUrl = "officer/change-password?";
   // Customers
   static createCustomer = "customer";
   static getAllCustomer = "customer/range";
   static getCustomerById = "customer/";
   static updateCustomer = "customer/";
   static deleteCustomer = "customer/";
   static changePwdUrl = "customer/change-password";
   static multipleCreate = "customer/multiple-create";
   static multipleUpdate = "customer/multiple-update";
   static typesOfCustomer = "customer/type";
   static uploadCustomer = "officer/profile";
   static businessConsultant = "customer/businessConsultant";
   // Roles
   static createRole = "role";
   static getAllRoles = "role/list";
   static getRolesById = "role/";
   static updateRole = "role/";
   static deleteRole = "role/";
   // component and role component map
   static getAllComponent = "component/list";
   static roleMapComponentPermission = "permission";
   static roleIdWisePermissionDetail = "permission";
   // course
   static createCourse = "course";
   static getAllCourse = "course/range";
   static getCourseId = "course/";
   static updateCourse = "course/";
   static nonMatch = "keyword/non-match/";
   static updateMapping = "keyword/update-mapping"
   static updateCourseStatus = "course/status/";
   // question
   static createQuestion = "question";
   static getCourseQuestions = "question/";
   static updateQuestion = "question/";
   static deleteQuestion = "question/";
   //dashboard
   static dashboardDetails = "/dashboard/"
   //_qus
}
