import { Menu } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CreateInstitution from './createInstitution';
import CreateOfficer from './officers/createOfficer';
import CreateCustomer from './customers/createCustomer';
// import InstitutionMenu from './institutionMenu';
import OfficersList from './officers/officersList';
import CustomersList from './customers/customersList';
import UpdateInstitution from './updateInstitution';
import UpdateOfficer from './officers/updateOfficer';
import UpdateCustomer from './customers/updateCustomer';
import {
  RiAddFill,
  RiArrowRightSLine,
  RiBankFill,
  RiEqualizerFill,
  RiFileEditFill,
  RiLayoutMasonryFill,
  RiSearchLine,
} from "react-icons/ri";
import { images } from "../../assets/images";
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, useLocation, NavLink } from "react-router-dom";
import { Accordion, Button, Form, Nav } from "react-bootstrap";
import { EndpointService } from "../../service/endpointService";
import HTTPService from "../../service/HTTPService";
import { cypherService } from "../../service/cypherService";
import { setIn } from "formik";
import { useDispatch, useSelector, Provider, connect } from 'react-redux';
import { toast } from "react-toastify";

export default function Institutions(props) {

  const [institutionList, setInstitutionList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const institutionData = useSelector(state => state.institution.institutionID);
  const [selectedPath, setSelectedPath] = useState(null);
  const [institutionFlag, setInstitutionFlag] = useState(null);
  const [currentEvent, setCurrentEvent] = useState('');
  const [showAddInstitutions, setShowAddInstitutions] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  let updateInstitution = cypherService.encryptSessionStorage.getItem('updateInstitution');
  let institution_Created = cypherService.encryptSessionStorage.getItem('institutionCreated');
  let loginUserPermission = cypherService.encryptSessionStorage.getItem("loginUserPermission");
  let componentName = cypherService.encryptSessionStorage.getItem("appComponent");

  useEffect(() => {
    getAllInstitutions();
    // alert()
  }, []);

  useEffect(() => {
    if (updateInstitution) {
      updateInstitutionData();
    }
  }, [updateInstitution]);

  useEffect(() => {
    if (institution_Created) {
      newInstitutionCreated();
    }
  }, [institution_Created]);

  const updateInstitutionData = () => {
    let tempUpdateInstitutionData = cypherService.encryptSessionStorage.getItem('updateInstitution');
    let tempFilterList = filteredList;
    let tempIndex = null;
    tempFilterList.map((item, index) => {
      if (item._id === tempUpdateInstitutionData._id) {
        tempIndex = index;
      }
    })
    let tempUpdateInstitutionDataKey = Object.keys(tempUpdateInstitutionData);
    tempUpdateInstitutionDataKey.map((item) => {
      tempFilterList[tempIndex][item] = tempUpdateInstitutionData[item];
    })
    setFilteredList([...filteredList]);
    cypherService.encryptSessionStorage.setItem('institutionName', tempUpdateInstitutionData.name);
    cypherService.encryptSessionStorage.setItem('updateInstitution', null);
  }

  const newInstitutionCreated = () => {
    let tempFilterList = filteredList;
    let filteredListIds = filteredList.map((value) => value._id);
    const apiUrl = EndpointService.getAllInstitutions;
    HTTPService.get(apiUrl, null).then((response) => {
      const institution = response.data;
      institution.map((obj, index) => {
        if (!filteredListIds.includes(obj._id)) {
          tempFilterList.push({
            address1: obj.address1,
            address2: obj.address2,
            city: obj.city,
            createdAt: obj.createdAt,
            createdBy: obj.createdBy,
            name: obj.name,
            pincode: obj.pincode,
            state: obj.state,
            updatedAt: obj.updatedAt,
            _id: obj._id,
            active: false
          })
        }
      })
      setFilteredList([...tempFilterList]);
    }).catch((error) => {
      throw error;
    });
    cypherService.encryptSessionStorage.setItem('institutionCreated', false);
  }

  // getAllInstitutions
  //
  const getAllInstitutions = () => {
    const apiUrl = EndpointService.getAllInstitutions;
    HTTPService.get(apiUrl, null).then((response) => {
      const institution = response.data;
      let _institution = institution.map((obj, index) => {
        return {
          address1: obj.address1,
          address2: obj.address2,
          city: obj.city,
          createdAt: obj.createdAt,
          createdBy: obj.createdBy,
          name: obj.name,
          pincode: obj.pincode,
          state: obj.state,
          updatedAt: obj.updatedAt,
          _id: obj._id,
          active: index === 0
        }
      })
      setSelectedPath('InitCustomers');
      setInstitutionList(_institution);
      setFilteredList(_institution);
      cypherService.encryptSessionStorage.setItem('institutionId', institution[0]._id);
      cypherService.encryptSessionStorage.setItem('institutionName', institution[0].name);
      cypherService.encryptSessionStorage.setItem('institutionCreated', false);
      debugger;
      setCurrentEvent('Customers');
    }).catch((error) => {
      throw error;
    });
  }
  // handleNavLink
  const handleNavLink = (item, path, indexValue) => {

    cypherService.encryptSessionStorage.setItem('institutionId', item._id);
    cypherService.encryptSessionStorage.setItem('institutionName', item.name);
    setSelectedPath(path);
    let _list = [...institutionList];
    _list = _list.map((obj, index) => {
      obj.active = index == indexValue ? true : false;
      return obj;
    });
    setInstitutionList(_list);
    setFilteredList(_list);
    if (path == 'Customers' || path == 'InitCustomers') {
      let rend = parseInt(Math.random() * (9999999 - 111111111) + 1111111 * 345);
      setCurrentEvent(rend);
      navigate('/institution/customers');
    }
    //InitCustomers Officers Customers
    if (path == 'Officers') {
      let rend = parseInt(Math.random() * (9999999 - 111111111) + 1111111 * 345);
      setCurrentEvent(rend);
      navigate('/institution/officers');
    }
  }

  //Search list of objects
  const handleSearch = (event) => {
    // debugger
    const query = event.target.value;
    setSearchQuery(query);
    const searchList = institutionList.filter((item) => {
      return item.name.toLowerCase().includes(query.toLowerCase())
    });
    setFilteredList(searchList);
  };
  const accordionItemEvent = (e) => {
    e.stopPropagation();
  }

  const createInstitution=()=>{
    console.log(loginUserPermission);
    if(loginUserPermission && loginUserPermission.length !=0){
      let flag = false;
      for (let i = 0; i < loginUserPermission.length; i++) {
        let componentId = loginUserPermission[i].componentId;
        if (componentName[componentId] == "Institutions") {
          flag = loginUserPermission[i].create;
          break;
        }
      }
      if(flag == true){
        navigate('/institution/add-institution');
      }else{
        toast.error('Permission is not available');
      } 
    }else{
      navigate('/institution/add-institution');
    }     
  }

  const showInstitutionsFilter = () => {
    setShowAddInstitutions(!showAddInstitutions);
 }
 const hideInstitutionsFilter = () => {
  setShowAddInstitutions(!showAddInstitutions);
 }

  return (
    <section className="main-container">
      {/* <InstitutionMenu institutionFlag={institutionFlag} /> */}


      <button className='add-institution-btn' onClick={showInstitutionsFilter}>+</button>


      <div className={showAddInstitutions ? 'institutions-menu show' : 'institutions-menu'}>
        <div class="fixed-mobile-shadow" onClick={hideInstitutionsFilter}>

        </div>

        <div className='institutions-menu--inner'>
        <h2 className='institutions-menu--header'>Institutions</h2>
        <div className='institutions-menu--search-main d-flex' >
          <div className='institutions-menu--search'>
            <Form.Control type="text" placeholder="Search" value={searchQuery}
              onChange={(e) => { handleSearch(e) }} className='institutions-menu--search' />
            <RiSearchLine className='search-icon' />
          </div>
          <Button className='institutions-menu--add' variant="primary"         
            onClick={() => createInstitution()}>
            <RiAddFill />
          </Button>
        </div>

        <Accordion className='institutions-menu--accordian' defaultActiveKey={0}>
          {filteredList && filteredList.map((item, index) => {
            return (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header>{item.name}</Accordion.Header>
                <Accordion.Body>
                  {item.name == "Grameen Foundation" ?
                    <div className={item.active && selectedPath == 'InitCustomers' ? 'active' : ''}
                      onClick={() => handleNavLink(item, 'InitCustomers', index)}>{`Users`}</div>
                    :
                    <>
                      <div className={item.active && selectedPath == 'Officers' ? 'active' : ''}
                        onClick={() => handleNavLink(item, 'Officers', index)}>{`Officers`}</div>
                      <div className={item.active && selectedPath == 'Customers' ? 'active' : ''}
                        onClick={() => handleNavLink(item, 'Customers', index)}>{`Users`}</div>
                    </>
                  }
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
        </Accordion>
        </div>
      </div>



      <div className="institutions-container ">
        {
          location.pathname == "/institution/officers/add-officer" ? (
            <CreateOfficer permissions={loginUserPermission}/>
          ) : (
            <></>
          )
        }
        {
          location.pathname == "/institution/add-institution" ? (
            <CreateInstitution permissions={loginUserPermission}/>
          ) : (
            <></>
          )
        }
        {
          location.pathname == "/institution/update-institution" ? (
            <UpdateInstitution permissions={loginUserPermission}/>
          ) : (
            <></>
          )
        }
        {
          location.pathname == "/institution/officers/update-officer" ? (
            <UpdateOfficer permissions={loginUserPermission}/>
          ) : (
            <></>
          )
        }
        {
          location.pathname == "/institution/customers" ? (
            <CustomersList permissions={loginUserPermission} currentEvent={currentEvent} />
          ) : (
            <></>
          )
        }

        {
          location.pathname == "/institution/customers/add-customer" ? (
            <CreateCustomer permissions={loginUserPermission}/>
          ) : (
            <></>
          )
        }

        {
          location.pathname == "/institution/customers/update-customer" ? (
            <UpdateCustomer permissions={loginUserPermission}/>
          ) : (
            <></>
          )
        }
        {
          location.pathname == "/institution/officers" ? (
            <OfficersList permissions={loginUserPermission} currentEvent={currentEvent} />
          ) : (
            <></>
          )
        }
      </div>
    </section>
  )
}
