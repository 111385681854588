import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ForgotPassword from "../pages/auth/forgotPassword";
import ResetPassword from "../pages/auth/resetPassword";
import Header from "../layouts/header/Header";
import Sidebar from "../layouts/menu/Sidebar";
import Dashboard from "../pages/home/dashboard";
import Institutions from "../pages/institutions";
import Profile from "../pages/profile";
import ChangePassword from "../pages/profile/changePassword";
import GeocodeMapping from "../pages/geocodeMapping";
import KeywordsMapping from "../pages/keywordsMapping";
import Contents from "../pages/contents";
import CreateCourse from "../pages/contents/courses/createCourse";
import Mcqs from "../pages/contents/courses/mcqs";
import Permissions from "../pages/permissions";
import Login from "../pages/auth/login";
import ProtectedRoute from "./ProtectedRoute";
import Footer from "../components/footer/Footer";

export default function BaseRoutes() {
   const location = useLocation();
   const renderHeaderSidebarComponent = () => {
      if (!(location.pathname == "/") && !(location.pathname == "/forgotpassword") && !(location.pathname == "/resetpassword")) {
         return (
            <>
               <Header />
               <Sidebar {...location} />
               <Footer />
            </>
         );
      }
   };

   return (
      <>
         {renderHeaderSidebarComponent()}

         <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            {/* protected url map */}
            <Route element={<ProtectedRoute />}>
               <Route path="/dashboard" element={<Dashboard />} />
               {/* Institution */}
               <Route path="/institution/officers" element={<Institutions />} />
               <Route path="/institution/add-institution" element={<Institutions />} />
               <Route path="/institution/update-institution" element={<Institutions />} />
               {/* Officer */}
               <Route path="/institution/officers/add-officer" element={<Institutions />} />
               <Route path="/institution/officers/update-officer" element={<Institutions />} />
               {/* Customers */}
               <Route path="/institution/customers" element={<Institutions />} />
               <Route path="/institution/customers/add-customer" element={<Institutions />} />
               <Route path="/institution/customers/update-customer" element={<Institutions />} />
               {/* Permissions */}
               <Route path="/permissions" element={<Permissions />} />
               {/* Contents */}
               <Route path="/contents" element={<Contents />} />
               <Route path="/contents/create-course" element={<CreateCourse />} />
               <Route path="/contents/mcqs" element={<Mcqs />} />
               {/* Keywords mapping */}
               <Route path="/keywords-mapping" element={<KeywordsMapping />} />
               {/* Geocode mapping */}
               <Route path="/geocode-mapping" element={<GeocodeMapping />} />
               {/* Profile */}
               <Route path="/profile/edit-profile" element={<Profile />} />
               <Route path="/profile/change-password" element={<ChangePassword />} />
            </Route>
         </Routes>
      </>
   );
}
