import * as React from "react";
import { images } from "../../assets/images";

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

//map-marker-svgrepo-com.svg

const pinStyle = {
   fill: "#d00",
   stroke: "none",
};

function Pin(props) {
   const { size = 50 } = props;

   return (
      <svg width={size+'px'} height={size+'px'} viewBox="-4 0 36 36" version="1.1">
         <title>map-marker</title>
         <desc>Created with Sketch.</desc>
         <defs></defs>
         <g id="Vivid.JS" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Vivid-Icons" transform="translate(-125.000000, -643.000000)">
               <g id="Icons" transform="translate(37.000000, 169.000000)">
                  <g id="map-marker" transform="translate(78.000000, 468.000000)">
                     <g transform="translate(10.000000, 6.000000)">
                        <path d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z" id="Shape" fill="#FF6E6E"></path>
                        <circle id="Oval" fill="#0C0058" fill-rule="nonzero" cx="14" cy="14" r="7"></circle>
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
}

export default React.memo(Pin);
