import * as Yup from "yup";
let atleastOneSpecialCharRegex = new RegExp("^(?=.*[a-z])(?=.*[!@#\$%\^&\*])(?=.{8,})");
let bussinessEmail = /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;

// Create Officer
const validationSchema = Yup.object({
    roleId: Yup.string().required("User type is required").nullable(),
    permission: Yup.array().min(1).of(Yup.string().required()).required()
});

const initialValues = {
    roleId: "",
    permission:[]
};

export const Schema = {
    initialValues,
    validationSchema
};
