import { createStore, applyMiddleware } from "redux";
import rootReducer from "./rootReducer";
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, logger)));

export default store;

// store is the result of calling createStore, a function from the Redux library.
// createStore takes a reducer as the first argument and in our case we passed in store 

// Redux reducer is just a JavaScript function. It takes two parameters: the current state and action

