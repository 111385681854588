import * as Yup from "yup";
let atleastOneSpecialCharRegex = new RegExp("^(?=.*[a-z])(?=.*[!@#$%^&*])(?=.{8,})");
let bussinessEmail = /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;

// https://github.com/NicKAnderson038/validation-latitude-longitude/blob/master/index.js

const regexLon = /^(?:-|-?(?:\d|[1-9]\d?|1(?:[0-7]\d?)?|1(?:80?)?)(?:\.[0-9]{0,6})?)$/;
const regexLat = /^(?:-|-?(?:\d|[1-8]\d?|90?)(?:\.[0-9]{0,6})?)$/;

// const long = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
// const lat = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;

// let regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
// let regexLon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;


// Create Institution
const validationSchema = Yup.object({
   name: Yup.string().required("Institution name is required").nullable(),
   address1: Yup.string().required("Address Line 1 is required").nullable(),
   address2: Yup.string().required("Address Line 2 is required").nullable(),
   city: Yup.string().required("City is required").nullable(),
   state: Yup.string().required("State is required").nullable(),
   country: Yup.string().nullable(),
   pincode: Yup.string()
      .required("Pincode is required")
      .nullable()
      .matches(/^[0-9]+$/, "Pincode should only contain numeric")
      .min(6, "Pincode should have only 6 digits")
      .max(6, "Pincode should have only 6 digits"),
});

const initialValues = {
   name: "",
   address1: "",
   address2: "",
   city: "",
   state: "",
   country: "India",
   pincode: "",
};

// Create Officer
const officerValidationSchema = Yup.object({
   institutionId: Yup.string().nullable(),
   firstName: Yup.string().required("First name is required").nullable(),
   lastName: Yup.string().required("Last name is required").nullable(),
   mobileNumber: Yup.string()
      .required("Mobile number is required")
      .matches(/^[0-9]+$/, "Please enter only numbers")
      .min(10, "Please enter 10 digit mobile number")
      .max(10, "Please enter 10 digit mobile number"),
   email: Yup.string().required("Email address is required").email("Invalid email address"),
   city: Yup.string().required("City is required").nullable(),
   roleId: Yup.string().required("Officer type is required").nullable(),
   designation: Yup.string().required("Designition is required").nullable(),
   department: Yup.string().required("Department is required").nullable(),
   gender: Yup.string().required("Gender is required").nullable(),
});

const officerInitialValues = {
   institutionId: "",
   firstName: "",
   lastName: "",
   mobileNumber: "",
   email: "",
   city: "Chennai",
   roleId: "",
   designation: "",
   department: "",
   gender: "",
};

// Customer Officer
const customerValidationSchema = Yup.object({
   firstName: Yup.string().required("First name is required").nullable(),
   lastName: Yup.string().required("Last name is required").nullable(),
   mobileNumber: Yup.string()
      .required("Mobile number is required")
      .matches(/^[0-9]+$/, "Please enter only numbers")
      .min(10, "Please enter 10 digit mobile number")
      .max(10, "Please enter 10 digit mobile number"),
   email: Yup.string().required("Email address is required").email("Please enter valid email address"),
   gender: Yup.string().required("Gender is required").nullable(),
   roleId: Yup.string().required("Customer type is required").nullable(),
   dateOfBirth: Yup.string().required("Date of birth is required").nullable(),
   maritalStatus: Yup.string().required("Marital status is required").nullable(),
   language: Yup.string().required("language is required").nullable(),
   // latitude: Yup.string().required("latitude is required").nullable(),
   // longitude: Yup.string().required("longitude is required").nullable()
   latitude: Yup.string().when("roleId", {
      is: (roleId) => roleId == "63a03e92bf6db5b5afd07fd2",
      then: Yup.string().required("latitude is required").nullable().matches(regexLat, "Please enter valid latitude"),
      otherwise: Yup.string(),
   }),
   longitude: Yup.string().when("roleId", {
      is: (roleId) => roleId == "63a03e92bf6db5b5afd07fd2",
      then: Yup.string().required("longitude is required").nullable().matches(regexLon, "Please enter valid latitude"),
      otherwise: Yup.string(),
   }),
});

const customerInitialValues = {
   institutionId: "",
   firstName: "",
   lastName: "",
   mobileNumber: "",
   email: "",
   gender: "",
   roleId: "",
   dateOfBirth: "",
   maritalStatus: "",
   language: "",
   latitude: "",
   longitude: "",
};

export const Schema = {
   validationSchema,
   initialValues,
   officerInitialValues,
   officerValidationSchema,
   customerInitialValues,
   customerValidationSchema,
};
