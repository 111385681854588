import React, { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { images } from "../../../assets/images";
import { RiLockPasswordFill, RiMailFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import { Schema } from "../login/schema";
import HTTPService from "../../../service/HTTPService";
import { cypherService } from "../../../service/cypherService";
import { EndpointService } from "../../../service/endpointService";
import { ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";

function ResetPassword() {
   const navigate = useNavigate();
   const [loader, setLoader] = useState(false);
   const [loginFailMsg, setLoginFailMsg] = useState("");

   const onSubmit = (values) => {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      let tokenValue = urlParams.get("token");
      const apiUrl = EndpointService.resetPwdUrl + tokenValue;
      let payload = {
         password: values.password,
      };
      HTTPService.post(apiUrl, payload)
         .then((response) => {
            debugger;
            const data = response.data;
            if (response.success) {
               debugger
               let msg = response.message + ", Please login";
               toast.success(msg);
               setTimeout(() => {
                  navigate("/");
               }, 3000);
            } else {
               let msg = response.message;
               toast.error(msg);
            }
         })
         .catch((error) => {
            debugger;
            setLoader(false);
            setLoginFailMsg(error.response.data.status.message);
            throw error;
         });
   };
   return (
      <div className="row login">
         <div className="col-md-5 login-img">
            <img src={images.brandLogo} alt="grameen-logo" className="grameen-logo" />
            <div className="leaf">
               <img src={images.leafLeft} alt="grameen-logo" className="leaf-left" />
               <img src={images.leafRight} alt="leaf one" className="leaf-right-1" />
               <img src={images.leafRight2} alt="leaf" className="leaf-right-2" />
            </div>
         </div>

         <div className="col-md-7 login-form-main float-end d-flex align-items-center justify-content-center">
            <Formik initialValues={Schema.resetPwdInitialValues} validationSchema={Schema.resetPwdValidationSchema} onSubmit={onSubmit} enableReinitialize={true}>
               {({ handleSubmit, getFieldProps }) => {
                  return (
                     <form className="login-form" onSubmit={handleSubmit}>
                        <h1>Reset Password</h1>
                        <p>Please choose your new password</p>
                        <FloatingLabel className="with-icon" controlId="floatingPassword" label="New Password">
                           <Form.Control type="password" placeholder="New Password" {...getFieldProps("password")} />
                           <RiLockPasswordFill />
                        </FloatingLabel>
                        <ErrorMessage name="password" component="div" className="error-msg" />
                        <FloatingLabel className="with-icon" controlId="floatingPassword" label="Confirm Password">
                           <Form.Control type="password" placeholder="Confirm Password" {...getFieldProps("confirmPassword")} />
                           <RiLockPasswordFill />
                        </FloatingLabel>
                        <ErrorMessage name="confirmPassword" component="div" className="error-msg" />
                        <div className="mt-5">
                           {/* <Button variant="primary" className='login-btn' onClick={() => { navigate("/dashboard") }}>Reset</Button> */}
                           <Button variant="primary" className="login-btn" type="submit">
                              Reset
                           </Button>
                        </div>
                     </form>
                  );
               }}
            </Formik>
         </div>
      </div>
   );
}

export default ResetPassword;
