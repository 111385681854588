import { Menu } from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { RiAddFill, RiBankFill, RiMailFill, RiPhoneFill, RiSearchLine, RiUserSettingsFill, RiLayoutGridFill, RiBookFill } from "react-icons/ri";
import fileCode from "../../../assets/images/fileCode.svg";

import Select, { components } from "react-select";
import { Card, FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import { Schema } from "../schema";
import { cypherService } from "../../../service/cypherService";
import HTTPService from "../../../service/HTTPService";
import { EndpointService } from "../../../service/endpointService";
import { toast } from "react-toastify";
import { MuiChipsInput } from "mui-chips-input";
import UploadWithDrag from "./uploadWithDrag";
import { ColorRing } from "react-loader-spinner";

const courseCategory = [
   { label: "Category 1", value: 1 },
   { label: "Category 2", value: 2 },
];

export default function CreateCourse() {

   const navigate = useNavigate();
   const [loader, setLoader] = useState(true);
   const [loginFailMsg, setLoginFailMsg] = useState("");
   const [roleList, setRoleList] = useState([]);
   const [chips, setChips] = useState([]);
   const [initialValues, setInitialValues] = useState({
      courseName: "",
      languageId: "",
      keywords: "",
      courseCode: "",
      qrCode: "",
      arCode: "",
   });
   const [languageList, setLanguageList] = useState([]);
   const { ValueContainer, Placeholder } = components;
   const [courseDetail, setCourseDetail] = useState("");

   const CustomValueContainer = ({ children, ...props }) => {
      return (
         <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
               {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
            {!!children && <RiUserSettingsFill />}
            {children}
         </ValueContainer>
      );
   };

   const customStyles = {
      container: (provided, state) => ({
         ...provided,
         marginTop: 50,
      }),
      valueContainer: (provided, state) => ({
         ...provided,
         overflow: "visible",
      }),
      placeholder: (provided, state) => ({
         ...provided,
         position: "absolute",
         top: state.hasValue || state.selectProps.inputValue ? -25 : "",
         left: state.hasValue || state.selectProps.inputValue ? -6 : "",
         color: state.hasValue || state.selectProps.inputValue ? "#007b91" : "#7F8F97",
         transition: "top 0.1s, font-size 0.1s",
         fontSize: (state.hasValue || state.selectProps.inputValue) && 14,
      }),
   };

   const handleChange = (newChips, setFieldValue) => {
      setChips(newChips);
      let value = newChips.length == 0 ? "" : JSON.stringify(newChips);
      setFieldValue("keywords", value);
   };

   useEffect(() => {
      cypherService.encryptSessionStorage.setItem("courseData", "");
      getLanguageList();
   }, []);

   const getLanguageList = () => {
      let apiUrl = EndpointService.getLanguageList;
      HTTPService.get(apiUrl, null).then((response) => {
         setLoader(false);
         if (response && response.data) {
            if (response.data.length != 0) {
               let _list = response.data.map((obj) => {
                  return {
                     _id: obj._id,
                     name: obj.name,
                     languageCode: obj.languageCode,
                     value: obj._id,
                     label: obj.name,
                  };
               });
               if (_list) {
                  setLanguageList(_list);
                  getInstitutionDetails();
               }
            }
         }
         debugger;
      }) .catch((error) => {
         setLoader(false);
         // toast.error(error.response.data.error);
         throw error;
      });
   };
   const getInstitutionDetails = () => {
      if (cypherService.encryptSessionStorage.getItem("courseEvent") == "Edit") {
         let editCourseId = cypherService.encryptSessionStorage.getItem("editCourseId");
         let apiUrl = EndpointService.getCourseId + editCourseId;
         HTTPService.get(apiUrl, null).then((response) => {
            if (response && response.data) {
               setLoader(false);
               setCourseDetail(response.data);
               debugger
               setInitialValues({
                  courseName: response.data.courseName,
                  languageId: response.data.languageId,
                  keywords: JSON.stringify(response.data.keywords),
                  courseCode: response.data.courseCode,
                  qrCode: response.data.arCodeImageUrl,
                  arCode: response.data.arCodeImageUrl,
               });
               console.log('keywords'+response.data.keywords)
               setChips(JSON.parse(response.data.keywords));
            }
         })
         .catch((error) => {
            setLoader(false);
            // toast.error(error.response.data.error);
            throw error;
         });
      }
   };
   // Submit
   const onSubmit = (values) => {
      console.log('submit', values)
      debugger;
      setLoader(true);
      if (cypherService.encryptSessionStorage.getItem("courseEvent") == "Edit") {
         editCourse(values);
      } else {
         createCourse(values);
      }
   };
   const editCourse = (values) => {
      let payLoad = { ...values };
      debugger
      payLoad.keywords=JSON.stringify(JSON.parse(payLoad.keywords))
      
      if(Array.isArray(JSON.parse(payLoad.keywords)))
      {
      }
      else{
         payLoad.keywords=JSON.stringify(JSON.parse(JSON.parse(payLoad.keywords)))
      }
      console.log(courseDetail);
      debugger;
      const apiUrl = EndpointService.updateCourse + courseDetail._id;
      HTTPService.imageWithContentRequestPut(apiUrl, payLoad)
         .then((response) => {
            console.log(response);
            const data = response.data;
            if (response.success === true) {
               cypherService.encryptSessionStorage.setItem("courseData", JSON.stringify(data));
               toast.success("course updated successfully");
               navigate("/contents/mcqs");
            }
            if (response.success == false) {
               setLoader(false);
               toast.error(response.error);
               debugger;
            }
         })
         .catch((error) => {
            debugger;
            setLoader(false);
            toast.error(error.response.data.error);
            throw error;
         });
   };
   const createCourse = (values) => {
      values.institutionId = cypherService.encryptSessionStorage.getItem("institutionId");
      let payLoad = { ...values };
      debugger;
      const apiUrl = EndpointService.createCourse;
      HTTPService.imageWithContentRequest(apiUrl, payLoad)
         .then((response) => {
            console.log(response);
            const data = response.data;
            if (response.success === true) {
               cypherService.encryptSessionStorage.setItem("courseData", JSON.stringify(data));
               toast.success("Course created successfully");
               navigate("/contents/mcqs");
            }
            if (response.success == false) {
               setLoader(false);
               toast.error(response.error);
               debugger;
            }
         })
         .catch((error) => {
            debugger;
            setLoader(false);
            toast.error(error.response.data.error);
            throw error;
         });
   };
   return (
      <section className="main-container">
          {loader ? (
            <div className="loader">
               <ColorRing
                  visible={true}
                  height="60"
                  width="60"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#007B91", "#007B91", "#007B91", "#007B91", "#007B91"]}
               />
            </div>
         ) : null}
         <div className="dashboard-container">
            <Card className="officers-list courses-list">
               <Card.Body>
                  <Card.Title>{cypherService.encryptSessionStorage.getItem("courseEvent")} Course</Card.Title>
                  <Formik initialValues={initialValues} validationSchema={Schema.courseSchema} onSubmit={onSubmit} enableReinitialize={true}>
                     {({ handleSubmit, getFieldProps, setFieldValue, values, errors }) => {
                        console.log('values', values);
                        console.log(Schema.courseSchema);
                        console.log(errors);
                        return (
                           <form className="verical-space-between login-form" onSubmit={handleSubmit}>
                              <div className="row">
                                 <div className="col-xl-6">
                                    <div className="row">
                                    <div className="col-12 col-md-6 col-xl-12">
                                       <FloatingLabel controlId="floatingName" label="Course Name" className="with-icon mb-3">
                                          <Form.Control size="lg" type="text" placeholder="Course Name" {...getFieldProps("courseName")} />
                                          <RiBookFill />
                                       </FloatingLabel>
                                       <ErrorMessage name="courseName" component="div" className="error-msg" />
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-12">
                                       <div className="select-input with-icon">
                                          <Select
                                             className="basic-single"
                                             classNamePrefix="select"
                                             name="languageId"
                                             options={languageList}
                                             components={{
                                                ValueContainer: CustomValueContainer,
                                             }}
                                             placeholder="Course Language"
                                             styles={customStyles}
                                             defaultValue={null}
                                             value={languageList.filter((option) => option.value.toString() == values.languageId.toString())}
                                             onChange={(e) => {
                                                setFieldValue("languageId", e.value);
                                             }}
                                          />
                                          <ErrorMessage name="languageId" component="div" className="error-msg" />
                                       </div>
                                    </div>
                                    {/* languageList */}
                                    <div className="col-12 col-md-6 col-xl-12">
                                       <FloatingLabel controlId="floatingName" label="Course Code" className="with-icon mb-3">
                                          <Form.Control size="lg" type="text" placeholder="Course Code" {...getFieldProps("courseCode")} />
                                          <img src={fileCode} alt="file-code" />
                                       </FloatingLabel>
                                       <ErrorMessage name="courseCode" component="div" className="error-msg" />
                                    </div>

                                    </div>

                                 </div>
                                 <div className="col-xl-6">
                                    <div className="add-keywords">
                                       <label className="d-block mb-2">Course Keywords</label>
                                       <MuiChipsInput
                                          className="chips-input"
                                          value={chips}
                                          onChange={(e) => {
                                             handleChange(e, setFieldValue);
                                          }}
                                       />
                                       <ErrorMessage name="keywords" component="div" className="error-msg" />
                                       {/* <div class="add-keywords-file-upload d-flex justify-content-between">
                                          <div className="file-upload-keywords">
                                             <UploadWithDrag setFieldValue={setFieldValue} name="QR Code" imageSrc={values.qrCode} qrCode />
                                             <ErrorMessage name="qrCode" component="div" className="error-msg" />
                                          </div>
                                          <div className="file-upload-keywords">
                                             <UploadWithDrag setFieldValue={setFieldValue} name="AR Code" imageSrc={values.arCode} arCode />
                                             <ErrorMessage name="arCode" component="div" className="error-msg" />
                                          </div>
                                       </div> */}
                                    </div>
                                 </div>
                              </div>
                              <div className="py-3">
                                 <hr className="" />
                                 <div className="d-flex justify-content-end">
                                    <Button variant="secondary" className="secondary-btn mt-3 me-3" onClick={() => navigate("/contents")}>
                                       Back
                                    </Button>
                                    <Button type="submit" variant="primary" className="primary-btn mt-3">
                                       Next
                                    </Button>
                                 </div>
                              </div>
                           </form>
                        );
                     }}
                  </Formik>
               </Card.Body>
            </Card>
         </div>
      </section>
   );
}
