import { Menu } from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import Header from "../../layouts/header/Header";
import Sidemenu from "../../layouts/menu/Sidebar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";
import { RiAddFill, RiBankFill, RiSearchLine, RiCloseFill } from "react-icons/ri";
import { MdSwapHoriz } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import keywordsImg from "../../assets/images/keywords.png";
import Select, { components } from "react-select";
import { ErrorMessage } from "formik";
import { EndpointService } from "../../service/endpointService";
import HTTPService from "../../service/HTTPService";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { cypherService } from "../../service/cypherService";

export default function KeywordsMapping(props) {

   const [courseList, setCourseList] = useState([]);
   const [nonMatchKey, setNonMatchKey] = useState([]);
   const [courseError, setCourseError] = useState('');
   //move to match key
   const [nonMatchKeyListRemove, setNonMatchKeyListRemove] = useState([]);
   //still unmatch key
   const [nonMatchKeyListAdd, setNonMatchKeyListAdd] = useState([]);
   //still match key
   const [matchKeyListAdd, setMatchKeyListAdd] = useState([]);
   //move to unmatch key
   const [matchKeyListRemove, setMatchKeyListRemove] = useState([]);
   const [loader, setLoader] = useState(true);
   const [courseKeyId, setCourseKeyId] = useState('');
   const [chips, setChips] = useState([]);
   const location = useLocation();
   const { ValueContainer, Placeholder } = components;
   const [keywordsMappingPermission, setKeywordsMappingPermission] = useState({
      create: true,
      delete: true,
      read: true,
      update: true
   });

   let loginUserPermission = cypherService.encryptSessionStorage.getItem("loginUserPermission");
   let componentName = cypherService.encryptSessionStorage.getItem("appComponent");

   useEffect(() => {
      getAllCourseList();
      getNonMatchKey();
      keywordsMappingPermissionInit();
   }, []);

   const keywordsMappingPermissionInit=()=>{   
      if(loginUserPermission){
         for (let i = 0; i < loginUserPermission.length; i++) {
            let componentId = loginUserPermission[i].componentId;
            if (componentName[componentId] == "Keyword Mapping") {
               setKeywordsMappingPermission({
                  create: loginUserPermission[i].create,
                  delete: loginUserPermission[i].delete,
                  read: loginUserPermission[i].read,
                  update: loginUserPermission[i].update,
               });
               break;
            }
          }  
      }           
   }

   const getNonMatchKey = () => {
      let apiUrl = EndpointService.nonMatch;
      HTTPService.get(apiUrl, null).then((response) => {
         if (response && response.data) {
            // let value = response.data.keywords.length == 0 ? "" : JSON.stringify(response.data.keywords);
            var array = response.data.map((obj) => {
               return obj.name;
            })
            setNonMatchKeyListRemove(array)
            setNonMatchKey(response.data);
         }
      });
   }

   const getCourseListById = (courseId) => {
      setCourseError('')
      setCourseKeyId(courseId)
      setNonMatchKeyListRemove([])
      //still unmatch key
      setNonMatchKeyListAdd([])
      //still match key
      setMatchKeyListAdd([])
      //move to unmatch key
      setMatchKeyListRemove([])

      var items = document.getElementsByName('matchless');
      for (var i = 0; i < items.length; i++) {
         if (items[i].type == 'checkbox')
            items[i].checked = false;
      }
      var items = document.getElementsByName('match-key');
      for (var i = 0; i < items.length; i++) {
         if (items[i].type == 'checkbox')
            items[i].checked = false;
      }
      let apiUrl = EndpointService.getCourseId + courseId;
      HTTPService.get(apiUrl, null).then((response) => {
         if (response && response.data) {
            // let value = response.data.keywords.length == 0 ? "" : JSON.stringify(response.data.keywords);
            debugger;
            console.log(JSON.parse(response.data.keywords))
            setChips(JSON.parse(response.data.keywords));
            setMatchKeyListAdd(JSON.parse(response.data.keywords));
         }
      });
   }
   const getAllCourseList = () => {
      // getAllCourse
      const apiUrl = EndpointService.getAllCourse;
      HTTPService.post(apiUrl, null).then((response) => {
         if (response.data.length != 0) {
            let _list = response.data.map((obj) => {
               return {
                  value: obj._id,
                  label: obj.courseName,
               };
            });
            // console.log(_list);
            if (_list) {
               setCourseList(_list);
               setLoader(false);
            }
         }
      });
   };

   const CustomValueContainer = ({ children, ...props }) => {
      return (
         <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
               {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
         </ValueContainer>
      );
   };

   const handleOnChange = (check, value) => {
      if (check) {
         const index = nonMatchKeyListRemove.indexOf(value);
         if (index > -1) {
            nonMatchKeyListRemove.splice(index, 1);
         }
         if (!nonMatchKeyListAdd.includes(value)) {
            nonMatchKeyListAdd.push(value)
         }
      }
      else {
         if (!nonMatchKeyListRemove.includes(value)) {
            nonMatchKeyListRemove.push(value)
         }
         const index = nonMatchKeyListAdd.indexOf(value);
         if (index > -1) {
            nonMatchKeyListAdd.splice(index, 1);
         }
      }
      setNonMatchKeyListAdd(nonMatchKeyListAdd);
      setNonMatchKeyListRemove(nonMatchKeyListRemove);      
   }

   const removeDuplicates = (arr) => {
      let unique = arr.reduce(function (acc, curr) {
         if (!acc.includes(curr))
            acc.push(curr);
         return acc;
      }, []);
      return unique;
   }

   const handleOnChangeKey = (check, value) => {
      if (check) {
         const index = matchKeyListAdd.indexOf(value);
         if (index > -1) {
            matchKeyListAdd.splice(index, 1);
         }
         if (!matchKeyListRemove.includes(value)) {
            matchKeyListRemove.push(value)
         }
      }
      else {
         if (!matchKeyListAdd.includes(value)) {
            matchKeyListAdd.push(value)
         }
         const index = matchKeyListRemove.indexOf(value);
         if (index > -1) {
            matchKeyListRemove.splice(index, 1);
         }
      }
      setMatchKeyListRemove(matchKeyListRemove);
      setMatchKeyListAdd(matchKeyListAdd);      
   }
   const Update = () => {
      if(keywordsMappingPermission.update){
         if (courseKeyId != '') {
            setCourseError('');
            setLoader(true);         
            if (matchKeyListAdd) {
               var addMatchListKey = matchKeyListAdd.concat(nonMatchKeyListAdd)
            }
   
            var deleteUnmatchedKey = nonMatchKeyListAdd;
            var addUnmatchedKey = matchKeyListRemove;         
            var payLoad = {
               "courseKeyId": courseKeyId,
               "addMatchListKey": addMatchListKey,
               "deleteUnmatchedKey": deleteUnmatchedKey,
               "addUnmatchedKey": addUnmatchedKey
            }
            const apiUrl = EndpointService.updateMapping;
            HTTPService.put(apiUrl, payLoad)
               .then((response) => {
                  if (response.success === true) {
                     setLoader(false)
                     toast.success(response.message);
                     getAllCourseList();
                     getNonMatchKey();
                     getCourseListById(courseKeyId);
                  }
                  if (response.success == false) {
                     setLoader(false);
                     toast.error(response.error);
                     debugger;
                  }
               })
               .catch((error) => {
                  setLoader(false);
               });
         }
         else {
            setCourseError('Please select course')
         }
      }else{
         toast.error('Permission is not available');
      }      
   }

   const customStyles = {
      container: (provided, state) => ({
         ...provided,
         marginTop: 50,
      }),
      valueContainer: (provided, state) => ({
         ...provided,
         overflow: "visible",
      }),
      placeholder: (provided, state) => ({
         ...provided,
         position: "absolute",
         top: state.hasValue || state.selectProps.inputValue ? -25 : "",
         left: state.hasValue || state.selectProps.inputValue ? -6 : "",
         color: state.hasValue || state.selectProps.inputValue ? "#007b91" : "#7F8F97",
         transition: "top 0.1s, font-size 0.1s",
         fontSize: (state.hasValue || state.selectProps.inputValue) && 14,
      }),
   };

   return (
      <section className="main-container">
         {loader ? (
            <div className="loader">
               <ColorRing
                  visible={true}
                  height="60"
                  width="60"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#007B91", "#007B91", "#007B91", "#007B91", "#007B91"]}
               />
            </div>
         ) : null}
         <div className="dashboard-container">
            {/* <div>
          <img src={keywordsImg} className="img-fluid" />
        </div> */}

            <Card className="officers-list">
               <Card.Body>
                  <Card.Title>Keywords Mapping</Card.Title>
                  <div className="dual-list-container login-form">
                     <div className="row mb-4 course-dropdown">
                        <div className="col-md-6">
                           <div className="select-input">
                              <Select
                                 className="basic-single"
                                 classNamePrefix="select"
                                 name="course"
                                 options={courseList}
                                 components={{
                                    ValueContainer: CustomValueContainer,
                                 }}
                                 placeholder="Course"
                                 styles={customStyles}
                                 defaultValue={null}
                                 onChange={(e) => {
                                    // setCourseId(e.value);
                                    getCourseListById(e.value);
                                 }}
                              />
                              <div name="course" component="div" className="error-msg">{courseError}</div>
                           </div>
                        </div>
                     </div>
                     <div className="row dual-list">
                        {/* New keywords */}
                        <Card className="col new-keywords-list ">
                           <Card.Title>
                              <Form.Group controlId="newkeywords">
                                 <Form.Check type="checkbox" label={<span>New Keywords</span>} />
                              </Form.Group>
                           </Card.Title>
                           <Card.Body>
                              {nonMatchKey &&
                                 nonMatchKey.map((item, index) => {
                                    return (
                                       <Form.Group className="mb-3" controlId={item.id} >
                                          <Form.Check
                                             onClick={(e) => {
                                                handleOnChange(e.target.checked, item.name)
                                             }}
                                             name="matchless"
                                             type="checkbox"
                                             label={
                                                <>
                                                   <span>{item.name}</span>
                                                </>
                                             }
                                          />
                                          <strong className="close">
                                             {item.count}
                                          </strong>
                                       </Form.Group>
                                    );
                                 })}
                           </Card.Body>
                        </Card>

                        <div className="update-icon">

                           <MdSwapHoriz onClick={() => {
                              Update()
                           }} />

                        </div>

                        {/* Existing keywords */}
                        <Card className="col existing-keywords-list">
                           <Card.Title>
                              <Form.Group controlId="existingKeywords">
                                 <Form.Check type="checkbox" label={<span>Existing Keywords</span>} />
                              </Form.Group>
                           </Card.Title>
                           <Card.Body>
                              {chips &&
                                 chips.map((item, index) => {
                                    return (
                                       <Form.Group className="mb-3" controlId={item}>
                                          <Form.Check
                                             type="checkbox"
                                             name="match-key"
                                             onClick={(e) => {
                                                handleOnChangeKey(e.target.checked, item)
                                             }}
                                             label={
                                                <>
                                                   <span>{item}</span>
                                                </>
                                             }
                                          />
                                          {/* <strong className="close">
                                             <RiCloseFill />
                                          </strong> */}
                                       </Form.Group>
                                    );
                                 })}

                           </Card.Body>
                        </Card>
                     </div>
                  </div>
               </Card.Body>
            </Card>
            <div className="mt-5">                
            </div>
         </div>
      </section>
   );
}
