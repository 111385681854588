import * as Yup from "yup";

const FILE_SIZE = 1024 * 1024;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png"
];

// courseSchema
const courseSchema = Yup.object({   
    courseName: Yup.string().required('Course name is required').nullable(),
    courseName: Yup.string().required('Course name is required').nullable(),
    languageId:Yup.string().required('Course language is required').nullable(),
    courseCode:Yup.string().required('Course code is required').nullable(),
    // test qrCode:Yup.mixed().required('Course code is required').nullable(),
    qrCode: Yup.mixed().required('QR code is required').nullable(),
    // .test(
    //   "fileFormat",
    //   "Image format not supported, Please upload image in jpg, jpeg, png",
    //   (file) => {
    //     if (file) {
    //       return SUPPORTED_FORMATS.includes(file.type)
    //     } else {
    //       return true;
    //     }
    //   }
    // )
    // .test(
    //   "fileSize",
    //   "Image size should not exceed 1 MB, Please upload again",
    //   (file) => {
    //     if (file) {
    //       return file.size < FILE_SIZE
    //     } else {
    //       return true;
    //     }
    //   }
    // ),
    arCode: Yup.mixed().required('AR code is required').nullable(),
    // .test(
    //   "fileFormat",
    //   "Image format not supported, Please upload image in jpg, jpeg, png",
    //   (file) => {
    //     if (file) {
    //       return SUPPORTED_FORMATS.includes(file.type)
    //     } else {
    //       return true;
    //     }
    //   }
    // )
    // .test(
    //   "fileSize",
    //   "Image size should not exceed 1 MB, Please upload again",
    //   (file) => {
    //     if (file) {
    //       return file.size < FILE_SIZE
    //     } else {
    //       return true;
    //     }
    //   }
    // )

});

let allAnswerShouldNotSame = 'All answer should not be same';

const mcqValidationSchema = Yup.object({   
    questionName: Yup.string().required('Question name is required').nullable()
    .max(100, 'Maximum 100 characters only allowed'),
    option1: Yup.string().required('Answer 1 is required').nullable().max(75, 'Maximum 75 characters only allowed')
    .notOneOf([Yup.ref('option2'),Yup.ref('option3'),Yup.ref('option4')],allAnswerShouldNotSame),
    option2: Yup.string().required('Answer 2 is required').nullable().max(75, 'Maximum 75 characters only allowed')
    .notOneOf([Yup.ref('option1'),Yup.ref('option3'),Yup.ref('option4')],allAnswerShouldNotSame),
    rightAnswerMessage: Yup.string().required('Right Answer Message is required'),
    wrongAnswerMessage: Yup.string().required('Wrong Answer Message is required'),
    correctAnswer:Yup.string().required('Correct answer is required').nullable(),
});

export const Schema = {
    courseSchema,
    mcqValidationSchema
};