import { Menu } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { RiAddFill, RiBankFill, RiMailFill, RiPhoneFill, RiSearchLine, RiUserSettingsFill } from "react-icons/ri";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import { Schema } from "../schema";
import { cypherService } from "../../../service/cypherService";
import HTTPService from "../../../service/HTTPService";
import { EndpointService } from "../../../service/endpointService";
import { toast } from "react-toastify";
import Map, { Marker, NavigationControl } from "react-map-gl";
import Pin from "../../../helpers/mapboxLib/pin";
import { MarkerDragEvent, LngLat } from "react-map-gl";
import { AppConstant } from "../../../constants/appConstant";

import mapboxgl from "mapbox-gl";
const TOKEN = AppConstant.mapboxToken; // Set your mapbox token here
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function CreateCustomer() {
    
   const navigate = useNavigate();
   const [loader, setLoader] = useState(false);
   const [loginFailMsg, setLoginFailMsg] = useState("");
   const [customerTypeList, setCustomerTypeList] = useState([]);
   const [dateOfBirth, setDateOfBirth] = useState("");
   const [customerInitialValues, setCustomerInitialValues] = useState(Schema.customerInitialValues);
   const [storeInstitutionName, setStoreInstitutionName] = useState(cypherService.encryptSessionStorage.getItem("institutionName"));
   const [selectedCustomerType, setSelectCustomerType] = useState("");
   const [languageList, setLanguageList] = useState([]);
   const [marker, setMarker] = useState({
      latitude: 20.5937,
      longitude: 78.9629,
   });
   const [events, logEvents] = useState({});
   const [latitude,setLatitude] = useState();
   const [longitude,setLongitude] = useState();
   const [location,setLocation] = useState(false);
   const [viewport, setViewport] = useState({
      width: "100%",
      height: "100%",
      latitude: 0,
      longitude: 0,
      zoom: 10
    });
  
    const [initialViewState, setInitialViewState] = useState({
      latitude: 19.16479778618988,
      longitude: 77.3613406707887,
      zoom: 5,
   });
   const gender = [
      { label: "Male", value: "m" },
      { label: "Female", value: "f" },
   ];
   const maritalStatus = [
      { label: "Married", value: true },
      { label: "Unmarried", value: false },
   ];

   useEffect(() => {
      getLanguageList();
   }, []);

   const getLanguageList = () => {
      let apiUrl = EndpointService.getLanguageList;
      HTTPService.get(apiUrl, null).then((response) => {
         if (response && response.data) {
            if (response.data.length != 0) {
               let _list = response.data.map((obj) => {
                  return {
                     label: obj.name,
                     value: obj._id,
                  };
               });
               console.log(_list);

               if (_list) {
                  // const obj = Object.fromEntries(_list.map((item) => [item._id, item.name]));
                  // console.log(obj);
                  setLanguageList(_list);
                  // getAllCourseList();
               }
            }
         }
         debugger;
      });
   };

   const { ValueContainer, Placeholder } = components;

   const CustomValueContainer = ({ children, ...props }) => {
      return (
         <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
               {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
         </ValueContainer>
      );
   };

   // const CustomMarker = ({ index, marker }) => {
   //    return (
   //       <Marker longitude={marker.longitude} latitude={marker.latitude}>
   //          <div className="marker">
   //             <span>
   //                <b>{index + 1}</b>
   //             </span>
   //          </div>
   //       </Marker>
   //    );
   // };

   const customStyles = {
      container: (provided, state) => ({
         ...provided,
         marginTop: 50,
      }),
      valueContainer: (provided, state) => ({
         ...provided,
         overflow: "visible",
      }),
      placeholder: (provided, state) => ({
         ...provided,
         position: "absolute",
         top: state.hasValue || state.selectProps.inputValue ? -25 : "",
         left: state.hasValue || state.selectProps.inputValue ? -6 : "",
         color: state.hasValue || state.selectProps.inputValue ? "#007b91" : "#7F8F97",
         transition: "top 0.1s, font-size 0.1s",
         fontSize: (state.hasValue || state.selectProps.inputValue) && 14,
      }),
   };

   // getCustomerType
   const getCustomerType = () => {
      const apiUrl = EndpointService.typesOfCustomer;
      HTTPService.post(apiUrl, {})
         .then((response) => {
            console.log(response);
            let cusTypeList = response.data;
            let _typeList = [];
            let customerID = null;

            cusTypeList.filter((item) => {
               if (cypherService.encryptSessionStorage.getItem("institutionName") == "Grameen Foundation") {
                  debugger;
                  if (item.name == "Customer") {
                     _typeList.push({
                        value: item._id,
                        label: item.name,
                     });
                     customerID = item._id;
                  }
                  return true;
               } else {
                  _typeList.push({
                     value: item._id,
                     label: item.name,
                  });
                  return true;
               }
            });

            if (_typeList) {
               setCustomerTypeList(_typeList);
               setCustomerInitialValues({
                  institutionId: "",
                  firstName: "",
                  lastName: "",
                  mobileNumber: "",
                  email: "",
                  gender: "",
                  roleId: customerID ? customerID : "",
                  dateOfBirth: "",
                  maritalStatus: "",
                  language: "",
                  latitude: "",
                  longitude: "",
               });
            }
         })
         .catch((error) => {
            throw error;
         });
   };

   useEffect(() => {
      getCustomerType();
   }, []);

   const onSubmit = (values) => {
      setLoader(true);
      let institutionId = cypherService.encryptSessionStorage.getItem("institutionId");
      let payLoad = { ...values, institutionId };
      const apiUrl = EndpointService.createCustomer;
      HTTPService.post(apiUrl, payLoad)
         .then((response) => {
            console.log(response);
            const data = response.data;
            if (response.success === true) {
               navigate("/institution/customers");
               toast.success(response.message);
            }
            if (response.success === false) {
               setLoader(false);
               if(response.message){
                  toast.error(response.message);
               }else{
                  if(response.error.errors.email){
                     toast.error('Email id should be unique');
                  }
                  if(response.error.errors.mobileNumber){
                     toast.error('Mobile number should be unique');
                  }
               }                
            }
         })
         .catch((error) => {
            debugger;
            setLoader(false);
            toast.error(error.response.data.error);
            throw error;
         });
   };
   const selectCustomerType = (e, setFieldValue) => {
      setFieldValue("roleId", e.value);
      setSelectCustomerType(e.label);
   };
   const onMarkerDragEnd = (event, setFieldValue) => {
  
      setMarker({
         longitude: event.lngLat.lng,
         latitude: event.lngLat.lat,
      });
      setFieldValue("latitude", event.lngLat.lat);
      setFieldValue("longitude", event.lngLat.lng);
      setLatitude(event.lngLat.lat)
      setLongitude(event.lngLat.lng)
   };
   useEffect(()=>{
      if(latitude && longitude && Number(latitude)<90 && Number(longitude)<90 && Number(latitude)>(-90) && Number(longitude)>(-90))
      {
      
         setLocation(true)
         navigator.geolocation.getCurrentPosition(
            position => {
              setViewport(prevViewport => ({
                ...prevViewport,
                latitude: latitude,
                longitude: longitude
              }));
            //   setInitialViewState({
            //    latitude: latitude,
            //    longitude:longitude,
            //    zoom: 5,
            //   })
            },
            error => console.log(error),
            { enableHighAccuracy: true }
          );
      }
      else{
         setLocation(false)
      }

   },[latitude,longitude])
   const latitudeCustomEvent = (event, setFieldValue) => {
      setFieldValue("latitude", event.target.value);
      if(event.target.value && Number(event.target.value)<90 && Number(event.target.value)>(-90))
      {
      setLatitude(event.target.value)
      }
      let _marker = marker;
      setMarker({
         longitude: _marker.longitude,
         latitude: event.target.value,
      });
      // navigator.geolocation.getCurrentPosition(
      //    position => {
      //      setViewport(prevViewport => ({
      //        ...prevViewport,
      //        latitude: event.target.value,
      //        longitude: longitude
      //      }));
      //      setInitialViewState({
      //       latitude: event.target.value,
      //       longitude:longitude,
      //       zoom: 5,
      //      })
      //    },
      //    error => console.log(error),
      //    { enableHighAccuracy: true }
      //  );
   };
   const longitudeCustomEvent = (event, setFieldValue) => {
      setFieldValue("longitude", event.target.value);
      if(event.target.value && Number(event.target.value)<90 && Number(event.target.value)>(-90))
      {
      setLongitude(event.target.value)
      }
      // navigator.geolocation.getCurrentPosition(
      //    position => {
      //      setViewport(prevViewport => ({
      //        ...prevViewport,
      //        latitude: latitude,
      //        longitude: event.target.value
      //      }));
      //      setInitialViewState({
      //       latitude: event.target.value,
      //       longitude:longitude,
      //       zoom: 5,
      //      })
      //    },
      //    error => console.log(error),
      //    { enableHighAccuracy: true }
      //  );
      let _marker = marker;
      setMarker({
         longitude: event.target.value,
         latitude: _marker.latitude,
      });
   };

   return (
      <Card className="officers-list">
         <Card.Body>
            <Card.Title>Create New User</Card.Title>
            <Formik initialValues={customerInitialValues} validationSchema={Schema.customerValidationSchema} onSubmit={onSubmit} enableReinitialize={true}>
               {({ handleSubmit, getFieldProps, setFieldValue, values ,errors}) => {
                  console.log(errors);
                  console.log(Schema.customerValidationSchema);
                  return (
                     <form className="verical-space-between login-form" onSubmit={handleSubmit}>
                        <div className="row">
                           <div className="col-12">
                              <div className="row">
                                 <div className="col-md-6">
                                    <div className="">
                                       <FloatingLabel controlId="floatingName" label="First Name" className="with-icon mb-3">
                                          <Form.Control size="lg" type="text" placeholder="First Name" {...getFieldProps("firstName")} />
                                          <RiUserSettingsFill />
                                       </FloatingLabel>
                                       <ErrorMessage name="firstName" component="div" className="error-msg" />
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="">
                                       <FloatingLabel controlId="floatingLastName" label="Last Name" className="with-icon mb-3">
                                          <Form.Control size="lg" type="text" placeholder="Last Name" {...getFieldProps("lastName")} />
                                          <RiUserSettingsFill />
                                       </FloatingLabel>
                                       <ErrorMessage name="lastName" component="div" className="error-msg" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="">
                                 <FloatingLabel controlId="floatingMobile" label="Mobile Number" className="with-icon mb-3">
                                    <Form.Control size="lg" type="text" placeholder="Mobile Number" maxLength={10} {...getFieldProps("mobileNumber")} />
                                    <RiPhoneFill />
                                 </FloatingLabel>
                                 <ErrorMessage name="mobileNumber" component="div" className="error-msg" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="">
                                 <FloatingLabel controlId="floatingEmailId" label="Email Id" className="with-icon mb-3">
                                    <Form.Control size="lg" type="text" placeholder="Email Id" {...getFieldProps("email")} />
                                    <RiMailFill />
                                 </FloatingLabel>
                                 <ErrorMessage name="email" component="div" className="error-msg" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="select-input">
                                 <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="gender"
                                    options={gender}
                                    components={{
                                       ValueContainer: CustomValueContainer,
                                    }}
                                    placeholder="Gender"
                                    styles={customStyles}
                                    defaultValue={null}
                                    value={gender.filter((option) => option.value.toString() == values.gender.toString())}
                                    onChange={(e) => {
                                       setFieldValue("gender", e.value);
                                    }}
                                 />
                                 <ErrorMessage name="gender" component="div" className="error-msg" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="select-input">
                                 <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="roleId"
                                    options={customerTypeList && customerTypeList}
                                    components={{
                                       ValueContainer: CustomValueContainer,
                                    }}
                                    placeholder="Customer Type"
                                    styles={customStyles}
                                    defaultValue={null}
                                    value={customerTypeList && customerTypeList.filter((option) => option.value == values.roleId)}
                                    onChange={(e) => {
                                       selectCustomerType(e, setFieldValue);
                                    }}
                                 />

                                 <ErrorMessage name="roleId" component="div" className="error-msg" />
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="select-input">
                                 <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="maritalStatus"
                                    options={maritalStatus}
                                    components={{
                                       ValueContainer: CustomValueContainer,
                                    }}
                                    placeholder="Marital Status"
                                    styles={customStyles}
                                    defaultValue={null}
                                    value={maritalStatus.filter((option) => option.value.toString() == values.maritalStatus.toString())}
                                    onChange={(e) => {
                                       setFieldValue("maritalStatus", e.value);
                                    }}
                                 />
                                 <ErrorMessage name="maritalStatus" component="div" className="error-msg" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="select-input">
                                 <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="language"
                                    options={languageList}
                                    components={{
                                       ValueContainer: CustomValueContainer,
                                    }}
                                    placeholder="Language List"
                                    styles={customStyles}
                                    defaultValue={null}
                                    value={languageList.filter((option) => option.value.toString() == values.language.toString())}
                                    onChange={(e) => {
                                       setFieldValue("language", e.value);
                                    }}
                                 />
                                 <ErrorMessage name="language" component="div" className="error-msg" />
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="customer-date-picker">
                                 <FloatingLabel controlId="floatingDesignation" className="mb-3">
                                    {/* <Form.Control size="lg" type="text" placeholder="Date of Birth" {...getFieldProps("designation")} /> */}
                                    <DatePicker
                                       dateFormat="MM/dd/yyyy"
                                       selected={dateOfBirth}
                                       peekNextMonth
                                       showMonthDropdown
                                       showYearDropdown
                                       dropdownMode="select"
                                       autoComplete="off"
                                       className="form-control"
                                       placeholderText="Date of Birth"
                                       onChange={(date) => {
                                          setDateOfBirth(date);
                                          setFieldValue("dateOfBirth", date);
                                       }}
                                    />

                                 </FloatingLabel>
                                 <ErrorMessage name="dateOfBirth" component="div" className="error-msg" />
                              </div>
                           </div>
                           {selectedCustomerType == "Business Correspondence" ? (
                              <div className="col-md-6">
                                 <div className="">
                                    <FloatingLabel controlId="floatingName" label="latitude" className="mb-3">
                                       <Form.Control
                                          size="lg"
                                          type="text"
                                          placeholder="latitude"
                                          value={values.latitude}
                                          onChange={(e) => {
                                             latitudeCustomEvent(e, setFieldValue);
                                             // setFieldValue("latitude", e.value);
                                          }}
                                       />
                                    </FloatingLabel>
                                    <ErrorMessage name="latitude" component="div" className="error-msg" />
                                 </div>
                              </div>
                           ) : (
                              <></>
                           )}
                           {selectedCustomerType == "Business Correspondence" ? (
                              <>
                                 <div className="col-md-6">
                                    <div className="">
                                       <FloatingLabel controlId="floatingName" label="longitude" className="mb-3">
                                          <Form.Control
                                             size="lg"
                                             type="text"
                                             placeholder="longitude"
                                             value={values.longitude}
                                             // {...getFieldProps("longitude")}
                                             onChange={(e) => {
                                                longitudeCustomEvent(e, setFieldValue);
                                                // setFieldValue("latitude", e.value);
                                             }}
                                          />
                                       </FloatingLabel>
                                       <ErrorMessage name="longitude" component="div" className="error-msg" />
                                    </div>
                                 </div>

                                 {/* https://github.com/visgl/react-map-gl/blob/7.0-release/examples/draggable-markers/src/app.tsx */}
                                 {/* https://github.com/visgl/react-map-gl/blob/7.0-release/examples/draggable-markers/src/app.tsx */}


                                 {/* <Map initialViewState={initialViewState} mapStyle="mapbox://styles/mapbox/navigation-day-v1" mapboxAccessToken={TOKEN}>
                                    <Marker latitude={marker.latitude} longitude={marker.longitude} anchor="bottom" draggable onDragEnd={(e) => onMarkerDragEnd(e, setFieldValue)}>
                                       <Pin size={20} />
                                    </Marker>
                                    <NavigationControl />
                                 </Map> */}
                                 {/* <ControlPanel events={events} /> */}
                                 {
                                    // values.latitude && values.longitude ? (
                                    //    <Map initialViewState={initialViewState} mapStyle="mapbox://styles/mapbox/navigation-day-v1" mapboxAccessToken={TOKEN}>
                                    //       <Marker latitude={marker.latitude} longitude={marker.longitude} anchor="bottom" draggable onDragEnd={(e) => onMarkerDragEnd(e, setFieldValue)}>
                                    //          <Pin size={20} />
                                    //       </Marker>
                                    //       <NavigationControl />
                                    //    </Map>
                                    // ):(
                                    //    <></>
                                    // )
                                 }
                                 
                                 {
                                    location ? (
                                       <Map  initialViewState={viewport} mapStyle="mapbox://styles/mapbox/navigation-day-v1" mapboxAccessToken={TOKEN}
                                     key={Math.floor(Math.random() * 10000000)} markerZoomAnimation={true} boxZoom={true}>
                                          <Marker latitude={latitude} longitude={longitude} anchor="center"
                                             draggable onDragEnd={(e) => onMarkerDragEnd(e, setFieldValue)}>
                                             <Pin size={20} />
                                          </Marker>
                                          <NavigationControl />
                                       </Map>
                                    ) : (
                                       <></>
                                    )
                                 }

                              </>
                           ) : (
                              <></>
                           )}
                        </div>
                        <div className="py-3">
                           <hr className="" />
                           <div className="d-flex justify-content-end">
                              <Button variant="secondary" className="secondary-btn mt-3 me-3" onClick={() => navigate(-1)}>
                                 Back
                              </Button>
                              <Button type="submit" variant="primary" className="primary-btn mt-3">
                                 Create
                              </Button>
                           </div>
                        </div>
                     </form>
                  );
               }}
            </Formik>
         </Card.Body>
      </Card>
   );
}
