import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);


export default function ChartTwo(props) {

  var assignedCustomer = props.customerAnalytics ? props.customerAnalytics.assignedCustomerCount ? props.customerAnalytics.assignedCustomerCount : 0 : 0;
  
  const data = {
    labels: ['Assigned Customers'],
    datasets: [
      {
        label: '',
        data: [assignedCustomer,],
        // borderWidth: (a, b, c) => (a.dataset.data[a.dataIndex] === 0 ? 0 : 1),
        backgroundColor: [
          '#81CFE2',
        ],
        borderColor: [
          '#81CFE2',
        ],
        borderWidth: 1,
        
      },
    ],
  }

  const plugins = [{
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = (height / 200).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      var text = "Institutions",
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }]

  return (
    <div className='doughnut-chart-2'>
      <Doughnut data={data} plugins={plugins}
        options={{
          cutout: "75%",
          plugins: {
            legend: {
              display: false
            },
            emptyDoughnut: {
              color: 'rgba(255, 128, 0, 0.5)',
              width: 30,
              radiusDecrease: 20
            }
          },
          responsive: true,
          maintainAspectRatio: true,
        }} key={Math.random() * 100} />
    </div>
  )
}
