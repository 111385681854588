import * as Yup from "yup";
let atleastOneSpecialCharRegex = new RegExp("^(?=.*[a-z])(?=.*[!@#\$%\^&\*])(?=.{8,})");
let bussinessEmail = /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;

// Login
const validationSchema = Yup.object({
    email: Yup.string()
        .required("Email address is required")
        .email("Invalid email address"),
    password: Yup.string().required("Password is required"),
});

const initialValues = {
    email: "",
    password: "",
};

// Forgot password
const forgotValidationSchema = Yup.object({
    email: Yup.string()
        .required("Email address is required")
        .email("Invalid email address"),
});

const forgotInitialValues = {
    email: "",
};

// Reset password
const resetPwdValidationSchema = Yup.object({
    password: Yup.string().required('Password is required')
        .nullable()
        .strict()
        .trim('Space not allowed')
        .min(8, 'Password should be min 8')
        .max(40, "Password should be max 40")
        .matches(/[a-z]+/, "One lowercase,uppercase,number,special character")
        .matches(/[A-Z]+/, "One lowercase,uppercase,number,special character")
        .matches(/[@$!%*#?&]+/, "One lowercase,uppercase,number,special character")
        .matches(/\d+/, "One lowercase,uppercase,number,special character"),
    confirmPassword: Yup.string().required('Confirm password is required')
        .nullable()
        .strict()
        .trim('Space not allowed')
        .min(8, 'Password should be min 8')
        .max(40, "Password should be max 40")
        .oneOf([Yup.ref('password')], 'Passwords do not match')
});

const resetPwdInitialValues = {
    password: "",
    confirmPassword: "",
};

// Change password
const changePwdValidationSchema = Yup.object({
    old_password: Yup.string().required('Old password is required')
        .nullable()
        .strict()
        .trim('Space not allowed')
        .min(8, 'Password should be min 8')
        .max(40, "Password should be max 40")
        .matches(atleastOneSpecialCharRegex, "Must contain atleast 1 special character"),
    new_password: Yup.string().required('New password is required')
        .nullable()
        .strict()
        .trim('Space not allowed')
        .min(8, 'Password should be min 8')
        .max(40, "Password should be max 40")
        .matches(/[a-z]+/, "One lowercase,uppercase,number,special character")
        .matches(/[A-Z]+/, "One lowercase,uppercase,number,special character")
        .matches(/[@$!%*#?&]+/, "One lowercase,uppercase,number,special character")
        .matches(/\d+/, "One lowercase,uppercase,number,special character"),
    confirm_password: Yup.string().required('Confirm password is required')
        .nullable()
        .strict()
        .trim('Space not allowed')
        .min(8, 'Password should be min 8')
        .max(40, "Password should be max 40")
        .oneOf([Yup.ref('new_password')], 'Passwords do not match')
});

const changePwdInitialValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
};

export const Schema = {
    validationSchema,
    initialValues,
    forgotInitialValues,
    forgotValidationSchema,
    resetPwdValidationSchema,
    resetPwdInitialValues,
    changePwdValidationSchema,
    changePwdInitialValues
};
