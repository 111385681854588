import * as Yup from "yup";
let atleastOneSpecialCharRegex = new RegExp("^(?=.*[a-z])(?=.*[!@#\$%\^&\*])(?=.{8,})");
let bussinessEmail = /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;

// Create Officer
const officerValidationSchema = Yup.object({
    firstName: Yup.string().required('First name is required').nullable(),
    lastName: Yup.string().required('Last name is required').nullable(),
    mobileNumber: Yup.string()
    .required('Mobile number is required')
    .matches(/^[0-9]+$/, "Please enter only numbers")
    .min(10, 'Please enter 10 digit mobile number')
    .max(10, 'Please enter 10 digit mobile number'),
    email: Yup.string().required("Email address is required").email("Invalid email address"),
});

const officerInitialValues = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: ""
};

// Change password
const changePwdValidationSchema = Yup.object({
    // currentPassword: Yup.string().required('Current password is required')
    //     .nullable()
    //     .strict()
    //     .trim('Space not allowed')
    //     .min(8, 'Password should be min 8')
    //     .max(40, "Password should be max 40")
    //     .matches(atleastOneSpecialCharRegex, "Must contain atleast 1 special character"),
    newPassword: Yup.string().required('New password is required')
        .nullable()
        .strict()
        .trim('Space not allowed')
        .min(8, 'Password should be min 8')
        .max(40, "Password should be max 40")
        .matches(/[a-z]+/, "One lowercase,uppercase,number,special character")
        .matches(/[A-Z]+/, "One lowercase,uppercase,number,special character")
        .matches(/[@$!%*#?&]+/, "One lowercase,uppercase,number,special character")
        .matches(/\d+/, "One lowercase,uppercase,number,special character"),
    confirmPassword: Yup.string().required('Confirm password is required')
        .nullable()
        .strict()
        .trim('Space not allowed')
        .min(8, 'Password should be min 8')
        .max(40, "Password should be max 40")
        .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
});

const changePwdInitialValues = {
    // currentPassword: "",
    newPassword: "",
    confirmPassword: "",
};

export const Schema = {   
    officerInitialValues,
    officerValidationSchema,
    changePwdValidationSchema,
    changePwdInitialValues
};
