import React, { useEffect, useState } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { RiArrowDownSLine, RiLockPasswordFill, RiLogoutBoxLine, RiNotification4Fill, RiPencilFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { images } from '../../assets/images';
import { cypherService } from '../../service/cypherService';

export default function Header() {

  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [userId, setUserId] = useState("");
  const [userImage, setUserImage] = useState("");
  const [institutionImage, setInstitutionImage] = useState("")

  useEffect(() => {
    getUserName();
    getUserId();
    getUserImage();
    getInstitutionImage();
  },[]);

  const getUserName = () => {
    setFullName(cypherService.encryptSessionStorage.getItem('userName'))
  }
  const getUserId = () => {
    setUserId(cypherService.encryptSessionStorage.getItem('userId'))
  }
  const getUserImage = () => {
    var image = cypherService.encryptSessionStorage.getItem('userImage')
    if (image == "") {
      image = images.user
    }
    setUserImage(image);
  }
  const getInstitutionImage = () => {
    var image = cypherService.encryptSessionStorage.getItem('institutionImage')
    setInstitutionImage(image);
  }
  const logout = () => {
    sessionStorage.clear();
    navigate('/');
  }
  const gotoDashbord = () => {
    navigate('/dashboard');   
    if (document.getElementsByClassName('sidebar')[0].className == 'sidebar sidebar--small') {
      document.getElementsByClassName('sidebar')[0].className = 'sidebar';
    }
  }
  
  return (
    <Navbar className='header'>
      <Container fluid>
        <Navbar.Brand><img src={institutionImage} alt='Logo' onClick={()=>gotoDashbord()}/></Navbar.Brand>
        <Nav className="align-items-center header-nav">
          {/* <NavDropdown title={<RiNotification4Fill />} id="notification" className='notification'>
            <NavDropdown.Item>Notification</NavDropdown.Item>
          </NavDropdown> */}
          <NavDropdown align="end"
            title={
            <div className="user-dropdown">
              <img className="thumbnail-image" src={userImage} alt="user pic" />
              <p>{fullName} <span>Admin</span></p>
              <RiArrowDownSLine />
            </div>}
            id="user">
            <NavDropdown.Item onClick={()=> navigate('/profile/edit-profile')}><RiPencilFill />Edit Profile</NavDropdown.Item>
            <NavDropdown.Item onClick={()=> navigate('/profile/change-password')}><RiLockPasswordFill />Change Password</NavDropdown.Item>
            <NavDropdown.Item onClick={logout}><RiLogoutBoxLine />Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  )
}
