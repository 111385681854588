import React, { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { images } from "../../../assets/images";
import { RiLockPasswordFill, RiMailFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import { Schema } from "../login/schema";
import HTTPService from "../../../service/HTTPService";
import { cypherService } from "../../../service/cypherService";
import { EndpointService } from "../../../service/endpointService";
import { toast } from "react-toastify";

function ForgotPassword() {
   const navigate = useNavigate();
   const [loader, setLoader] = useState(false);
   const [loginFailMsg, setLoginFailMsg] = useState("");

   const onSubmit = (values) => {
      setLoader(true);
      let discriminator = cypherService.getSessionStorage("discriminator");
      let payLoad = { ...values, userDiscriminator: discriminator };
      const apiUrl = EndpointService.forgotPwdUrl;
      HTTPService.post(apiUrl, payLoad).then((response) => {
         if (response.success === true || response.status == "success") {
            toast.success(response.message);
           navigate('/')
         } else {
            setLoader(false);
            toast.error(response.message);
         }
         })
         .catch((error) => {
            
            debugger;
            setLoader(false);
            toast.error(error.response.data.error);
            throw error;
         });
   };
   return (
      <div className="row login">
         <div className="col-md-5 login-img">
            <img src={images.brandLogo} alt="grameen-logo" className="grameen-logo" />
            <div className="leaf">
               <img src={images.leafLeft} alt="grameen-logo" className="leaf-left" />
               <img src={images.leafRight} alt="leaf one" className="leaf-right-1" />
               <img src={images.leafRight2} alt="leaf" className="leaf-right-2" />
            </div>
         </div>

         <div className="col-md-7 login-form-main float-end d-flex align-items-center justify-content-center">
            <Formik initialValues={Schema.forgotInitialValues} validationSchema={Schema.forgotValidationSchema} onSubmit={onSubmit} enableReinitialize={true}>
               {({ handleSubmit, getFieldProps }) => {
                  return (
                     <form className="login-form" onSubmit={handleSubmit}>
                        <h1>Forgot Password!</h1>
                        <p>Send a link to your email to reset password</p>
                        <FloatingLabel controlId="floatingInput" label="Email ID" className="with-icon mb-3">
                           <Form.Control type="email" placeholder="name@example.com" {...getFieldProps("email")} />
                           <RiMailFill />
                        </FloatingLabel>
                        <ErrorMessage name="email" component="div" className="error-msg" />
                        <div className="mt-5">
                           <Button
                              type="submit"
                              variant="primary"
                              className="login-btn"
                          
                           >
                              Send Reset Link
                           </Button>
                        </div>
                     </form>
                  );
               }}
            </Formik>
         </div>
      </div>
   );
}

export default ForgotPassword;
