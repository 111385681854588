import { Menu } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { RiAddFill, RiBankFill, RiMailFill, RiPhoneFill, RiSearchLine, RiUserSettingsFill } from "react-icons/ri";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import { cypherService } from "../../service/cypherService";
import HTTPService from "../../service/HTTPService";
import { EndpointService } from "../../service/endpointService";
import { toast } from "react-toastify";
import Map, { Marker, NavigationControl } from "react-map-gl";
import Pin from "../../helpers/mapboxLib/pin";
import { MarkerDragEvent, LngLat } from "react-map-gl";
import { AppConstant } from "../../constants/appConstant";
import mapboxgl from "mapbox-gl";
const TOKEN = AppConstant.mapboxToken; // Set your mapbox token here
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function GeocodeMapping() {
   const navigate = useNavigate();
   const [loader, setLoader] = useState(false);
   const [loginFailMsg, setLoginFailMsg] = useState("");
   const [customerTypeList, setCustomerTypeList] = useState([]);
   const [dateOfBirth, setDateOfBirth] = useState("");
   const [storeInstitutionName, setStoreInstitutionName] = useState(cypherService.encryptSessionStorage.getItem("institutionName"));
   const [selectedCustomerType, setSelectCustomerType] = useState("");
   const [businessConsultantList, setBusinessConsultantList] = useState([]);
   const [useFitBounds, setUseFitBounds] = useState([20.5937, 78.9629]);
   const [mapZoomValue, setMapZoomValue] = useState(4);
   const [latitude, setLatitude] = useState(41.40338);
   const [longitude, setLongitude] = useState(2.17403);
   const [initialViewState, setInitialViewState] = ([{
      latitude: 41.40338,
      longitude: 2.17403
   }]);

   const [viewport, setViewport] = useState({
      latitude: 41.40338,
      longitude: 2.17403,
      zoom: 10
   });


   const { ValueContainer, Placeholder } = components;

   const CustomValueContainer = ({ children, ...props }) => {
      return (
         <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
               {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
         </ValueContainer>
      );
   };

   const customStyles = {
      container: (provided, state) => ({
         ...provided,
         marginTop: 50,
      }),
      valueContainer: (provided, state) => ({
         ...provided,
         overflow: "visible",
      }),
      placeholder: (provided, state) => ({
         ...provided,
         position: "absolute",
         top: state.hasValue || state.selectProps.inputValue ? -25 : "",
         left: state.hasValue || state.selectProps.inputValue ? -6 : "",
         color: state.hasValue || state.selectProps.inputValue ? "#007b91" : "#7F8F97",
         transition: "top 0.1s, font-size 0.1s",
         fontSize: (state.hasValue || state.selectProps.inputValue) && 14,
      }),
   };
   // getCustomerList
   const getCustomerList = () => {
      let payLoad = {
         institutionId: cypherService.encryptSessionStorage.getItem("institutionId"),
      };
      const apiUrl = EndpointService.businessConsultant;
      HTTPService.post(apiUrl, payLoad).then((response) => {
         console.log(response);
         let cusList = response.data;
         debugger;
         if (response.data) {
            if (response.data.length != 0) {

               setBusinessConsultantList(response.data);
            }
         }
      });
   };

   useEffect(() => {
      getCustomerList();

   }, []);
   useEffect(() => {
      if (businessConsultantList.length != 0) {
         mapZoom(0)
      }

   }, [businessConsultantList]);

   const mapZoom = (index) => {

      let list = [...businessConsultantList];
      let tempList = list.map((obj, mapIndex) => {
         if (index == mapIndex) {
            setUseFitBounds([obj.latitude, obj.longitude]);
            navigator.geolocation.getCurrentPosition(
               position => {
                  setLatitude(obj.latitude)
                  setLongitude(obj.longitude)
                  setViewport(prevViewport => ({
                     ...prevViewport,
                     latitude: obj.latitude,
                     longitude: obj.longitude,
                     zoom: 15,
                  }));
                  // setInitialViewState({
                  //    latitude: obj.latitude,
                  //    longitude: obj.longitude,
                  //    zoom: 15,
                  // })
               },
               error => console.log(error),
               { enableHighAccuracy: true }
            );

         }
         return {
            ...obj,
            select: index == mapIndex ? true : false
         }
      });
      console.log(tempList)
      // setBusinessConsultantList(tempList);
   }

   return (
      <section className="main-container">
         <div className="dashboard-container">
            <Card className="officers-list">
               <Card.Body>
                  <Card.Title>Geocodes</Card.Title>
                  <div className="row">
                     <div id="mapHeight" className="col-12 col-xl-7">
                        {/* <Map  initialViewState={viewport} 
                           fitBounds={useFitBounds} key={Math.floor(Math.random() * 10000000)} markerZoomAnimation={true} mapStyle="mapbox://styles/mapbox/navigation-day-v1" mapboxAccessToken={TOKEN}>
                                          <Marker latitude={latitude} longitude={longitude} anchor="center"
                                             >
                                             <Pin size={20} />
                                          </Marker>
                                          <NavigationControl />
                                       </Map> */}
                        {businessConsultantList ? (
                           <Map initialViewState={viewport}
                              mapStyle="mapbox://styles/mapbox/navigation-day-v1"
                              mapboxAccessToken={TOKEN}
                              // zoom={[mapZoomValue]}
                              fitBounds={useFitBounds} key={Math.floor(Math.random() * 10000000)} markerZoomAnimation={true} boxZoom={true}>
                              {businessConsultantList &&
                                 businessConsultantList.map((listObject, index) => {
                                    return (
                                       <Marker latitude={listObject.latitude} key={index}
                                          offsetLeft={-20} offsetTop={-10}
                                          longitude={listObject.longitude} anchor="bottom" color={'b97d24'}>
                                          <Pin size={50} />
                                       </Marker>
                                    );
                                 })}
                              <NavigationControl />
                           </Map>
                        ) : (
                           <></>
                        )}
                     </div>
                     <div className="col-12 col-xl-5 geocode">
                        <div className="filter w-100">
                           <div className="d-flex w-100">
                              <div className="institution-list--search w-100 m-0">
                                 <Form.Control type="text" placeholder="Search business consultant" />
                                 <RiSearchLine className="search-icon" />
                              </div>
                           </div>
                        </div>
                        {/* Course list */}
                        <div className="course-list">
                           {businessConsultantList &&
                              businessConsultantList.map((object, index) => {
                                 return (
                                    <div className="list-item" onClick={(e) => mapZoom(index)}>
                                       <h4 className="course-name">{object.firstName + " " + object.lastName}</h4>
                                       <div className="course-details">
                                          <span className="course-id">Latitude: {object.latitude}</span>
                                          <span className="course-mcq">Longitude: {object.longitude}</span>
                                       </div>
                                    </div>
                                 );
                              })}
                        </div>
                     </div>
                  </div>
               </Card.Body>
            </Card>
         </div>
      </section>
   );
}
