import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);


export default function chartThree(props) {

  const data = {
    labels: ['Officers','Admin Officers', 'General Officers'],
    datasets: [
      {
        label: '',
        data: props.totalOfficerCount,
        backgroundColor: [
          '#f9f9f9',
          '#ED680F',
          '#F2A16A',

        ],
        borderColor: [
          '#f9f9f9',
          '#ED680F',
          '#F2A16A',

        ],
        borderWidth: 1,
      },
    ],

  };

  const plugins = [{
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = (height / 300).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      var text = props.institutionName=="Grameen Foundation" ? "--" : props.customerAnalytics ? props.customerAnalytics.allOfficerCount ? props.customerAnalytics.allOfficerCount + " Officers" : "--": 0 + "--",
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }]

  return (
    <div className='doughnut-chart-main'>
      <div className='doughnut-chart'>
        <Doughnut data={data} plugins={plugins} key={Math.random() * 100}
          options={{
            cutout: "80%",
            plugins: {
              tooltip: {
                callbacks: {
                  label: (item) => item.label=='Officers' ? '0' : item.parsed + '%'
                }
              },
              legend: {
                display: false
              },
              emptyDoughnut: {
                color: 'rgba(255, 128, 0, 0.5)',
                width: 30,
                radiusDecrease: 20
              }
            },
            responsive: true,
            maintainAspectRatio: true,
          }} />
      </div>
      <div className='doughnut-chart-content'>
        <ul className='doughnut-chart-label-two'>
          <li>Admin Officers
            <span>{ props.institutionName=="Grameen Foundation" ? "--" : props.customerAnalytics ? props.customerAnalytics.adminOfficerCount ? props.customerAnalytics.adminOfficerCount ? (props.customerAnalytics.adminOfficerCount > 0) ? props.customerAnalytics.adminOfficerCount : '--' : '--' : '--' : '--'}</span>
          </li>
          <li>General Officers
            <span>{ props.institutionName == "Grameen Foundation" ? "--" : props.customerAnalytics ? props.customerAnalytics.generalOfficerCount ? props.customerAnalytics.generalOfficerCount : '--' : '--'}</span></li>

        </ul>
      </div>
    </div>
  )
}
