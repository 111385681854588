import { CHANGE_INSTITUTION, RESET_STORE, STUDENT_OBJECT } from "./institutionTypes";

const initialState = {
    institutionID: ''   
};

function institutionReducer(state = initialState, action = {}) {
    debugger;
    switch (action.type) {        
        case CHANGE_INSTITUTION:
            return {
                institutionID: action.id
            };        
        case RESET_STORE:
            return {
                institutionID: ""
            }
        default: return state;
    }
}

export default institutionReducer;