import React, { useState, useEffect } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { images } from "../../../assets/images";
import { RiLockPasswordFill, RiMailFill, RiEyeOffFill, RiEyeFill } from "react-icons/ri";
import { ImSpinner8 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import { Schema } from "./schema";
import HTTPService from "../../../service/HTTPService";
import { cypherService } from "../../../service/cypherService";
import { EndpointService } from "../../../service/endpointService";

function Login(props) {
   const navigate = useNavigate();
   const [loader, setLoader] = useState(false);
   const [loginFailMsg, setLoginFailMsg] = useState("");
   const [passwordShown, setPasswordShown] = useState(false);

   useEffect(() => {
      sessionStorage.clear();
      localStorage.clear();
   }, []);
   const onSubmit = (values) => {
      setLoader(true);
      setLoginFailMsg("");
      let payLoad = values;
      const apiUrl = EndpointService.loginUrl;
      HTTPService.post(apiUrl, payLoad)
         .then((response) => {
            debugger;
            if (response.success === true) {
               debugger;
               cypherService.encryptSessionStorage.setItem("appToken", response.data.officer.token);
               let firstName = response.data.officer.firstName;
               let lastName = response.data.officer.lastName;
               let fullName = firstName + " " + lastName;
               let userId = response.data.officer._id;
           
               cypherService.encryptSessionStorage.setItem("userId", userId);
               cypherService.encryptSessionStorage.setItem("officerRole", response.data.officer.roleId.name);
               cypherService.encryptSessionStorage.setItem("institutionId", response.data.officer.institutionId);
               cypherService.encryptSessionStorage.setItem("userName", fullName);
               cypherService.encryptSessionStorage.setItem("institutionCreated", false);
               cypherService.encryptSessionStorage.setItem("updateInstitution", null);
               cypherService.encryptSessionStorage.setItem("userImage", response.data.officer.path);
               cypherService.encryptSessionStorage.setItem("institutionImage", response.data.institution && response.data.institution.path ? response.data.institution.path : "");
               cypherService.encryptSessionStorage.setItem("loginUserPermission", response.data.permission);
               cypherService.encryptSessionStorage.setItem("sidebarRender", false);
               debugger;
               getAllComponentList();
            }
            if (response.success === false) {
               setLoader(false);
               setLoginFailMsg(response.error);
            }
         })
         .catch((error) => {
            debugger;
            setLoader(false);
            if (error.response.status == 401) {
               setLoginFailMsg(error.response.data.error);
            } else {
               setLoginFailMsg(error.response.data.error);
            }
            throw error;
         });
   };
   const getAllComponentList = () => {
      debugger;
      const apiUrl = EndpointService.getAllComponent;
      HTTPService.get(apiUrl, null)
         .then((response) => {
            debugger;
            if (response.success) {
               debugger;
               if (response.data.length > 0) {
                  debugger;
                  debugger;
                  let _componentList = response.data.map((obj) => {
                     return {
                        _id: obj._id,
                        name: obj.name,
                     };
                  });
                  const obj = Object.fromEntries(_componentList.map((item) => [item._id, item.name]));
                  cypherService.encryptSessionStorage.setItem("appComponent", obj);
                  navigate("/dashboard");
                  setLoader(false);
               }
            }
         })
         .catch((error) => {
            throw error;
         });
   };
   const togglePassword = () => {
      setPasswordShown(!passwordShown);
   };

   return (
      <div className="row login">
         <div className="col-md-5 login-img">
            <img src={images.brandLogo} alt="grameen-logo" className="grameen-logo" />
            <div className="leaf">
               <img src={images.leafLeft} alt="grameen-logo" className="leaf-left" />
               <img src={images.leafRight} alt="leaf one" className="leaf-right-1" />
               <img src={images.leafRight2} alt="leaf" className="leaf-right-2" />
            </div>
         </div>

         <div className="col-md-7 login-form-main float-end d-flex align-items-center justify-content-center">
            <Formik initialValues={Schema.initialValues} validationSchema={Schema.validationSchema} onSubmit={onSubmit} enableReinitialize={true}>
               {({ handleSubmit, getFieldProps, values }) => {
                  console.log(values);
                  return (
                     <form className="login-form" onSubmit={handleSubmit}>
                        <h1>Hello!</h1>
                        <p>Log in to your account</p>
                        {loginFailMsg ? <div className="error-msg text-center">{loginFailMsg}</div> : null}
                        <FloatingLabel controlId="floatingInput" label="Email ID" className="with-icon mb-3">
                           <Form.Control type="email" placeholder="name@example.com" {...getFieldProps("email")} />
                           <RiMailFill />
                        </FloatingLabel>
                        <ErrorMessage name="email" component="div" className="error-msg" />
                        <FloatingLabel className="with-icon" controlId="floatingPassword" label="Password">
                           <Form.Control type={passwordShown ? "text" : "password"} placeholder="Password" {...getFieldProps("password")} />
                           <RiLockPasswordFill />
                           <span className="show-password" onClick={togglePassword}>
                              {" "}
                              {passwordShown ? <RiEyeFill /> : <RiEyeOffFill />}{" "}
                           </span>
                        </FloatingLabel>
                        <ErrorMessage name="password" component="div" className="error-msg" />
                        <div className="remember-me">
                           <Form.Group className="" controlId="formBasicCheckbox">
                              <Form.Check type="checkbox" label="Remember me" />
                           </Form.Group>

                           <Button
                              variant="link"
                              className="forget-btn"
                              onClick={() => {
                                 navigate("/forgotpassword");
                              }}
                           >
                              Forgot Password?
                           </Button>
                        </div>
                        <Button type="submit" variant="primary" disabled={loader} className="login-btn">
                           {loader ? "Loading..." : "Log in"}
                        </Button>
                     </form>
                  );
               }}
            </Formik>
         </div>
      </div>
   );
}

export default Login;
