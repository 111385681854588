import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

var data;
var plugins;

export default function ChartOne(props) {

    data = {
        labels: ['Customer','Customers', 'Business Correspondence'],
        datasets: [
            {
                label: '',
                data: props.totalCustomerCount,
                backgroundColor: [
                    '#f9f9f9',
                    '#12D75A',
                    '#F6D036',

                ],
                borderColor: [
                    '#f9f9f9',
                    '#12D75A',
                    '#F6D036',

                ],
                borderWidth: 1,
            },
        ],

    };

    plugins = [{
        beforeDraw: function (chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 300).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            var text = props.customerAnalytics ? props.customerAnalytics.allCustomerCount ? props.customerAnalytics.allCustomerCount + " Customers" : "--" : "--",
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
            ctx.fillText(text, textX, textY);
            ctx.save();
        }
    }]

    return (
        <div className='doughnut-chart-main'>
            <div className='doughnut-chart'>
                {/* <pre>{JSON.stringify(props,2)}</pre> */}
                <Doughnut data={data} plugins={plugins} key={Math.random() * 100}
                    options={{
                        cutout: "80%",

                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: (item) => item.label=='Customer' ? '0' : item.parsed + '%'
                                }
                            },
                            legend: {
                                display: false
                            },
                            emptyDoughnut: {
                                color: 'rgba(255, 128, 0, 0.5)',
                                width: 30,
                                radiusDecrease: 20
                              }
                        },
                        responsive: true,
                        maintainAspectRatio: true,
                    }} />

            </div>
            {/* <input type={'button'} id="reload" name="reload" value="reload" onClick={(e)=>reloadChart()}></input> */}
            <div className='doughnut-chart-content'>
                {props.institutionName}
                <ul className='doughnut-chart-label'>
                    <li>Customers
                        <span>{props.customerAnalytics ? props.customerAnalytics.totalCustomerCount ? props.customerAnalytics.totalCustomerCount : '--' : '--'}</span>
                    </li>
                    {
                        props.institutionName == "Grameen Foundation" ? (
                            <></>
                        ) : (
                            <li>
                                Business Correspondence
                                <span>{props.customerAnalytics && props.customerAnalytics.totalBcCount ? props.customerAnalytics.totalBcCount : '--'}</span>
                            </li>
                        )
                    }


                </ul>
            </div>
        </div>
    )
}
