import React from "react";
import { useDropzone } from "react-dropzone";
import { RiUpload2Line } from "react-icons/ri";
import { ProgressBar } from "react-bootstrap";
import { useEffect,useState } from "react";
import { images } from "../../../assets/images";

const UploadWithDrag = (props) => {

   const { setFieldValue,imageSrc } = props
   const [previewImage, setPreviewImage] = useState('');
   useEffect(() => {
    
      if(previewImage=='')
      {
         setPreviewImage(imageSrc)
      }
   }, [imageSrc]);
   const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      onDrop: acceptedFiles => {
         console.log(acceptedFiles)
         if (props.qrCode) {

            setPreviewImage(URL.createObjectURL(acceptedFiles[0]))
            setFieldValue("qrCode", acceptedFiles[0]);
         }
         if (props.arCode) {
            setPreviewImage(URL.createObjectURL(acceptedFiles[0]))
            setFieldValue("arCode", acceptedFiles[0]);
         }

      }
   });

   function formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
   }

   const files = previewImage != '' ? <aside className="uploading-files">
      <div className="csv-image">
         <img src={previewImage} alt="qr-code" />
      </div>

   </aside> : '';

   return (
      <section className="drag-with-upload mt-4 mx-2">
         <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <RiUpload2Line className="icon icon-upload" />
            <p>
               Drag & Drop or <a>Browse</a> the {props.name} Code
            </p>
         </div>

         {files}

      </section>
   );
};

export default UploadWithDrag;
