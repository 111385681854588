import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { RiArrowRightSLine, RiBankFill, RiEqualizerFill, RiFileEditFill, RiLayoutMasonryFill } from "react-icons/ri";
import { images } from "../../assets/images";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useLocation } from "react-router-dom";
import { cypherService } from "../../service/cypherService";
import { EndpointService } from "../../service/endpointService";
import HTTPService from "../../service/HTTPService";

export default function Sidebar(props) {
   debugger;
   const location = useLocation();
   const navRef = React.useRef();
   const navBtn = React.useRef();

   const [geoCodePermission, setGeoCodePermission] = useState(null);
   const [keywordMappingPermission, setKeywordMappingPermission] = useState(null);
   const [permissioncomponent, setPermissioncomponent] = useState(null);
   const [contentPermission, setContentPermission] = useState(null);
   const [institutionPermission, setInstitutionPermission] = useState(null);
   const [dashboardPermission, setDashboardPermission] = useState(null);
   const [permissionNameMap, setPermissionNameMap] = useState(null);
   const [permissionActive, setPermissionActive] = useState(false);

   const [isNavExpanded, setIsNavExpanded] = useState(true);

   let activeClassName = "active";

   useEffect(() => {
      if (
         location.pathname == "/institution/officers/add-officer" ||
         location.pathname == "/institution/add-institution" ||
         location.pathname == "/institution/update-institution" ||
         location.pathname == "/institution/officers/update-officer" ||
         location.pathname == "/institution/customers" ||
         location.pathname == "/institution/officers" ||
         location.pathname == "/institution/customers/update-customer" ||
         location.pathname == "/institution/customers/add-customer"
      ) {
         navRef.current.classList.add("sidebar--small");
      } else {
         navRef.current.classList.remove("sidebar--small");
      }
   }, []);
   const getAllComponentList = () => {
      let param = cypherService.encryptSessionStorage.getItem("appComponent");
      getPermission(param);
   };

   const getPermission = (param) => {
      let loginUserPermission = cypherService.encryptSessionStorage.getItem("loginUserPermission");
      debugger;
      if (loginUserPermission.length == 0) {
         superAdminActive();
      } else {
         for (let i = 0; i < loginUserPermission.length; i++) {
            let componentId = loginUserPermission[i].componentId;
            if (param[componentId] == "Dashboard") {
               debugger;
               setDashboardPermission({
                  create: loginUserPermission[i].create,
                  delete: loginUserPermission[i].delete,
                  read: loginUserPermission[i].read,
                  update: loginUserPermission[i].update,
               });
               debugger;
            }
            if (param[componentId] == "Institutions") {
               setInstitutionPermission({
                  create: loginUserPermission[i].create,
                  delete: loginUserPermission[i].delete,
                  read: loginUserPermission[i].read,
                  update: loginUserPermission[i].update,
               });
            }
            if (param[componentId] == "Contents") {
               setContentPermission({
                  create: loginUserPermission[i].create,
                  delete: loginUserPermission[i].delete,
                  read: loginUserPermission[i].read,
                  update: loginUserPermission[i].update,
               });
            }
            if (param[componentId] == "Permissions") {
               setPermissioncomponent({
                  create: loginUserPermission[i].create,
                  delete: loginUserPermission[i].delete,
                  read: loginUserPermission[i].read,
                  update: loginUserPermission[i].update,
               });
            }
            if (param[componentId] == "Keyword Mapping") {
               setKeywordMappingPermission({
                  create: loginUserPermission[i].create,
                  delete: loginUserPermission[i].delete,
                  read: loginUserPermission[i].read,
                  update: loginUserPermission[i].update,
               });
            }
            if (param[componentId] == "Geo Codes") {
               setGeoCodePermission({
                  create: loginUserPermission[i].create,
                  delete: loginUserPermission[i].delete,
                  read: loginUserPermission[i].read,
                  update: loginUserPermission[i].update,
               });
            }
         }
         setPermissionActive(true);
      }
   };
   const superAdminActive = () => {
      setDashboardPermission({
         create: true,
         delete: true,
         read: true,
         update: true,
      });
      setInstitutionPermission({
         create: true,
         delete: true,
         read: true,
         update: true,
      });
      setContentPermission({
         create: true,
         delete: true,
         read: true,
         update: true,
      });
      setPermissioncomponent({
         create: true,
         delete: true,
         read: true,
         update: true,
      });
      setKeywordMappingPermission({
         create: true,
         delete: true,
         read: true,
         update: true,
      });
      setGeoCodePermission({
         create: true,
         delete: true,
         read: true,
         update: true,
      });
      setPermissionActive(true);
   };
   useEffect(() => {
      if (
         location.pathname == "/institution/officers/add-officer" ||
         location.pathname == "/institution/add-institution" ||
         location.pathname == "/institution/update-institution" ||
         location.pathname == "/institution/officers/update-officer" ||
         location.pathname == "/institution/customers" ||
         location.pathname == "/institution/officers" ||
         location.pathname == "/institution/customers/update-customer" ||
         location.pathname == "/institution/customers/add-customer"
      ) {
         navRef.current.classList.add("sidebar--small");
      } else {
         navRef.current.classList.remove("sidebar--small");
      }
      getAllComponentList();
   }, [props.pathname]);

   const handleNavChange = (eventKey) => {
      if (eventKey === "2") {
         navRef.current.classList.add("sidebar--small");
      }
      if (!(eventKey === "2")) {
         navRef.current.classList.remove("sidebar--small");
      }
   };

   const closeMenu = () => {
      navRef.current.classList.remove("mobile-menu-show");
      navBtn.current.classList.remove("open");
   };

   return (
      <>
         <div ref={navBtn} className={isNavExpanded ? "hamburger" : "hamburger open"}
            onClick={() => {
               setIsNavExpanded(!isNavExpanded)
            }} 
         > <span></span>
         <span></span>
         <span></span>
         </div>

         <div className={isNavExpanded ? "sidebar" : "sidebar mobile-menu-show"} ref={navRef}>

            {/* <pre>{JSON.stringify(institutionPermission)}</pre> */}
            {/* <div className="menu-close" onClick={closeMenu}></div> */}
            <div className="sidebar--main">
               <Nav className="flex-column" onSelect={(e) => handleNavChange(e)}>
                  {permissionActive && dashboardPermission.read && (
                     <Nav.Item>
                        <Nav.Link as={NavLink} to="/dashboard" className={({ isActive }) => (isActive ? activeClassName : undefined)} eventKey="1">
                           <div>
                              <span className="sidebar--icon">
                                 <RiLayoutMasonryFill />
                              </span>
                              <span className="sidebar--content">Dashboard</span>
                           </div>
                        </Nav.Link>
                     </Nav.Item>
                  )}
                  {permissionActive && institutionPermission.read && (
                     <Nav.Item>
                        <Nav.Link as={NavLink} to="/institution/customers" className={({ isActive }) => (isActive ? activeClassName : undefined)} eventKey="2">
                           <div>
                              <span className="sidebar--icon">
                                 <RiBankFill />
                              </span>
                              <span className="sidebar--content">Institutions</span>
                           </div>
                        </Nav.Link>
                     </Nav.Item>
                  )}
                  {permissionActive && permissioncomponent.read && (
                     <Nav.Item>
                        <Nav.Link as={NavLink} to="/permissions" className={({ isActive }) => (isActive ? activeClassName : undefined)} eventKey="3">
                           <div>
                              <span className="sidebar--icon">
                                 <RiEqualizerFill />
                              </span>
                              <span className="sidebar--content">Permissions</span>
                           </div>
                        </Nav.Link>
                     </Nav.Item>
                  )}
                  {permissionActive && contentPermission.read && (
                     <Nav.Item>
                        <Nav.Link as={NavLink} to="/contents" className={({ isActive }) => (isActive ? activeClassName : undefined)} eventKey="4">
                           <div>
                              <span className="sidebar--icon">
                                 <RiFileEditFill />
                              </span>
                              <span className="sidebar--content">Contents</span>
                           </div>
                        </Nav.Link>
                     </Nav.Item>
                  )}
                  {permissionActive && keywordMappingPermission.read && (
                     <Nav.Item>
                        <Nav.Link as={NavLink} to="/keywords-mapping" className={({ isActive }) => (isActive ? activeClassName : undefined)} eventKey="5">
                           <div>
                              <span className="sidebar--icon">
                                 <img src={images.keywordsMaping} alt="keywordsMaping" />
                              </span>
                              <span className="sidebar--content">Keywords Mapping</span>
                           </div>
                        </Nav.Link>
                     </Nav.Item>
                  )}
                  {permissionActive && geoCodePermission.read && (
                     <Nav.Item>
                        <Nav.Link as={NavLink} to="/geocode-mapping" className={({ isActive }) => (isActive ? activeClassName : undefined)} eventKey="6">
                           <div>
                              <span className="sidebar--icon">
                                 <img src={images.geocodeMapping} alt="keywordsMaping" />
                              </span>
                              <span className="sidebar--content">Geocode Mapping</span>
                           </div>
                        </Nav.Link>
                     </Nav.Item>
                  )}
               </Nav>
            </div>
         </div>
      </>
   );
}
