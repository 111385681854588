import { Menu } from "@mui/material";
import React, { Component, useEffect, useRef, useState } from "react";
import Header from "../../layouts/header/Header";
import Sidemenu from "../../layouts/menu/Sidebar";  
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";
import { RiAddCircleFill, RiAddFill, RiBankFill, RiCloseFill, RiDeleteBin7Line, RiDownload2Fill, RiMapPinFill, RiMore2Fill, RiPencilFill, RiSearchLine, RiUpload2Fill, RiUserStarFill } from 'react-icons/ri';
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import courseImg from "../../assets/images/courses.png";
import CourseList from './courses/courseList';
import { cypherService } from "../../service/cypherService";;

export default function Contents(props) {
  const location = useLocation();
  const navigate = useNavigate();
   
  useEffect(()=>{
    cypherService.encryptSessionStorage.setItem('courseEvent', '');
    cypherService.encryptSessionStorage.setItem('courseData', '');
  },[]);

  return (
    <section className="main-container">
      <div className="dashboard-container">
        <CourseList/>
      </div>
    </section>
  );
}
