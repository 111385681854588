import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function BarChart(props) {
   var allLanguageList = props.languageList;
   console.log('languageList', languageList);
   var languageList = []
   var labels = ['English','Telugu','Assamese','Tamil','Kannada','Gujarati','Marathi','Hindi']
    var dataset = {'English':0,'Telugu':0,'Assamese':0,'Tamil':0,'Kannada':0,'Gujarati':0,'Marathi':0,'Hindi':0}

    allLanguageList.forEach(element => {
      if(element && element.name)
      {
         dataset[element.name] = element.count
      }
    
      // labels.push(element.name)
      // dataset.push(element.count)
   });
   const options = {
      type: 'bar',
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
         legend: {
            display: false,
            position: "top",
         },
         title: {
            display: false,
            text: "",
         },
      },
      scales: {
         x: {
            grid: {
               display: false,
            },
         },
         y: {
            grid: {
               color: '#e2e2e2',
            },
            border: {
               dash: [6, 5],
            },
         }
      },
   };

   // const labels = ["English", "Latin", "French", "Dutch", "Italy", "British", "Arabic"];

   const data = {
      labels,
      datasets: [
         {
            label: "",
            data: dataset,
            backgroundColor: "#D4F2F3",
            borderWidth: 2,
            borderRadius: 50,
            borderColor: "#D4F2F3",
            hoverBackgroundColor: "#007B91",
            hoverBarderColor: "#007B91",
         },
      ],
   };
   return (
      <div className="bar-chart">
         <Bar options={options} data={data} />
      </div>
   );
}
