import { Menu } from '@mui/material'
import React, { Component, useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { RiAddCircleFill, RiAddFill, RiBankFill, RiCloseFill, RiDeleteBin7Line, RiDownload2Fill, RiMapPinFill, RiMore2Fill, RiPencilFill, RiSearchLine, RiUpload2Fill, RiUserStarFill } from 'react-icons/ri';
import Select from 'react-select';
import Table from 'react-bootstrap/Table';
import { Card, Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { EndpointService } from '../../../service/endpointService';
import HTTPService from '../../../service/HTTPService';
import { cypherService } from '../../../service/cypherService';
import { toast } from 'react-toastify';
// import InstitutionMenu from '../institutionMenu';
import { ColorRing } from 'react-loader-spinner';
import { useDispatch, useSelector, Provider, connect } from 'react-redux';
import { changeInstitution } from '../../../components/redux/institution/institutionActions';

export default function OfficersList() {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [institutionName, setInstitutionName] = useState();
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const searchQuery = useRef("");
    const [filteredList, setFilteredList] = useState([]);
    const [deleteBtn, setDeleteBtn] = useState(false);
    const [deleteOfficerId, setDeleteOfficerId] = useState(null);
    const [page,setPage] = useState(0);
    const [flag,setFlag] = useState(false);
    const tableRef = React.useRef();
    const [institutionCreated, setInstitutionCreated] = useState(cypherService.encryptSessionStorage.getItem('institutionCreated'));
    const [institutionPermission, setInstitutionPermission] = useState({
        create: true,
        delete: true,
        read: true,
        update: true
    });
    const columns = [
        {
            name: 'Full Name',
            selector: row => row.firstName + ' ' + row.lastName,
            sortable: true,
        },
        {
            name: 'Designation',
            selector: row => row.designation,
            sortable: true,
        },
        {
            name: 'Mobile No',
            selector: row => row.mobileNumber,
            sortable: true,
        },

        {
            name: 'Email ID',
            selector: row => row.email,
            sortable: true,
        },
    ];
    const [keyValue,setKeyValue] = useState(Math.random() * 100);

    let tempInstitutionName = cypherService.encryptSessionStorage.getItem('institutionName');
    let loginUserPermission = cypherService.encryptSessionStorage.getItem("loginUserPermission");
    let componentName = cypherService.encryptSessionStorage.getItem("appComponent");    
    const dispatch = useDispatch();

    useEffect(() => {        
        setInstitutionName(cypherService.encryptSessionStorage.getItem('institutionName'));
        if (institutionCreated) {
            institutionInit();
        }
        institutionPermissionEvent();
    }, []);
    useEffect(() => {        
        fetchOfficers();
        
    },[page])
    useEffect(() => {
        if (institutionName && tempInstitutionName !== institutionName) {
            fetchOfficers(); // fetch page 1 of officers
            setInstitutionName(cypherService.encryptSessionStorage.getItem('institutionName'));
        }
    }, [tempInstitutionName]);

    const institutionPermissionEvent=()=>{   
        if(loginUserPermission){
           for (let i = 0; i < loginUserPermission.length; i++) {
              let componentId = loginUserPermission[i].componentId;
              if (componentName[componentId] == "Institutions") {
               setInstitutionPermission({
                  create: loginUserPermission[i].create,
                  delete: loginUserPermission[i].delete,
                  read: loginUserPermission[i].read,
                  update: loginUserPermission[i].update,
               });
               break;
              }
            }  
        }           
    }

    const institutionInit = () => {         
        let randomNumber = Math.random().toString().substr(2, 6);
        dispatch(changeInstitution(randomNumber));
    }
    
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };       
    }, [filterText, resetPaginationToggle]);

    // Fetch users
    const fetchOfficers = async () => {
       
        setLoading(true);
        setInstitutionName(cypherService.encryptSessionStorage.getItem('institutionName'));
        let institutionId = cypherService.encryptSessionStorage.getItem('institutionId');
        let apiUrl = EndpointService.getAllOfficer;
        let search = searchQuery.current;
        let payLoad = {
            "perPage": perPage,
            "page": page,
            "institutionId": institutionId,
            "searchParm": search
        }
        HTTPService.post(apiUrl, payLoad).then((response) => {
            console.log(response)
            const data = response.data;
            setData(data);
            setFilteredList(data);
            setTotalRows(response.count);
            setLoading(false);
        });
    };

    // HandlePageChange
    const handlePageChange = page => {
        setPage(page-1)
    };
    // HandlePerRowsChange
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        let institutionId = cypherService.encryptSessionStorage.getItem('institutionId');
        let apiUrl = EndpointService.getAllOfficer;
        let payLoad = {
            "perPage": newPerPage,
            "page": (page-1),
            "institutionId": institutionId,
            "searchParm": searchQuery.current
        }
        HTTPService.post(apiUrl, payLoad)
            .then((response) => {
                console.log(response)
                const data = response.data;
                setData(data);
                setFilteredList(data);
                setPerPage(newPerPage);
                setLoading(false);
            })
    };

    //Search list of objects
    const handleSearch = ({target}) => {
        const { value } = target;
        const searchText = value;
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        searchQuery.current=searchText;
        setPage(0);
        fetchOfficers();
    };

    // UpdateOfficer
    const updateOfficer = (row, event) => {
        console.log(row);
        console.log(event);
        cypherService.encryptSessionStorage.setItem('officerId', row._id)
        navigate('/institution/officers/update-officer');
    }
    // handleRowSelected
    const handleRowSelected = (row, event) => {
        console.log(row)
       console.log(tableRef)
        debugger
        setDeleteBtn(true)
        setDeleteOfficerId(null)
        if (!row.selectedCount > 0) {
            setDeleteBtn(false)
        }
        if(row.selectedCount > 0){
            let tempArr = [];
            row.selectedRows.map((item)=>{
                tempArr.push(item._id)
            })            
            console.log(tempArr);
            setDeleteOfficerId({
                id: tempArr
            })
        }
        
        // setDeleteOfficerId(row.selectedRows.length > 0 ? row.selectedRows[0]._id : "")
        console.log(deleteOfficerId);
        // debugger
    }

    // DeleteOfficer
    const deleteOfficer = () => {
        if(institutionPermission.delete){
        setLoader(true);
        // institutionId = cypherService.encryptSessionStorage.getItem('institutionId');
        console.log(deleteOfficerId)
        let payLoad = deleteOfficerId;
        const apiUrl = EndpointService.deleteOfficer;        
        HTTPService.post(apiUrl, payLoad)
            .then((response) => {
                console.log(response)
                const data = response.data;
                if (response.success === true) {
                    setKeyValue(Math.random() * 100);
                    setDeleteBtn(true);
                    toast.success(response.message);
                    navigate('/institution/officers')
                    fetchOfficers();
                    setLoader(false);
                    setDeleteBtn(false)

       setFlag(true)
                }
                if (response.success === false) {                    
                    toast.error(response.error);
                    setLoader(false);
                }
            })
            .catch((error) => {                
                toast.error(error.error);
                setLoader(false);
                throw error;
            });
        }else{
            toast.error('Permission is not available');
        }
    }
    const gotoCreateOfficerPage=()=>{
        if(institutionPermission.create){            
            navigate("/institution/officers/add-officer");
        }else{
            toast.error('Permission is not available');
        }
    }
    const gotoEditInstitutionPage=()=>{    
        if(institutionPermission.update){
           navigate("/institution/update-institution");
        }else{
           toast.error('Permission is not available');
        }
    }

    return (
        <>
        {/* <InstitutionMenu/> */}
        {loader ? <div className='loader'>
            <ColorRing
                visible={true}
                height="60"
                width="60"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#007B91', '#007B91', '#007B91', '#007B91', '#007B91']}
            />
            </div> : null}
        
        <Card className="officers-list">
            <Card.Body>
                <Card.Title>
                    <div>{cypherService.encryptSessionStorage.getItem('institutionName')}</div>
                    <DropdownButton id="dropdown-item-button" variant='light'
                        title={
                            <>
                                <RiMore2Fill className='dots' />
                                <RiCloseFill className='cross' />
                            </>
                        }>                        
                        <Dropdown.Item onClick={() => { 
                            // navigate('/institution/update-institution')
                            gotoEditInstitutionPage();
                        }}>
                            <span className='icon'><RiPencilFill /></span>
                            <span className='content'>
                                <p>Edit Institution</p>
                            </span>
                        </Dropdown.Item>
                    </DropdownButton>
                </Card.Title>
                <div className='filter'>
                    <div className='d-flex'>
                        <div className='institution-list--search'>
                            <Form.Control type="text" placeholder="Search officers" value={searchQuery.current}
                                onChange={(e) => { handleSearch(e) }} className='' />
                            <RiSearchLine className='search-icon' />
                        </div>
                        {deleteBtn && <Button variant="primary" className='add-btn me-3' onClick={() => deleteOfficer()}><RiDeleteBin7Line /></Button>}
                    </div>
                    <div className=''>
                        <Button variant="primary" className='primary-btn' onClick={() => 
                            // navigate('/institution/officers/add-officer')
                            gotoCreateOfficerPage()
                        }><RiAddCircleFill />Add Officer</Button>
                    </div>
                </div>
                <div>
                    <DataTable
                        columns={columns}
                        data={filteredList}
                        progressPending={loading}
                        pagination
                        paginationServer
                        responsive
                        selectableRows
                        clearSelectedRows={flag}
                        selectableRowsHighlight
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        subHeaderComponent={subHeaderComponentMemo}
                        paginationResetDefaultPage={resetPaginationToggle}
                        persistTableHead
                        onRowClicked={updateOfficer}
                        onSelectedRowsChange={(row) => handleRowSelected(row)}
                        className="react-table"
                        key={keyValue}
                    />
                </div>
            </Card.Body>
        </Card>
        </>

    )
}
