import { Menu } from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { RiAddFill, RiBankFill, RiMailFill, RiPhoneFill, RiSearchLine, RiUserSettingsFill, RiLockPasswordFill } from "react-icons/ri";
import Select, { components } from "react-select";
import { Card, FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import { Schema } from "./schema";
import { cypherService } from "../../service/cypherService";
import HTTPService from "../../service/HTTPService";
import { EndpointService } from "../../service/endpointService";
import { toast } from "react-toastify";
import graphics from "../../assets/images/svg/change-pwd.svg";

export default function ChangePassword() {
   const navigate = useNavigate();
   const [loader, setLoader] = useState(false);
   const [loginFailMsg, setLoginFailMsg] = useState("");
   const [roleList, setRoleList] = useState([]);

   useEffect(() => {}, []);

   // Submit
   const onSubmit = (values) => {
      setLoader(true);
      values.institutionId = cypherService.encryptSessionStorage.getItem("institutionId");
      let officerId = cypherService.encryptSessionStorage.getItem("userId");
      let payLoad = { ...values };
      const apiUrl = EndpointService.changeOfficersPwdUrl + officerId;

      HTTPService.post(apiUrl, payLoad)
         .then((response) => {
            console.log(response);
            const data = response.data;
            if (response.success === true) {
               toast.success(response.message);
               // navigate('/institution/officers');
               setTimeout(() => {
                  logout();
               }, 2000);
            }
            if (response.success === false) {
               setLoader(false);
               toast.error(response.error);
            }
         })
         .catch((error) => {
            debugger;
            setLoader(false);
            toast.error(error.response.data.error);
            throw error;
         });
   };
   const logout = () => {
      sessionStorage.clear();
      navigate("/");
   };
   return (
      <section className="main-container">
         <div className="dashboard-container">
            <Card className="officers-list">
               <Card.Body>
                  <Card.Title>Change Password</Card.Title>
                  <Formik initialValues={Schema.changePwdInitialValues} validationSchema={Schema.changePwdValidationSchema} onSubmit={onSubmit} enableReinitialize={true}>
                     {({ handleSubmit, getFieldProps, setFieldValue, values }) => {
                        return (
                           <form className="verical-space-between login-form" onSubmit={handleSubmit}>
                              <div className="row">
                                 <div className="d-none d-md-block col-md-6 p-5 d-flex align-items-center justify-content-center">
                                    <div className="graphics text-center">
                                       <img src={graphics} alt="" className="img-fluid" />
                                    </div>
                                 </div>
                                 <div className="col-12 col-md-6 p-sm-5 p-md-3 p-xl-5 ">
                                    <div className="row align-items-center">
                                       {/* <div className='col-md-12'>
                                                        <div className=''>
                                                            <FloatingLabel controlId="floatingName" label="Current Password" className="with-icon mb-3">
                                                                <Form.Control size="lg" type="password" placeholder="Current Password" {...getFieldProps("currentPassword")} />
                                                                <RiLockPasswordFill />
                                                            </FloatingLabel>
                                                            <ErrorMessage
                                                                name="currentPassword"
                                                                component="div"
                                                                className="error-msg"
                                                            />
                                                        </div>
                                                    </div> */}
                                       <div className="col-md-12">
                                          <div className="">
                                             <FloatingLabel controlId="floatingName" label="New Password" className="with-icon mb-3">
                                                <Form.Control size="lg" type="password" name="newPassword" placeholder="New Password" {...getFieldProps("newPassword")} />
                                                <RiLockPasswordFill />
                                             </FloatingLabel>
                                             <ErrorMessage name="newPassword" component="div" className="error-msg" />
                                          </div>
                                       </div>
                                       <div className="col-md-12">
                                          <div className="">
                                             <FloatingLabel controlId="floatingLastName" label="Confirm Password" className="with-icon mb-3">
                                                <Form.Control size="lg" type="password" name="confirmPassword" placeholder="Confirm Password" {...getFieldProps("confirmPassword")} />
                                                <RiLockPasswordFill />
                                             </FloatingLabel>
                                             <ErrorMessage name="confirmPassword" component="div" className="error-msg" />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="py-3">
                                 <hr className="" />
                                 <div className="d-flex justify-content-end">
                                    <Button variant="secondary" className="secondary-btn mt-3 me-3" onClick={() => navigate(-1)}>
                                       Cancel
                                    </Button>
                                    <Button type="submit" variant="primary" className="primary-btn mt-3">
                                       Update
                                    </Button>
                                 </div>
                              </div>
                           </form>
                        );
                     }}
                  </Formik>
               </Card.Body>
            </Card>
         </div>
      </section>
   );
}
